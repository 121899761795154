import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { MetaInfo } from "../models/api/com/bion/etl/NodeMetaData";
import { Workflow, WorkflowNodeSettings } from "../models/api/com/bion/etl/Workflow";
import { WorkflowRepositoryEntry } from "../models/api/models/workflow/WorkflowRepositoryEntry";


export class ApiServiceUtils {

  constructor(private http: HttpClient) { }

  static makeAuthHeaders(token: string, contentType?: string): HttpHeaders {

    const content_type = contentType ? contentType : "application/json";

    return new HttpHeaders({
      "Content-Type": content_type,
      TokenAuthorization: token,
    });
  }


  /**
 * Builds get parameters and ignores undefined variables
 * @param paramList Parameter List
 * @returns GET Parameters
 */
  static makeParams(paramList: Map<string, any>): HttpParams {
    let params = new HttpParams();
    for (let p of paramList) {
      if (p[1] !== undefined) {
        params = params.set(p[0], p[1]);
      }
    }
    return params;
  }

  static makeMapOption(map: Map<string, any>) {
    const params = this.makeParams(map);
    const options = { params: params };
    return options;
  }

  static make_params_pure(...paramList: { param: string, value: any }[]) {
    const map = new Map<string, any>();

    for (let p of paramList)
      map.set(p.param, p.value);

    return this.makeParams(map);
  }

  /**
   * Builds request options. Undefined values are ignored.
   * @param paramList Parameter List
   * @returns GET Options
   */
  static makeOptions(...paramList: { param: string, value: any }[]) {
    const map = new Map<string, any>();

    for (let p of paramList)
      map.set(p.param, p.value);

    const params = this.makeParams(map);
    const options = { params: params };
    return options;
  }

  static encodeQueryData(data) {
    const ret = [];
    for (let d in data) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }

    return ret.join('&');
  }

}





/**
 * Deep clones objets
 */
export interface Cloner<T> {
  /**
   * Create a deep copy without methods.
   * @param arg object
   */
  deepCopy(arg: T): T;
}

export class DateCloner implements Cloner<Date> {
  deepCopy(arg: Date): Date {
    const copy = new Date();
    copy.setTime(arg.getTime());
    return copy;
  }
}

/**
 * Clones and copies objects.
 */
export class ObjectCloner implements Cloner<any> {


  /**
   * Copies an object. CAUTION: methods are not cloned!
   * @param obj Object to clone
   * @returns Result object
   */
  deepCopy(obj: any) {
    let copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      let i = 0;
      const len = obj.length;
      for (; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }
}


// import {  MetaInfo, WorkflowNodeSettings} from "src/app/models/workflow.model";
import { ExecutePlugInResult, ExecutePortResult, UpdateSettingsPortResult, UpdateSettingsResult, UpdateWorkflowSettingsResult, WorkflowResult } from "../models/designer.models";

/**
 * Supports Workflow Node Configuration.
 */
export class NodeConfigUtil {


  /**
   * Converts a execute or update settings Meta Info to a general Meta Info
   *
   * The general Meta Info contains multiple tables, because there could be a port with multiple table inputs (e.g. Union).
   * The result Meta Info of 'execute plug-in' or 'update settings' contains only 1 table per port, because no out port can produce
   * multiple tables.
   *
   * @param apiMetaInfo Meta Info with only 1 table per port
   * @returns Meta Info with (port -> table list) containing only the 1 input table
   */
  static makeMetaInfo(apiMetaInfo: Map<string, MetaInfo>): Map<string, MetaInfo[]> {

    // const sourceMetaInfo = this.objectToMap<MetaInfo>(apiMetaInfo);
    const resultMap = new Map<string, MetaInfo[]>();

    for (let key of apiMetaInfo.keys()) {
      const metaInfo = apiMetaInfo.get(key);
      resultMap.set(key, [metaInfo]);
    }

    return resultMap;
  }

  /**
   * Converts an object to a Map keyed with strings.
   * @param obj Object
   * @returns String-Keyed Map
   */
  static objectToMap<T>(obj: any): Map<string, T> {
    // xah_obj_to_map2 same as xah_obj_to_map, but require JS2017
    return new Map(Object.entries(obj));
  }

  /**
   * Converts a string-keyed Typescript Map to an Object.
   * @param m Map
   * @returns Object
   */
  static mapToObject<T>(m: Map<string, T>): any {
    let result = {};

    m.forEach((value, key) => {
      result[key] = value;
    });

    return result;
  }


  /**
   * Currently the MetaInfo object is delivered as JSON object but defined as TypeScript Map here.
   * In the future we need to correct both, replace the delivered JSON with tuple and so for the TypeScript Map.
   * @param w Backend Workflow
   * @returns 
   */
  static workflow_object_to_map(w: Workflow): Workflow {
    const clean_nodes = w.Nodes.map((n) => {
      const clean_meta_info = NodeConfigUtil.objectToMap<MetaInfo[]>(
        n.Properties.MetaInfo
      );
      const new_node = { ...n };
      new_node.Properties.MetaInfo = clean_meta_info;
      return new_node;
    });
    w.Nodes = clean_nodes;
    return w;
  }
}



//--------------------- DESIGNER UTIL-----------------------------------------

export class ResultConversionUtil {

  static convert_workflow_result(result: WorkflowResult): WorkflowResult {

    const new_result = { ...result };

    const new_out_node_data = NodeConfigUtil.objectToMap<ExecutePlugInResult<WorkflowNodeSettings>>(new_result.OutNodeData);
    for (let key of new_out_node_data.keys()) {
      const value = new_out_node_data.get(key);
      const new_value = this.otm_execute_plug_in_result(value);
      new_out_node_data.set(key, new_value);
    }
    new_result.OutNodeData = new_out_node_data;
    // new_result.NodeStates = NodeConfigUtil.objectToMap<NodeState>(new_result.NodeStates);
    // new_result.Errors = NodeConfigUtil.objectToMap<string>(new_result.Errors);

    return new_result;
  }

  static otm_execute_plug_in_result(arg: ExecutePlugInResult<WorkflowNodeSettings>): ExecutePlugInResult<WorkflowNodeSettings> {
    const new_arg = { ...arg };

    const new_port_results = NodeConfigUtil.objectToMap<ExecutePortResult>(new_arg.PortResults);

    new_arg.PortResults = new_port_results;

    return new_arg;
  }

  /**
  * Object-To-Map of {@link dss.UpdateWorkflowSettingsResult}
  * @param result Object
  * @returns Map
  */
  static otm_update_workflow_settings_result(result: UpdateWorkflowSettingsResult): UpdateWorkflowSettingsResult {

    const new_result = { ...result };
    const new_out_settings = NodeConfigUtil.objectToMap<UpdateSettingsResult<WorkflowNodeSettings>>(result.OutNodeData)

    for (let key of new_out_settings.keys()) {
      const value = new_out_settings.get(key);
      const new_value = this.otm_update_settings_result(value);
      new_out_settings.set(key, new_value);
    }

    new_result.OutNodeData = new_out_settings;
    return new_result;
  }

  /**
  *
  * @param arg Object-To-Map of {@link UpdateSettingsResult}
  * @returns
  */
  static otm_update_settings_result(arg: UpdateSettingsResult<WorkflowNodeSettings>): UpdateSettingsResult<WorkflowNodeSettings> {
    const new_arg = { ...arg };

    const new_port_results = NodeConfigUtil.objectToMap<UpdateSettingsPortResult>(new_arg.PortResults);
    new_arg.PortResults = new_port_results;

    return new_arg;
  }


}



