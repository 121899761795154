import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TabPanel } from 'primeng/tabview';
import { Observable } from 'rxjs';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { DataSource } from 'src/app/models/datasource.model';
// import { WorkflowRepositoryEntry } from 'src/app/models/workflow.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { SchedulesService } from 'src/app/services/schedules.service';
import { UserService } from 'src/app/services/user.service';
import { GenericLatestActivitiesComponent } from '../generic-latest-activities/generic-latest-activities.component';
import { DataSourceActivity } from '../generic-latest-activities/provider-data-source';
import * as dss from 'src/app/models/datasource.model';

export interface WidgetProvider<T> {
  getData(): Observable<any>;
  createTabsMenu(): any[];
}

export class DataSourceWidget implements WidgetProvider<DataSource>{
  constructor(private bionApi: ApiBackendService) {}
  getData(): Observable<any> {
    let schedulesObs = this.bionApi.getScheduleActionPlan();
    return schedulesObs
  }
  createTabsMenu(): any[] {
    let tabs = [{header: "Latest", icon: "pi pi-calendar"},{header: "Team", icon: "pi pi-users"},{header: "Data", icon: "pi pi-table"},{header: "Export", icon: "pi pi-download"}];
    console.log(tabs);
    return tabs
  }

}

export class WorkflowWidget implements WidgetProvider<WorkflowRepositoryEntry>{
  constructor(private bionApi: ApiBackendService) {}
  getData(): Observable<any> {
    let schedulesObs = this.bionApi.getScheduleActionPlan();
    return schedulesObs
  }
  createTabsMenu(): any[] {
    let tabs = [{header: "Latest", icon: "pi pi-calendar"},{header: "Team", icon: "pi pi-users"},{header: "Preview", icon: "pi pi-share-alt"},{header: "Export", icon: "pi pi-download"}];
    console.log(tabs);
    return tabs
  }

}

@Component({
  selector: 'app-generic-widgets',
  templateUrl: './generic-widgets.component.html',
  styleUrls: ['./generic-widgets.component.scss']
})
export class GenericWidgetsComponent<T> implements OnInit {
  @Input() objList: T[] = [];
  @Input() typeClass!: WidgetProvider<T>;
  @Input() currentView!: string;

  //@ViewChild('calendarObjectWidget') calendarObjectWidget: GenericCalendarWidgetComponent<DataSource>;
  @ViewChild("dsLatestActivities") dsObjectActivities!: GenericLatestActivitiesComponent<DataSource, [dss.DataPackageProtocolEntry[], dss.DataSourceProtocolEntry[], dss.DataSource[]]>;

  dataSourceActivityTypeClass: DataSourceActivity = new DataSourceActivity(this.bionApi, this.datasourcesService, this.userService);

  //calendarTypeClass = new DataSourceSchedulePart(this.bionApi,this.datasourcesService,this.schedulesService);

  tabs: TabPanel[] = [];

  constructor(private bionApi: ApiBackendService, private datasourcesService: DatasourcesService, private schedulesService: SchedulesService, private userService: UserService) { }

  ngOnInit(): void {
  }

  setObjects(typeClass: WidgetProvider<T>,objList: any[]) {
    this.typeClass = typeClass;
    this.typeClass.getData().subscribe((results) => {
      this.tabs = this.typeClass.createTabsMenu();
      console.log(typeClass,objList,this.dsObjectActivities);
      this.dsObjectActivities.setObject(this.dataSourceActivityTypeClass);
    })
  }

}
