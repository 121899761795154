import { MenuItem } from "primeng/api/menuitem";
import { DataStore } from "src/app/models/datastore.model";
import { CubesService } from "src/app/services/cubes.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { DestinationService } from "src/app/services/destinationService";
import { UserService } from "src/app/services/user.service";
import { ToolbarProvider } from "./toolbar-providers";
import { DestinationActionEvent, DestinationDialogActionType } from "src/app/models/dialog-actions.model";

export class DestinationToolbar implements ToolbarProvider<DataStore> {
    constructor(private dataStoresService: CubesService, private userService: UserService, private datasourcesService: DatasourcesService,
        private destService: DestinationService) { }

    subscribeToEmitters() {
        let selectedObs = this.dataStoresService.selectedDataStoreEmitter;

        return selectedObs
    }

    initObjectMenu(): MenuItem[] {
        return [

            {
                label: "Create destination",
                icon: "pi pi-fw pi-plus",
                styleClass: "p-button p-mr-2 p-mb-2",
                command: () => {
                    //this.dataStoresService.displayNewDataStoreDialog.emit(true);
                    this.dataStoresService.destinationDialogActionSendEmitter.emit(new DestinationActionEvent(true, "Create destination", DestinationDialogActionType.createDestination, "Create", undefined));

                    
                }
            },
            // {
            //     label: "New_New",
            //     icon: "pi pi-fw pi-plus",
            //     command: () => {
            //         this.destService.displayNewDestinationDialog.emit(true);
            //     },
            // },
        ]
    }
    initActionMenu(): MenuItem[] {
        return [
            // {
            //     label: "ViewData",
            //     icon: "pi pi-fw pi-table",
            //     command: () => {
            //         this.datasourcesService.displayPsaDialog.emit(true);
            //     }
            // },
        ]
    }
}
