import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { Observable, interval } from 'rxjs';
import { concatMap, map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { IntegratedSourceController } from 'src/app/models/api/controllers/IntegratedSourceController';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { TasksService } from 'src/app/services/tasks.service';
import { SubSink } from 'subsink';
import { DefineStreamsIntComponent } from '../define-streams-int/define-streams-int.component';
import { DefineStreamsIntPageComponent } from '../define-streams-int-page/define-streams-int-page.component';
import { HeaderRow } from 'src/app/views/objectManagement/general-object-view/record-provider';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Diese Komponente rendert eine Liste integirerter Sources.
 *
 * Features:
 * - Source Name und Herkunft
 *
 * Aktionen:
 * - Streams anschauen
 * - Source löschen
 */
@Component({
  selector: 'app-view-sources-int',
  templateUrl: './view-sources-int.component.html',
  styleUrls: ['./view-sources-int.component.scss'],
  animations: [trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition(":enter", [animate(500)]),
    transition(":leave", [animate(500)]),
  ]),],
  providers: [MessageService]
})
export class ViewSourcesIntComponent implements OnInit {

  isLoading: boolean = false;
  buttonDisabled: boolean = false;
  progressMode: string = "determinate";
  infos: IntegratedSourceModel.DataSource<number>[] = [];
  connectorInfos: IntegratedSourceController.ConnectorInfo[] = [];
  messages: Message[] = [];
  selectedInfo?: IntegratedSourceModel.DataSource<number>;
  // file upload dialog
  show_file_dialog: boolean = false;
  file_dialog_source?: IntegratedSourceModel.DataSource<number> = undefined;

  // Progress monitoring
  subs = new SubSink;

  cols: HeaderRow[] = [
    { field: 'Id.Origin', header: 'Name', width: "70%" },
    { field: 'lastUpdated.start', header: 'Connector', width: "15%" },
    { field: 'lastUpdated.start', header: 'LastUpdated', width: "15%" },

];
items: MenuItem[] = [{ icon: 'pi pi-table', label: "Datenquellen", routerLink: '/SourceIntegrationSources' }];
//home:MenuItem = { icon: 'pi pi-table', label: "Datenquellen", routerLink: '/SourceIntegrationSources' };

  constructor(private service_api: IntegratedSourceService, private router: Router, private task_api: TasksService, private sanitizer: DomSanitizer, private datasourcesService:DatasourcesService, private route: ActivatedRoute, private messageService: MessageService, private translate: TranslateService) { }

  ngOnDestroy(): void{
    this.subs.unsubscribe();
  }

  ngOnInit(): void {

    this.isLoading = true;

    const sources_ob = this.service_api.getDataSource();
    const conns_ob = this.service_api.getConnectors();

    const all_ob = sources_ob.pipe(mergeMap(sources => conns_ob.pipe(map(connectors => [sources, connectors]))))

    this.subs.sink = all_ob.subscribe(pair => {
      const datasources = <IntegratedSourceModel.DataSource<number>[]>pair[0];
      const connectors = <IntegratedSourceController.ConnectorInfo[]>pair[1];

      this.infos = datasources;
      this.connectorInfos = connectors;
      this.isLoading = false;

      for (let info of this.infos) {
        const conn = this.connectorInfos.find(c => info.Connector.Id == c.Key.Id && info.Connector.Origin == c.Key.Origin);
        info["ConnectorInfo"] = conn;
      }
    })

  }

  // Observable<(IntegratedSourceModel.DataSource<number>[] | IntegratedSourceController.ConnectorInfo[])[]>

  /**
   * The returned observable fetches all required information for the datasources and adds their connector as extra field.
   * @param service_api API Service
   */
  static loadDataSourcesObs(service_api: IntegratedSourceService): Observable<[IntegratedSourceModel.DataSource<number>[], IntegratedSourceController.ConnectorInfo[]]> {

    const sources_ob = service_api.getDataSource();
    const conns_ob = service_api.getConnectors();

    const all_ob = sources_ob.pipe(mergeMap(sources => conns_ob.pipe(map(connectors => [sources, connectors]))))

    return all_ob.pipe(map(pair => {

      const datasources = <IntegratedSourceModel.DataSource<number>[]>pair[0];
      const connectors = <IntegratedSourceController.ConnectorInfo[]>pair[1];

      for (let info of datasources) {
        const conn = connectors.find(c => info.Connector.Id == c.Key.Id && info.Connector.Origin == c.Key.Origin);
        info["ConnectorInfo"] = conn;
      }

      return [datasources, connectors]
    }))
  }

  on_sync_source(ds: IntegratedSourceModel.DataSource<number>) {
    console.log("On Sync Source");

    const sync_arg = new IntegratedSourceModel.SyncArg(ds.Id, undefined);
    this.subs.sink = this.service_api.sync(sync_arg).subscribe(result => {
      console.log("Sync Started with Job", result);
      console.log("TODO: keep the job infos somewhere so we see which data sources are synced right now");
    },
      err => this.handle_error(err),
      () => this.setLoading(false)
    )
  }

  /**
   * Click auf File Upload.
   * Jetzt wird der Dialog angezeigt, wo der Nutzer die Datei auswählen kann.
   * @param ds
   */
  on_sync_file_source(ds: IntegratedSourceModel.DataSource<number>) {
    // push source key to component and display
    console.log("On Sync File Source");

    console.log("Show Dialog Flag", this.show_file_dialog);

    this.file_dialog_source = ds;
    this.show_file_dialog = true;
  }

  on_delete_source(ds: IntegratedSourceModel.DataSource<number>) {
    console.log("On Delete Source");

    // const dlg = confirm("Do you want to delete this data source?");
    // if (dlg) {
      const del_arg = new IntegratedSourceModel.DeleteDataSourceArg(ds.Id);

      const refresh_ob = ViewSourcesIntComponent.loadDataSourcesObs(this.service_api);
      const all_obs = this.service_api.deleteDataSource(del_arg)
        .pipe(concatMap(count => refresh_ob
          .pipe(map(pair => {
            const result: [number, [IntegratedSourceModel.DataSource<number>[], IntegratedSourceController.ConnectorInfo[]]] = [count, pair];
            return result;
          }))))

      this.setLoading(true);

      this.subs.sink = all_obs.subscribe(pairs => {
        const pair = pairs[1];
        const result = pairs[0];

        console.log("Delete Data Source successful", result);
        this.infos = pair[0];
        this.connectorInfos = pair[1];

        this.messageService.add({
            severity: "success",
            summary: this.translate.instant("Message.DeleteDataSourceSuccess.Title"),
            detail: this.translate.instant("Message.DeleteDataSourceSuccess.Text1") + this.infos[0].Id +
            this.translate.instant("Message.DeleteDataSourceSuccess.Text2"),
        });
      },
        err => this.handle_error(err),
        () => {
          this.setLoading(false);
          this.displayDeleteDatasource = false;
        }
      )
  }

  displayDeleteDatasource: boolean =false;
  on_display_delete_dialog() {
    this.displayDeleteDatasource = true;
  }

  handle_error(e: Error) {
    this.messages.push({ severity: 'error', summary: 'Error', detail: e.message });
    console.log("Error Handler", e);
    this.setLoading(false);
  }

  handle_monitor_error(e: Error) {
    console.error(e);
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }

  /**
   * Callback, navigates to Create Data Source Wizard.
   */
  onCreateSource() {
    //this.router.navigate(['/', '/SourceIntegrationChooseConnector']);
    this.router.navigateByUrl('/'+'SourceIntegrationChooseConnector');

  }
  onViewSource(ds) {
    this.router.navigateByUrl('/SourceIntegrationSource/'+ds.Id.Origin+"/"+ds.Id.Id);
  }


  onSelectInfo(event) {
    console.log(event);
    this.selectedInfo= <IntegratedSourceModel.DataSource<number>>event.data;
  }
  onUnselectInfo(event) {
    console.log(event);
    this.selectedInfo = undefined;
  }
  getSafeHtml(icon?: string): SafeHtml {
    const fixedWidth= 100;
    const modifiedSvgString = icon.replace(/<svg[^>]*width\s*=\s*["']?\d+["']?/i, `<svg width="25px"`);
    const finalSvgString = modifiedSvgString.replace(/<svg[^>]*height\s*=\s*["']?\d+["']?/i, `<svg height="25px" viewBox="0 0 25 25"`);
    return this.sanitizer.bypassSecurityTrustHtml(finalSvgString);
  }
}
