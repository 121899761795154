import { Observable, forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";
import { Arrays } from "src/app/helper/arrays";
import { WorkflowNode } from "src/app/models/api/com/bion/etl/Workflow";
import { RichDataSourceCake } from "src/app/models/api/controllers/staging/RichDataSourceCake";
import { PsaInputPlugIn } from "src/app/models/api/models/etl/PsaInputPlugIn";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { DataSource, PersistentStagingArea } from "src/app/models/datasource.model";
import { DataStore } from "src/app/models/datastore.model";
import { ScheduleActionPlan, ScheduleBaseAction, ExtractDataSourceAction } from "src/app/models/schedule.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { BottomToolbarProvider, PreviewData, ToolbarResult } from "./bottom-toolbar-provider";

export class DataSourceToolbarResult implements ToolbarResult {
    ScheduleActionPlans: ScheduleActionPlan[];
    Workflows: WorkflowRepositoryEntry[];
    Matches: boolean[];

    constructor(ScheduleActionPlans: ScheduleActionPlan[],
        Workflows: WorkflowRepositoryEntry[],
        Matches: boolean[]) {
        this.ScheduleActionPlans = ScheduleActionPlans;
        this.Workflows = Workflows;
        this.Matches = Matches;
    }

    DataSources: DataSource[] = [];
    DataStoreOutNodes: WorkflowNode[] = [];
    DataStores: DataStore[] = [];
    PsaInNodes: WorkflowNode[] = [];
    ScheduleActions: ScheduleBaseAction[] = [];
    WorkflowNodes: WorkflowNode[] = [];
}

export interface DataSourceToolbarData {
    ScheduleActionPlan: ScheduleActionPlan[];
    ScheduleActions: ScheduleBaseAction[];
    Workflows: WorkflowRepositoryEntry[];
}

export class DataSourceBottomToolbar
  implements BottomToolbarProvider<DataSource, PersistentStagingArea, DataSourceToolbarResult, DataSourceToolbarData> {

  constructor(private bionApi: ApiBackendService, private dataSourceService: DatasourcesService) { }
  getPsaData(obj: DataSource): Observable<PreviewData<PersistentStagingArea>> {

    let psaObs = this.bionApi.psaApiQuery(obj.id, 100, 0, false, true, false);
    let psaDataSourceObs = this.bionApi.getPsaOfDataSource(obj.id);

    // let result = forkJoin(psaObs,psaDataSourceObs);
    //
    // return result
    return forkJoin(psaObs, psaDataSourceObs).pipe(map(data_and_psa => {
      const psa_data: PreviewData<PersistentStagingArea> = { DataTablePreview: data_and_psa[0], DataHolder: data_and_psa[1] }
      return psa_data;
    }))
  }
  getData(): Observable<DataSourceToolbarData> {
    let schedulesObs = this.bionApi.getScheduleActionPlan();
    let scheduleActionsObs = this.bionApi.getScheduleActions();
    let workflowsObs = this.bionApi.getWorkflowObjectList();

    return forkJoin(schedulesObs, scheduleActionsObs, workflowsObs).pipe(map(all => {
      const result: DataSourceToolbarData = { ScheduleActionPlan: all[0], ScheduleActions: all[1], Workflows: all[2] }
      return result;
    }))
  }
  subscribeToEmitter(): Observable<DataSource> {
    return this.dataSourceService.selectedDatasourceEmitter;
  }
  buildToolbar(data: DataSourceToolbarData, obj?: DataSource): DataSourceToolbarResult {

    const schedules: ScheduleActionPlan[] = data.ScheduleActionPlan;
    const scheduleActions: ScheduleBaseAction[] = data.ScheduleActions;

    let extractActions = <ExtractDataSourceAction[]>scheduleActions.filter((schedule) => {
      return schedule._type = "models.scheduler.ExtractDataSourceAction"
    });

    if (obj) {
      extractActions = extractActions.filter((action) => {
        return action.dataSource === obj.id
      });
    }

    let filteredSchedules: ScheduleActionPlan[] = [];

    for (let i of extractActions) {
      for (let y of schedules) {
        if (i.actionPlan === y.id) {
          filteredSchedules.push(y);
        }
      }
    }

    //Find related workflows
    let workflows = data.Workflows;
    let filteredWorkflows: WorkflowRepositoryEntry[] = [];

    for (let workflow of workflows) {
      let wfData = workflow.workflowData;
      let wfNodes = wfData.Nodes;

      let filteredNodes = wfNodes.filter((node) => { return node.Name == "PsaInput" });

      for (let node of filteredNodes) {
        //console.log("node",node);
        let config = <PsaInputPlugIn.Settings>node.Properties.Configuration;
        let ds = config.SelectedDataSource;
        //console.log("DS NAME",ds.id);
        if (obj) {
          if (config.SelectedDataSource?.ID === obj.id) {
            filteredWorkflows.push(workflow);
          }
        } else {
          filteredWorkflows.push(workflow);
        }
      }
    }

    let statusSuccess: boolean;
    statusSuccess = !(filteredWorkflows.length === 0 && filteredSchedules.length === 0);
    console.log([filteredSchedules, filteredWorkflows, statusSuccess]);

    return new DataSourceToolbarResult(filteredSchedules, filteredWorkflows, [statusSuccess]);
  }
}

export class DataSourceRichBottomToolbar
  implements BottomToolbarProvider<DataSource, PersistentStagingArea, DataSourceToolbarResult, RichDataSourceCake.DataSourceView<any>[]> {

  constructor(private bionApi: ApiBackendService, private dataSourceService: DatasourcesService,
    private data:RichDataSourceCake.DataSourceView<any>[]) { }

  getPsaData(obj: DataSource): Observable<PreviewData<PersistentStagingArea>> {

    let psaObs = this.bionApi.psaApiQuery(obj.id, 100, 0, false, true, false);
    let psaDataSourceObs = this.bionApi.getPsaOfDataSource(obj.id);

    return forkJoin(psaObs, psaDataSourceObs).pipe(map(data_and_psa => {
      const psa_data: PreviewData<PersistentStagingArea> = { DataTablePreview: data_and_psa[0], DataHolder: data_and_psa[1] }
      return psa_data;
    }))
  }
  getData(): Observable<RichDataSourceCake.DataSourceView<any>[]> {
    return of(this.data);
  }
  subscribeToEmitter(): Observable<DataSource> {
    return this.dataSourceService.selectedDatasourceEmitter;
  }
  buildToolbar(data: RichDataSourceCake.DataSourceView<any>[], obj?: DataSource): DataSourceToolbarResult {

    const target_data = obj ? data.filter(d => d.DataSource.id === obj.id) : data;

    const filteredSchedules: ScheduleActionPlan[] = Arrays.flatMap(target_data, d => Arrays.dropUndef(d.ExtractActions.map(a => a.Plan)));
    const filteredWorkflows: WorkflowRepositoryEntry[] = [];
    console.log("WARNING: The new API must still provide the Workflow Repository Entries");
    const statusSuccess = !(filteredWorkflows.length === 0 && filteredSchedules.length === 0);


    const result = new DataSourceToolbarResult(filteredSchedules, filteredWorkflows, [statusSuccess]);

    return result;
  }
}
