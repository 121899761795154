import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin, Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { Id } from "src/app/helper/id";
import { ViewInfoRow } from "src/app/models/api/domain/NewDataSourceModelBase";
import { PowerBIModel } from "src/app/models/api/models/PowerBIModel";
import { Accessible } from "src/app/models/api/models/authorization/Accessible";
import { GroupRole } from "src/app/models/api/models/authorization/GroupRoleLike";
import { PermissionBase } from "src/app/models/api/models/authorization/PermissionBase";
import { UserRole } from "src/app/models/api/models/authorization/UserRoleLIke";
import { ViewAccessible } from "src/app/models/api/models/authorization/ViewAccessible";
import { ViewPermission } from "src/app/models/api/models/authorization/ViewPermission";
import { ReportAccessible } from "src/app/models/api/models/integrate/ReportAccessible";
import { ReportPermission } from "src/app/models/api/models/integrate/ReportPermission";
import { UserInfo } from "src/app/models/api/models/session/UserInfo";
import { DataStoreAccessible } from "src/app/models/api/models/staging/DataStoreAccessible";
import { DataStorePermission } from "src/app/models/api/models/staging/DataStorePermission";
import { WorkflowAccessible } from "src/app/models/api/models/workflow/WorkflowAccessible";
import { WorkflowPermission } from "src/app/models/api/models/workflow/WorkflowPermission";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";

import {
	DataSource,
	DataSourceAccessible,
	DataSourcePermission,
} from "src/app/models/datasource.model";
import { DataStore } from "src/app/models/datastore.model";
import { ActionPlanPermission, ActionPlanAccessible, ScheduleActionPlan } from "src/app/models/schedule.model";

import { AuthorizationService } from "src/app/services/authorization.service";
import { CubesService } from "src/app/services/cubes.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { PowerBiReportService } from "src/app/services/power-bi-report.service";
import { SchedulesService } from "src/app/services/schedules.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";

export enum View {
	DatasourceView,
	WorkflowView,
	SchedulerView,
	CubeView,
}
export class ObjectKey {
    Id: number;
    Origin: string;
    constructor(Id: number, Origin: string) {
        this.Id = Id;
        this.Origin = Origin
    }
}
export interface PermissionView<
	P extends PermissionBase<A>,
	A extends Accessible,
	E
> {
	permission: P;
	roleName: string;
	roleType: string;
	accessibleName: string;
	object: E;
}

export abstract class BasicPermissionView<
	P extends PermissionBase<A>,
	A extends Accessible,
	E,
> implements PermissionView<P, A, E>
{
	permission: P;

	protected constructor(permission: P, roleType: string, roleName: string, object: E) {
		this.permission = permission;
		this.roleType = roleType;
		this.roleName = roleName;
		this.object = object;
		this.accessibleName = "";
	}
	object: E;

	roleName: string;
	roleType: string;
	accessibleName: string;
}

export interface PermissionManager<
	P extends PermissionBase<A>,
	A extends Accessible,
	E,
	V
> {
	getPlayPermissions(p_id?: number, accessible?: any): Observable<P[]>;
	createView(
		permission: P,
		roleType: string,
		roleName: string,
		object: E,
	): PermissionView<P, A, E>;
	createAccessible(object: E): A;
	selectedObjectEmitter: EventEmitter<E>;
    getObjectFunction(id?: any): Observable<E[]>;
	updatePermissionView(p_view: V, rights: string[], role: number, isOwner: boolean): PermissionView<P, A, E>;
	getPermissionObjects(p_id?: number, accessible?: A): Observable<Array<[P, E]>>;
}

export abstract class AbstractPermissionManager<
	P extends PermissionBase<A>,
	A extends Accessible,
	E, V, AK> implements PermissionManager<P, A, E, V>{

	constructor(selectedObjectEmitter: EventEmitter<E>) {
		this.selectedObjectEmitter = selectedObjectEmitter;
	}

	abstract getPlayPermissions(p_id?: number, accessible?: any): Observable<P[]>;
	abstract createView(permission: P, roleType: string, roleName: string, object: E): PermissionView<P, A, E>;
	abstract createAccessible(object: E): A;

	selectedObjectEmitter: EventEmitter<E>;

	abstract updatePermissionView(p_view: V, rights: string[], role: number, isOwner: boolean): PermissionView<P, A, E>;
	getPermissionObjects(p_id?: number, accessible?: A): Observable<[P, E][]> {

		let dsPermissionsObs = this.getPermissionsFunction(p_id);
		let accessibleKey = accessible ? this.getAccessibleKey(accessible) : undefined;
		let datasourcesObs = this.getObjectFunction(accessibleKey);

		return forkJoin(dsPermissionsObs, datasourcesObs).pipe(map((result) => {
			let dsPermissions = result[0];
			let objects = result[1];

			let finalPermissions = new Array<[P, E]>();

			for (let perm of dsPermissions) {
				for (let obj of objects) {
					if (this.compare(perm.Accessible, obj)) finalPermissions.push([perm, obj])
				}
			}

			return finalPermissions;
		}));
	}
	abstract getPermissionsFunction(p_id?: number): Observable<P[]>;
	abstract getObjectFunction(obj_id?: AK): Observable<E[]>;
	abstract getAccessibleKey(accessible: A): AK;
	abstract compare(acccessible: A, obj: E): boolean;
}

export class WorkflowPermissionView extends BasicPermissionView<
	WorkflowPermission,
	WorkflowAccessible,
	WorkflowRepositoryEntry
> {
	constructor(
		permission: WorkflowPermission,
		roleType: string,
		roleName: string,
		object: WorkflowRepositoryEntry,

	) {
		super(permission, roleType, roleName, object);
		this.accessibleName = permission.Accessible.ID.toString();
	}
}


export class WorkflowPermissionManager
	// implements
	// PermissionManager<
	extends AbstractPermissionManager<
		WorkflowPermission,
		WorkflowAccessible,
		WorkflowRepositoryEntry,
		WorkflowPermissionView, number
	>
{
	getSelectedObjectEmitter(): EventEmitter<WorkflowRepositoryEntry> {
		return this.workflowService.selectedWorkflowEmitter;
	}
	constructor(
		private authService: AuthorizationService,
		private workflowService: WorkflowsService,
	) {
		super(workflowService.selectedWorkflowEmitter);
	}
	getPermissionsFunction(p_id?: number): Observable<WorkflowPermission[]> {
		return this.authService.getWorkflowAuthPermissions(p_id);

	}
	getObjectFunction(obj_id?: number): Observable<WorkflowRepositoryEntry[]> {
		return this.workflowService.getWorkflowObjectList(obj_id);
	}
	getAccessibleKey(accessible: WorkflowAccessible): number {
		return accessible.ID;
	}
	compare(accessible: WorkflowAccessible, obj: WorkflowRepositoryEntry): boolean {
		return accessible.ID === obj.id;
	}

	getPlayPermissions(
		p_id?: number,
		accessible?: WorkflowAccessible
	): Observable<WorkflowPermission[]> {
		let accID = accessible?.ID;
		return this.authService.getWorkflowAuthPermissions(p_id, accID);
	}
	createView(
		permission: WorkflowPermission,
		roleType: string,
		roleName: string,
		object: WorkflowRepositoryEntry
	): PermissionView<WorkflowPermission, WorkflowAccessible, WorkflowRepositoryEntry> {
		return new WorkflowPermissionView(permission, roleType, roleName, object);
	}
	createAccessible(object: WorkflowRepositoryEntry): WorkflowAccessible {
		return new WorkflowAccessible(object.id);
	}

	//selectedObjectEmitter: EventEmitter<WorkflowRepositoryEntry>;

	updatePermissionView(
		p_view: WorkflowPermissionView,
		rights: string[],
		role: number,
		isOwner: boolean
	): PermissionView<WorkflowPermission, WorkflowAccessible, WorkflowRepositoryEntry> {
		let permissionViewClone = { ...p_view };
		let permissionClone = { ...p_view.permission };

		permissionViewClone.permission = permissionClone;
		permissionViewClone.permission.Role = role;
		permissionViewClone.permission.Rights = rights;
		permissionViewClone.permission.IsOwner = isOwner;

		return permissionViewClone;
	}
}

export class DataSourcePermissionView extends BasicPermissionView<
	DataSourcePermission,
	DataSourceAccessible,
	DataSource
> {
	constructor(
		permission: DataSourcePermission,
		roleType: string,
		roleName: string,
		object: DataSource,

	) {
		super(permission, roleType, roleName, object);
		this.accessibleName = permission.Accessible.ID.toString();
	}
}

export class DatasourcePermissionManager extends AbstractPermissionManager<DataSourcePermission,
	DataSourceAccessible,
	DataSource,
	DataSourcePermissionView, number>
{
	getPermissionsFunction(p_id?: number): Observable<DataSourcePermission[]> {
		return this.authService.getDataSourcePermissions(p_id);
	}
	getObjectFunction(obj_id?: number): Observable<DataSource[]> {
		return this.datasourceService.getDatasources(obj_id);
	}
	getAccessibleKey(accessible: DataSourceAccessible) {
		return accessible.ID;
	}
	compare(acccessible: DataSourceAccessible, obj: DataSource): boolean {
		return acccessible.ID === obj.id
	}
	constructor(
		private authService: AuthorizationService,
		protected datasourceService: DatasourcesService
	) {
		super(datasourceService.selectedDatasourceEmitter);
	}

	getPlayPermissions(
		p_id?: number,
		accessible?: DataSourceAccessible
	): Observable<DataSourcePermission[]> {
		let accID = accessible?.ID;
		return this.authService.getDataSourcePermissions(p_id, accID);

		//return this.authService.getDataSourceAuthPlayPermissions(p_id, accID);
	}
	createView(
		permission: DataSourcePermission,
		roleType: string,
		roleName: string,
		object: DataSource,
	): PermissionView<DataSourcePermission, DataSourceAccessible, DataSource> {
		return new DataSourcePermissionView(permission, roleType, roleName, object);
	}
	createAccessible(object: DataSource): DataSourceAccessible {
		return new DataSourceAccessible(object.id);
	}
	updatePermissionView(
		p_view: DataSourcePermissionView,
		rights: string[],
		role: number,
		isOwner: boolean
	): PermissionView<DataSourcePermission, DataSourceAccessible, DataSource> {
		let permissionViewClone = { ...p_view };
		let permissionClone = { ...p_view.permission };

		permissionViewClone.permission = permissionClone;
		permissionViewClone.permission.Role = role;
		permissionViewClone.permission.Rights = rights;
		permissionViewClone.permission.IsOwner = isOwner;

		return permissionViewClone;
	}
}

/// PowerBi Report Permissions

export class PowerBiReportPermissionView extends BasicPermissionView<
	ReportPermission,
	ReportAccessible,
	PowerBIModel.ReportInfo
> {
	constructor(
		permission: ReportPermission,
		roleType: string,
		roleName: string,
		object: PowerBIModel.ReportInfo,

	) {
		super(permission, roleType, roleName, object);
		this.accessibleName = permission.Accessible.ID.toString();
	}
}

export class PowerBiReportPermissionManager extends AbstractPermissionManager<ReportPermission,
	ReportAccessible,
	PowerBIModel.ReportInfo,
	PowerBiReportPermissionView, number>
{
	getPermissionsFunction(p_id?: number): Observable<ReportPermission[]> {
		return this.biService.getReportPermission(p_id);
	}
	getObjectFunction(obj_id?: number): Observable<PowerBIModel.ReportInfo[]> {
		return this.biService.getReports(obj_id);
	}
	getAccessibleKey(accessible: ReportAccessible) {
		return accessible.ID;
	}
	compare(acccessible: ReportAccessible, obj: PowerBIModel.ReportInfo): boolean {
		return acccessible.ID === obj.Id
	}
	constructor(
		private authService: AuthorizationService,
		protected datasourceService: DatasourcesService,
		private biService: PowerBiReportService
	) {
		super(biService.selectedReportEmitter);
	}

	getPlayPermissions(
		p_id?: number,
		accessible?: ReportAccessible
	): Observable<ReportPermission[]> {
		let accID = accessible?.ID;
		return this.biService.getReportPermission(p_id, accID);

		//return this.authService.getDataSourceAuthPlayPermissions(p_id, accID);
	}
	createView(
		permission: ReportPermission,
		roleType: string,
		roleName: string,
		object: PowerBIModel.ReportInfo,
	): PermissionView<ReportPermission, ReportAccessible, PowerBIModel.ReportInfo> {
		return new PowerBiReportPermissionView(permission, roleType, roleName, object);
	}
	createAccessible(object: PowerBIModel.ReportInfo): ReportAccessible {
		return new ReportAccessible(object.Id);
	}
	updatePermissionView(
		p_view: PowerBiReportPermissionView,
		rights: string[],
		role: number,
		isOwner: boolean
	): PermissionView<ReportPermission, ReportAccessible, PowerBIModel.ReportInfo> {
		let permissionViewClone = { ...p_view };
		let permissionClone = { ...p_view.permission };

		permissionViewClone.permission = permissionClone;
		permissionViewClone.permission.Role = role;
		permissionViewClone.permission.Rights = rights;
		permissionViewClone.permission.IsOwner = isOwner;

		return permissionViewClone;
	}
}





/// Destinations Permissions

export class DataStorePermissionView extends BasicPermissionView<
	DataStorePermission,
	DataStoreAccessible,
	DataStore
> {
	constructor(
		permission: DataStorePermission,
		roleType: string,
		roleName: string,
		object: DataStore
	) {
		super(permission, roleType, roleName, object);
		this.accessibleName = permission.Accessible.ID.toString();
	}
}

export class DestinationPermissionManager extends AbstractPermissionManager<DataStorePermission,
	DataStoreAccessible,
	DataStore,
	DataStorePermissionView, number> {

	getPermissionsFunction(p_id?: number): Observable<DataStorePermission[]> {
		return this.authService.getDataStorePermissions(p_id);
	}
	getObjectFunction(obj_id?: number): Observable<DataStore[]> {
		return this.cubesService.getDataStoreObjectList(obj_id)
	}
	getAccessibleKey(accessible: DataStoreAccessible): number {
		return accessible.ID
	}
	compare(acccessible: DataStoreAccessible, obj: DataStore): boolean {
		return acccessible.ID === obj.id
	}
	constructor(
		private authService: AuthorizationService,
		private cubesService: CubesService
	) {
		super(cubesService.selectedDataStoreEmitter);
	}

	getPlayPermissions(p_id?: number, accessible?: any): Observable<DataStorePermission[]> {
		let accID = accessible?.ID;
		return this.authService.getDataStorePermissions(p_id, accID);
	}
	createView(permission: DataStorePermission, roleType: string, roleName: string, object: DataStore): PermissionView<DataStorePermission, DataStoreAccessible, DataStore> {
		console.log("Start")
		return new DataStorePermissionView(permission, roleType, roleName, object);
	}
	createAccessible(object: DataStore) {
		return new DataStoreAccessible(object.id);
	}
	updatePermissionView(p_view: DataStorePermissionView, rights: string[], role: number, isOwner: boolean): PermissionView<DataStorePermission, DataStoreAccessible, DataStore> {
		let permissionViewClone = { ...p_view };
		let permissionClone = { ...p_view.permission };

		permissionViewClone.permission = permissionClone;
		permissionViewClone.permission.Role = role;
		permissionViewClone.permission.Rights = rights;
		permissionViewClone.permission.IsOwner = isOwner;

		return permissionViewClone;
	}
}

// View Permissions
export class ViewPermissionView extends BasicPermissionView<
	ViewPermission,
	ViewAccessible,
	ViewInfoRow
> {
	constructor(
		permission: ViewPermission,
		roleType: string,
		roleName: string,
		object: ViewInfoRow,
	) {
		super(permission, roleType, roleName, object);
		this.accessibleName = permission.Accessible.Name;
	}
}





export class ActionPlanPermissionView
	extends BasicPermissionView<ActionPlanPermission, ActionPlanAccessible, ScheduleActionPlan> {
	constructor(
		permission: ActionPlanPermission,
		roleType: string,
		roleName: string,
		object: ScheduleActionPlan
	) {
		super(permission, roleType, roleName, object);
		this.accessibleName = permission.Accessible.ID.toString();
	}
}


export class ActionPlanPermissionManager
	extends AbstractPermissionManager<ActionPlanPermission, ActionPlanAccessible, ScheduleActionPlan, ActionPlanPermissionView, number>
{
	getPermissionsFunction(p_id?: number): Observable<ActionPlanPermission[]> {

		return this.authService.getActionPlanPermissions(p_id);
	}
	getObjectFunction(obj_id: number): Observable<ScheduleActionPlan[]> {
		return this.schedulerService.getScheduleActionPlan(obj_id);
	}
	getAccessibleKey(accessible: ActionPlanAccessible): number {
		return accessible.ID
	}
	compare(acccessible: ActionPlanAccessible, obj: ScheduleActionPlan): boolean {
		return acccessible.ID === obj.id
	}
	constructor(
		private authService: AuthorizationService,
		private schedulerService: SchedulesService
	) {
		super(schedulerService.selectedSchedulePlanEmitter);
	}

	getPlayPermissions(
		p_id?: number,
		accessible?: ActionPlanAccessible
	): Observable<ActionPlanPermission[]> {
		let accID = accessible?.ID;
		return this.authService.getActionPlanPermissions(p_id, accID);
	}
	createView(
		permission: ActionPlanPermission,
		roleType: string,
		roleName: string,
		object: ScheduleActionPlan,
	): PermissionView<ActionPlanPermission, ActionPlanAccessible, ScheduleActionPlan> {
		return new ActionPlanPermissionView(permission, roleType, roleName, object);
	}
	createAccessible(object: ScheduleActionPlan): ActionPlanAccessible {
		return new ActionPlanAccessible(object.id);
	}
	updatePermissionView(
		p_view: ActionPlanPermissionView,
		rights: string[],
		role: number,
		isOwner: boolean
	): PermissionView<ActionPlanPermission, ActionPlanAccessible, ScheduleActionPlan> {
		let permissionViewClone = { ...p_view };
		let permissionClone = { ...p_view.permission };

		permissionViewClone.permission = permissionClone;
		permissionViewClone.permission.Role = role;
		permissionViewClone.permission.Rights = rights;
		permissionViewClone.permission.IsOwner = isOwner;

		return permissionViewClone;
	}
}

export class ViewPermissionManager
	extends AbstractPermissionManager<ViewPermission, ViewAccessible, ViewInfoRow, ViewPermissionView, string>
{

	getPermissionsFunction(p_id?: number): Observable<ViewPermission[]> {
		return this.authService.getViewPermission(p_id)
	}
	getObjectFunction(obj_id: any): Observable<ViewInfoRow[]> {
		return this.authService.getViewInfoRow(obj_id);
	}
	getAccessibleKey(accessible: ViewAccessible): string {
		return accessible.Name
	}
	compare(acccessible: ViewAccessible, obj: ViewInfoRow): boolean {
		return acccessible.Name === obj.name
	}
	constructor(
		private authService: AuthorizationService,
	) {
		super(new EventEmitter<ViewInfoRow>());
	}
	getPlayPermissions(
		p_id?: number,
		accessible?: ViewAccessible
	): Observable<ViewPermission[]> {
		let accID = accessible?.Name;

		return this.authService.getViewPermission(p_id, accID);
	}
	createView(
		permission: ViewPermission,
		roleType: string,
		roleName: string,
		object: ViewInfoRow
	): PermissionView<ViewPermission, ViewAccessible, ViewInfoRow> {
		return new ViewPermissionView(permission, roleType, roleName, object);
	}
	createAccessible(object: ViewInfoRow): ViewAccessible {
		return new ViewAccessible(object.name);
	}
	updatePermissionView(
		p_view: ViewPermissionView,
		rights: string[],
		role: number,
		isOwner: boolean
	): PermissionView<ViewPermission, ViewAccessible, ViewInfoRow> {
		const permissionViewClone = { ...p_view };
		const permissionClone = { ...p_view.permission };

		permissionViewClone.permission = permissionClone;
		permissionViewClone.permission.Role = role;
		permissionViewClone.permission.Rights = rights;
		permissionViewClone.permission.IsOwner = isOwner;

		return permissionViewClone;
	}
}


@Component({
	selector: "app-user-permissions-list",
	templateUrl: "./user-permissions-list.component.html",
	styleUrls: ["./user-permissions-list.component.scss"],
})
export class UserPermissionsListComponent<
	P extends PermissionBase<A>,
	A extends Accessible,
	E,
	V
> implements OnInit, OnDestroy {
	permissionManager?: PermissionManager<P, A, E, V>;
	subs = new SubSink();
	@Input() view!: string;
	genericPermissions: Array<P> = [];
	genericPermissionView: PermissionView<P, A, E>[] = [];
	rights: string[] = [];
	selectedObject?: E;

	constructor(
		private userService: UserService,
		private authService: AuthorizationService,
		private workflowService: WorkflowsService,
		private datasourcesService: DatasourcesService,
		private cubesService: CubesService,
		private schedulerService: SchedulesService,
		private errorService: SystemMessageLogService,
		private biService: PowerBiReportService,
        private router: Router
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.rights = ["Admin","Create","Delete","Read","Write"];



		// Load correct Permission Manager
		if (this.view === "WorkflowView") {
			let permissionManager = new WorkflowPermissionManager(
				this.authService,
				this.workflowService
			);
			let permissionManagerAny = <any>permissionManager;
			this.permissionManager = <PermissionManager<P, A, E, V>>permissionManagerAny;
		}
		if (this.view === "DatasourceView") {
			let permissionManager = new DatasourcePermissionManager(
				this.authService,
				this.datasourcesService
			);
			let permissionManagerAny = <any>permissionManager;
			this.permissionManager = <PermissionManager<P, A, E, V>>permissionManagerAny;
		}
		if (this.view === "Views") {
			let permissionManager = new ViewPermissionManager(
				this.authService
			);
			let permissionManagerAny = <any>permissionManager;
			this.permissionManager = <PermissionManager<P, A, E, V>>permissionManagerAny;
		}
		if (this.view === "SchedulerView") {
			let permissionManager = new ActionPlanPermissionManager(
				this.authService,
				this.schedulerService
			);
			let permissionManagerAny = <any>permissionManager;
			this.permissionManager = <PermissionManager<P, A, E, V>>permissionManagerAny;
		}
		if (this.view === "DestinationView") {
			let permissionManager = new DestinationPermissionManager(
				this.authService,
				this.cubesService
			);
			let permissionManagerAny = <any>permissionManager;
			this.permissionManager = <PermissionManager<P, A, E, V>>permissionManagerAny;
		}
		if (this.view === "PowerBiReports") {
			let permissionManager = new PowerBiReportPermissionManager(
				this.authService,
				this.datasourcesService,
				this.biService
			);
			let permissionManagerAny = <any>permissionManager;
			this.permissionManager = <PermissionManager<P, A, E, V>>permissionManagerAny;
		}
		if (this.permissionManager === undefined) {
			throw new Error(
				"PermissionManager undefined, please implement a manager with the name: " +
				this.view
			);
		}


		// Init Permission Gui View
        if(this.permissionManager) {
            const urlContext = this.getUrlContext();
            this.subs.sink = this.permissionManager.getObjectFunction(urlContext.Id).subscribe((res) => {
                const _res = res[0];
                this.genericInitPermissionView(
                    undefined,
                    this.permissionManager?.createAccessible(_res)
                );
            });
        }

		this.subs.sink = this.authService.permissionsChangedEmitter.subscribe(() => {
			//this.genericInitPermissionView();
            const urlContext = this.getUrlContext();
            this.subs.sink = this.permissionManager.getObjectFunction(urlContext.Id).subscribe((res) => {
                const _res = res[0];
                this.genericInitPermissionView(
                    undefined,
                    this.permissionManager?.createAccessible(_res)
                );
            });
		});
		this.subs.sink = this.datasourcesService.datasourceChangedEmitter.subscribe(() => {
			this.genericInitPermissionView();
		});
		this.subs.sink = this.workflowService.workflowsChangedEmitter.subscribe(() => {
			this.genericInitPermissionView();
		});
		this.subs.sink = this.schedulerService.schedulesChangedEmitter.subscribe(() => {
			this.genericInitPermissionView();
		});
		this.subs.sink = this.biService.reportChangedEmitter.subscribe(() => {
			this.genericInitPermissionView();
		});
		this.cubesService.dataStoresChangedEmitter.subscribe(() => {
			this.genericInitPermissionView();
		});
	}

      /**
   * Der Datasource Schlüssel aus der Route.
   * @returns Data Source Key
   */

  getUrlContext(): ObjectKey {
    const arr = this.router.url.split('/');
    const id = parseInt(arr.last());
    const origin = arr.getRight(1);
    console.log("ID: " + id);
    console.log("Origin: " + origin);
    return new ObjectKey(id, origin);
  }

	genericInitPermissionView(p_id?: number, accessible?: A) {
		let userRolesObs = this.authService.getUserRoles();
		let usersObs = this.userService.getUserCommon();
		let groupRolesObs = this.authService.getGroupRoles();

		this.subs.sink = this.getAccessiblePermissionObjects(p_id, accessible).subscribe(
			(res) => {
				this.genericPermissions = res.map((entry) => { return entry[0] });
				this.subs.sink = forkJoin(
					userRolesObs,
					usersObs,
					groupRolesObs
				).subscribe((rolesResult) => {
					const views = this.buildGenericPermissionObjectView(
						res,
						rolesResult[0],
						rolesResult[1],
						rolesResult[2]
					);
					this.genericPermissionView = views;
				},
					(err: Error) => {
						this.errorService.handleError(err);
					});
			}
		);
	}
	getAccessiblePermissions(p_id: number, accessible: A): Observable<P[]> {
		if (this.permissionManager) {
			return this.permissionManager.getPlayPermissions(p_id, accessible);
		} else {
			return throwError(new Error("No Permission Manager set!"));
		}

	}
	getAccessiblePermissionObjects(p_id?: number, accessible?: A): Observable<[P, E][]> {
		if (this.permissionManager) {
			return this.permissionManager.getPermissionObjects(p_id, accessible);
		} else {
			return throwError(new Error("No Permission Manager set!"));
		}

	}


	buildGenericPermissionObjectView(
		permObjTuples: [P, E][],
		userroles: UserRole[],
		users: UserInfo[],
		grouproles: GroupRole[]
	) {

		const permission_manager = Id.assertSet(this.permissionManager, new Error("The Permission Manager is not set!"));

		let permissionViews = permObjTuples.map((permission: [P, E]) => {
			const user_role_found = userroles.find(userrole => permission[0].Role === userrole.ID);
			const grouproleFound = grouproles.find(grouprole => permission[0].Role === grouprole.ID);

			let roleType: string | undefined;
			let roleName: string | undefined;

			if (user_role_found) {
				roleName = users.find(user => user.ID === user_role_found?.UserID)?.Username; // TODO: CHECK FOR UNDEFINED USERID
				roleType = "UserRole";
			}
			if (grouproleFound) {
				roleType = "GroupRole";
				roleName = grouproleFound.Name;
			}

			const role_type = Id.assertSet(roleType, new Error("The role type was not set!"));
			const role_name = Id.assertSet(roleName, new Error("The role name was not set!"));

			let permissionView = permission_manager.createView(
				permission[0],
				role_type,
				role_name,
				permission[1]
			);
			return permissionView;
		});

		// this.genericPermissionView = permissionViews;
		return permissionViews;
	}

	// // EVENT EMITTERS
	onMenuClicked(p: PermissionView<P, A, E>) {
		this.userService.selectedMenuPermissionEmitter.emit([
			p,
			Id.assertSet(this.permissionManager, new Error("Permission Manager is undefined")),
		]);
	}

	// CRUID DIALOG EMITTERS
	displayNewUserPermissionDialog() {
		this.userService.displayAddPermission.emit(true);
	}
	displayEditPermissionDialog() {
		this.userService.displayEditPermission.emit(true);
	}
	displayDeletePermissionDialog() {
		this.userService.displayDeletePermission.emit(true);
	}
}
