
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { Observable, forkJoin } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { AppMainComponent } from 'src/app/app.main.component';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { DataStore } from 'src/app/models/datastore.model';
import { WriteMode } from 'src/app/models/helperClass.model';
import { UserDetailsRow } from 'src/app/models/user.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { CubesService } from 'src/app/services/cubes.service';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { DestinationService } from 'src/app/services/destinationService';
import { ExperimentalApi } from 'src/app/services/experimental-api.service';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { ObjectSearchService } from 'src/app/services/object-search.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { DestinationRecord, DestinationViewRecord } from 'src/app/views/objectManagement/general-object-view/provider-data-store';
import { DestinationSearch } from 'src/app/views/objectManagement/general-search/general-search.component';
import { DestinationBottomToolbar } from 'src/app/views/objectManagement/generic-bottom-toolbar/provider-data-store';
import { DestinationFieldInfoProvider, DestinationRichFieldInfoProvider } from 'src/app/views/objectManagement/generic-field-infos/field-info-data-store';
import { GenericLatestActivitiesComponent } from 'src/app/views/objectManagement/generic-latest-activities/generic-latest-activities.component';
import { DestinationActivity, DestinationRichActivity } from 'src/app/views/objectManagement/generic-latest-activities/provider-data-store';
import { DestinationToolbar } from 'src/app/views/objectManagement/generic-top-toolbar/provider-data-store';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-destination-view',
  templateUrl: './destination-view.component.html',
  styleUrls: ['./destination-view.component.scss'],
  providers: [MessageService]
})
export class DestinationViewComponent implements OnInit {
  subs = new SubSink;
  loading: boolean = false;
  userDetails: UserDetailsRow[] = [];
  selectedDestination?:DataStore;
  selectedDestinationCopy?:DataStore;

  connectorInfo?: string = "DataStore";
  editModeOn: boolean = false;
  @ViewChild('psaTable') psaTable;
  @ViewChild('objectPipeline') objectPipeline;
  @ViewChild('permView') permView;
  @ViewChild('destinationLatestActivities') destinationLatestActivities!: GenericLatestActivitiesComponent<DataStore, any>;

  // WF specific TypeClass
  destinationActivityTypeClass = new DestinationActivity(this.bionApi, this.dataStoreService, this.userDetails, this.utilService,this.router);
  // -- NEW TYPE CLASSES - Using rich API
  destinationViewActivityTypeClass = new DestinationRichActivity(this.dataStoreService, [],this.userDetails, this.utilService,this.router);


  constructor(
    private bionApi: ApiBackendService,
    public appMain: AppMainComponent,
    private datasourcesService: DatasourcesService,
    private dataStoreService: CubesService,
    private systemLogService: SystemMessageLogService,
    private service_api: IntegratedSourceService,
    private userService: UserService,
    private destService: DestinationService,
    private objectSearchService: ObjectSearchService,
    private utilService: UtilFunctionsService,
    public translate: TranslateService,
    public experimentalApi: ExperimentalApi,
    public router: Router,
    private messageService: MessageService
  ) { }
  ngOnInit(): void {
    this.writeModes = this.datasourcesService.getWriteModes().filter( wm => wm.name !== "Update" && wm.name !== "Delta" );

    this.subs.sink = this.initViewNew().subscribe(() => {
      this.loading = false;
      console.log("Load Details complete and success");
      console.log(this.selectedDestination);
    }, err => {
      this.systemLogService.handleError(err);
    });
    this.subs.sink = this.dataStoreService.selectedDataStoreEmitter.subscribe(
      (wf: DataStore) => {
        this.subs.sink = this.initViewNew().subscribe(() => {
          this.loading = false;
        }, err => {
          this.systemLogService.handleError(err);
        });
      }
    );
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Diese Version von InitView überschreibt die Typklassen für die neue Rich API.
   * @param selected Possible selected Data Store
   * @returns
   */
  initViewNew(): Observable<boolean> {
    this.loading = true;

    // Call BE data from API
    const userDetailsObs = this.userService.getUserDetailsRow();
    const destObs = this.experimentalApi.getDataStoreView();
    const dTypesObs = this.bionApi.getDataTypes();
    //const connectorObs = this.service_api.getConnectors();
    const source_key = this.getUrlContext();

    const obs = forkJoin(destObs,userDetailsObs,dTypesObs);

    const finalObs = obs.pipe(concatMap(rich_data => {
        const data_stores = rich_data[0].map(d => d.DataStore);
        console.log(rich_data);
        this.userDetails = rich_data[1];
        this.selectedDestination = data_stores.find(d => d.id === source_key.Id);
        this.selectedDestinationCopy = {...this.selectedDestination};

        this.psaTable.initDataStore(this.selectedDestination);
        //this.objectPipeline.selectedDataStore = this.selectedDestination;
        this.objectPipeline.createApiUrl(this.selectedDestination);
        this.permView.selectedObject = this.selectedDestination;

        this.destinationViewActivityTypeClass = new DestinationRichActivity(this.dataStoreService, rich_data[0],this.userDetails, this.utilService,this.router);
        const activity_obs = this.destinationLatestActivities.setObject(this.destinationViewActivityTypeClass);

        return forkJoin(new Array(activity_obs));

    }))
    return finalObs.pipe(map(() => true));

  }

  onSendRequest(sender: string) {
    let subject: any;
    let bodyText: any;

    if(sender === "Api") {
        subject = this.translate.instant("I want to request a API");
        bodyText = this.translate.instant("Hi Bion support, i want to request a API. Here is the description:");
    }
    if(sender === "Filetype") {
        subject = this.translate.instant("I want to request a file type");
        bodyText = this.translate.instant("Hi Bion support, i want to request a new file type. Here is the description:");
    }
    const mailText = "mailto:support@bion-analytics.com?subject="+subject+"&body="+bodyText; // add the links to body
    window.location.href = mailText;
  }

  /**
   * Der Datasource Schlüssel aus der Route.
   * @returns Data Source Key
   */
  getUrlContext(): IntegratedSourceModel.DataSourceKey<number> {
    const arr = this.router.url.split('/');
    const id = parseInt(arr.last());
    const origin = arr.getRight(1);
    console.log("Data Source ID: " + id);
    console.log("Data Source Origin: " + origin);
    return new IntegratedSourceModel.DataSourceKey(id, origin);
  }


  confirmDialog: boolean = false;
  writeModes: WriteMode[] = [];
  onChangeEditMode() {
    if(this.editModeOn) {
        if(this.selectedDestination.name === this.selectedDestinationCopy.name && this.selectedDestination.description == this.selectedDestinationCopy.description && this.selectedDestination.writeMode == this.selectedDestinationCopy.writeMode) {
            // Do nothing, just disable input
            this.editModeOn = false;
        } else {
            // show popup to ask
            this.confirmDialog = true;
        }
    } else {
        // activate input field to change data
        this.editModeOn = true;
    }
  }
  onInfoChanged() {

  }
  onChangeDestination() {
    this.loading = true;

    this.subs.sink = this.dataStoreService.updateDataStoreObject(this.selectedDestinationCopy).subscribe((ds) => {
        this.subs.sink = this.initViewNew().subscribe(() => {
            this.confirmDialog = false;
            this.dataStoreService.dataStoresChangedEmitter.emit(ds);
            this.messageService.add({
                severity: "success",
                summary: this.translate.instant("Message.UpdateDataStoreSuccess.Title"),
                detail: this.translate.instant("Message.UpdateDataStoreSuccess.Text1") + this.selectedDestinationCopy.id +
                this.translate.instant("Message.UpdateDataStoreSuccess.Text2"),
            });
        },(err) => {
            this.systemLogService.handleError(err)
        });

    },(err) => this.systemLogService.handleError(err),() => {
        this.editModeOn = false;
        this.loading = false;

    });


  }
  onResetDialog() {
    this.selectedDestinationCopy = this.selectedDestination;
    this.editModeOn = false;
    this.confirmDialog = false;
  }

}
