import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { Subject } from 'rxjs';
import { ConsoleLogger, LogLevel } from 'src/app/views/designer/components/workflow-graph/logger';
import { ObjectTypeComponent } from './object.type';
//import 'bootstrap/dist/css/bootstrap.min.css'; // Import der Bootstrap-Styles
//import 'node_modules/bootstrap/dist/css/bootstrap.min.css'; // Import der Bootstrap-Styles

/**
 * Airbyte Connector Specifications.
 */
interface AirbyteConnectorSpecification {
  sourceDefinitionId: string;
  documentationUrl: string;
  connectionSpecification: any;
}

/**
 * Diese Komponente ist für die dynamische Airbyte Config. Sie akzeptiert die Konnektor Specs und liefert das Config-Objekt.
 */
@Component({
  selector: 'app-create-config-int-ab',
  templateUrl: './create-config-int-ab.component.html',
  //styleUrls: ['./create-config-int-ab.component.scss', '../../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css']
  styleUrls: ['./create-config-int-ab.component.scss']
})
export class CreateConfigIntAbComponent implements OnInit {

  constructor(private formlyJsonschema: FormlyJsonschema) { }

  ngOnInit(): void {
  }

  @Input()
  get connectorSpecs(): AirbyteConnectorSpecification { return this.model; }
  set connectorSpecs(m: AirbyteConnectorSpecification) {
    this.logger.info("Set Connector Specs", m);
    this.loadAirbyteForm(m.connectionSpecification);
  }

  @Output()
  public onConfigSet: EventEmitter<any> = new EventEmitter<any>();

  private logger = new ConsoleLogger("CreateDatasourceIntComponent", LogLevel.Info);
  private destroy$: Subject<any> = new Subject<any>();
  form: FormGroup;
  model: any;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];

  loadAirbyteForm(connectionSpecification: any) {
    console.log("Load Airbyte Form", connectionSpecification);
    this.patchSchemaFormly(connectionSpecification);
    console.log("Schema after patch", connectionSpecification);

    this.form = new FormGroup({});
    this.options = {};
    this.fields = [this.formlyJsonschema.toFieldConfig(connectionSpecification)];
    this.model = {};
  }

  /**
   * Konfiguriert das Schema für das korrekte Formly-Rendering.
   * Dazu wird jedem Feld ein Attribut 'templateOptions' hinzugefügt, wo genau gesteuert wird,
   * wie der Eintrag zu rendern ist.
   */
  patchSchemaFormly(schema: any) {
    console.log("Patching Schema Formly");
    this.patchSchemaObject(schema);
  }

  patchSchemaObject(schema: any) {
    const props = schema["properties"];

    if(props === undefined) {
      return;
    }

    // Wird vom alias übernommen.
    // if(schema.type == "object") {
    //   console.log("Try custom object");
    //   schema.type = ObjectTypeComponent;
    // }

    for (let key of Object.keys(props)) {
      const value = props[key];

      if (value.type == "object") {
        this.patchSchemaObject(value);
      } else if (value.type == "array") {

      } else {
        // atomic
        this.patchSchemaAtomic(value);
      }

    }
  }

  patchSchemaField(schema: any) {

  }
  patchSchemaAtomic(schema: any) {

    const value = schema;

    // Datum patchen:
    // https://formly.dev/docs/ui/primeng/
    if (value.type == "string" && value.format == "date-time") {
      value.type = "datepicker";

      const date_props = {
        label: 'Datepicker',
        placeholder: 'Placeholder',
        description: 'Description',
        dateFormat: 'yy/mm/dd',
        hourFormat: '24',
        numberOfMonths: 1,
        selectionMode: 'single',
        required: true,
        readonlyInput: false,
        showTime: false,
        showButtonBar: true,
        showIcon: false,
        showOtherMonths: true,
        selectOtherMonths: false,
        monthNavigator: false,
        yearNavigator: false,
        yearRange: '2020:2030',
        inline: false,
      };

      value.props = date_props;

    }
  }

  submit() {
    const config = this.model;
    console.log("Final Argument", config);

    this.onCreate(config);
  }

  onCreate(data: any) {
    this.logger.debug("Config is valid", data);
    this.onConfigSet.emit(data);
  }
}
