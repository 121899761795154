import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, Message } from 'primeng/api';
import { concatMap, map } from 'rxjs/operators';
import { Id } from 'src/app/helper/id';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { ConsoleLogger, LogLevel } from 'src/app/views/designer/components/workflow-graph/logger';

@Component({
  selector: 'app-create-datasource-int-page',
  templateUrl: './create-datasource-int-page.component.html',
  styleUrls: ['./create-datasource-int-page.component.scss']
})
export class CreateDatasourceIntPageComponent implements OnInit {
  //items: MenuItem[] = [{ label: '1. Konnektor auswählen', disabled: false, routerLink: '/SourceIntegrationCreateDatasource'}, { label: '2. Datenquelle einrichten',disabled: false,routerLink: '/SourceIntegrationCreateDatasource'  }, { label: '3. Tabellen auswählen',disabled: true, routerLink: '/SourceIntegrationCreateDatasource'  }];
  items: MenuItem[] = [{ label: this.translate.instant('1. Konnektor auswählen'), disabled: false}, { label: this.translate.instant('2. Datenquelle einrichten'),disabled: false, routerLink: '/SourceIntegrationCreateDatasource'}, { label: this.translate.instant('3. Tabellen auswählen'),disabled: true, routerLink: '/SourceIntegrationCreateDatasource'}];



  private logger = new ConsoleLogger("CreateDatasourceIntPageComponent", LogLevel.Info);

  isLoading = false;
  selected_connector_key?: IntegratedSourceModel.ConnectorKey<string> = undefined;
  datasource_name: string = "";
  config_to_display: string = "";  // Airbyte or Bion
  selected_connector_spec?: IntegratedSourceModel.ConnectorSpec<string, any>; // Ausgewählte Konnektor Informationen (Konfig, Typ, ...)
  bion_file?:File;

  messages: Message[] = [];
  progressMode: string = "determinate";

  readonly BionConfig = "Bion";
  readonly AirbyteConfig = "Airbyte";

  constructor(private service_api: IntegratedSourceService, private router: Router, private route: ActivatedRoute, private translate: TranslateService) { }

  ngOnInit(): void {
    this.setBreadcrumb();
    // The old approach has problem with whitespaces in the connector name!
    // this.selected_connector_key = this.getUrlContext();
    // const conn_ob_old = this.service_api.getConnectorSpecs(this.selected_connector_key);

    const conn_ob = this.route.url.pipe(concatMap(segments => {
      const conn_key = this.extractUrlContext(segments);
      this.selected_connector_key = conn_key;
      return this.service_api.getConnectorSpecs(this.selected_connector_key)
    }))

    this.setLoading(true);
    const sub = conn_ob.subscribe(
      spec_res => this.handle_spec_result(spec_res),
      err => this.handle_error(err),
      () => this.setLoading(false)
    );

    // TODO: handle sub
    this.logger.warn("TODO: handle Subscription variable");
  }

  setBreadcrumb() {

    const currentRoute = this.router.url;
    console.log("currentRoute",currentRoute);
    //this.items = <MenuItem[]>[{ label: '1. Konnektor auswählen', disabled: false, routerLink: '/SourceIntegrationChooseConnector'}, { label: '2. Datenquelle einrichten',disabled: false,routerLink: currentRoute  }, { label: '3. Tabellen auswählen',disabled: true, routerLink: ''  }];
    this.items = <MenuItem[]>[{ label: this.translate.instant('1. Konnektor auswählen'), disabled: false, routerLink: '/SourceIntegrationChooseConnector'}, { label: this.translate.instant('2. Datenquelle einrichten'),disabled: false,routerLink: currentRoute}, { label: this.translate.instant('3. Tabellen auswählen'),disabled: true, routerLink: ''}];

  }



  /**
   * Holt den Konnektor aus der URL
   * @param segments
   * @returns
   */
  extractUrlContext(segments: UrlSegment[]): IntegratedSourceModel.ConnectorKey<string> {

    const origin_path = segments[1];
    const id_path = segments[2];

    return new IntegratedSourceModel.ConnectorKey(id_path.path, origin_path.path)
  }

  /**
   * Liefert den Konnektorschlüssel aus der Route
   */
  getUrlContext(): IntegratedSourceModel.ConnectorKey<string> {

    this.route.url.subscribe(console.log); // UrlSegment[]

    const arr = this.router.url.split('/');
    const id = arr[arr.length - 1];
    const origin = arr[arr.length - 2];
    return new IntegratedSourceModel.ConnectorKey(id, origin);
  }

  handle_error(e: Error) {
    this.messages.push({ severity: 'error', summary: 'Error', detail: e.message });
    this.logger.error("Error Handler", e);
    this.setLoading(false);
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }

  /**
 * Läd die Konnektor Konfiguration.
 * @param result Konnektor Spezifikationen
 */
  handle_spec_result(result: IntegratedSourceModel.ConnectorSpec<string, any>) {
    this.selected_connector_spec = result;
    this.config_to_display = result.ConnectorId.Origin;
    this.setLoading(false);
  }

  /**
 * Überprüft den Datasource Namen. Leere Namen sind nicht erlaubt.
 * @param name Datasource Name
 * @throws Name is empty
 */
  validate_ds_name(name: string) {
    if (name.length == 0) throw new Error("The datasource name must not be empty");
  }

  on_airbyte_config_set(config: any) {
    this.on_create_datasource_gen(config);
  }

  on_bion_config_set(config:any) {
    this.on_create_datasource_gen(config);
  }
  on_bion_file_set(file:File) {
    console.log("On Bion FIle");
    this.bion_file = file;
  }

  on_create_datasource_gen(config: any) {
    this.logger.warn("TODO: push connector key & config to parameters")

    this.messages = [];
    this.setLoading(true);
    const con_key = Id.assertSet(this.selected_connector_key, new Error("Connector Key not set!"));

    try {
      const name: string = this.datasource_name;
      this.validate_ds_name(name);

      const arg = new IntegratedSourceModel.CreateDataSourceArg(con_key, config, name);

      this.service_api.createDataSource(arg).subscribe(
        ds_result => this.handle_create_ds_result(ds_result),
        err => this.handle_error(err),
        () => this.setLoading(false)
      );
    }
    catch (e) {
      this.handle_error(e);
    } finally {
      this.setLoading(false);
    }

  }

  on_create_datasource() {

    this.logger.warn("TODO: push connector key & config to parameters")

    try {

      // TODO: Check user input
      const con_key = Id.assertSet(this.selected_connector_key, new Error("Connector Key not set!"));
      let config: any = undefined;
      switch (con_key.Origin) {
        case this.BionConfig: config = this.get_bion_config();
          break;
        case this.AirbyteConfig: config = this.get_airbyte_config();
          break;
        default:
          throw new Error("Origin of " + con_key.Origin + " is unknown");
      }

      const name: string = this.datasource_name;
      this.validate_ds_name(name);

      const arg = new IntegratedSourceModel.CreateDataSourceArg(con_key, config, name);

      console.log("Create Data Source Argument", arg);

      this.service_api.createDataSource(arg).subscribe(
        ds_result => this.handle_create_ds_result(ds_result),
        err => this.handle_error(err),
        () => this.setLoading(false)
      );
    }
    catch (e) {
      this.handle_error(e);
    } finally {
      this.setLoading(false);
    }
  }

  handle_create_ds_result(result: IntegratedSourceModel.CreateDataSourceResult<any>) {
    this.logger.info("Data Source successfully created!", result);
    this.setLoading(false);
    //this.datasource_key = result.SourceKey;
    //this.gotoDefineStreams(result);

    // TODO: Go to Select Streams Page
    this.gotoDefineStreams(result.SourceKey, this.bion_file);
  }

  /**
 * Holt die Konfiguration aus dem speziellen Bion Dialog
 */
  get_bion_config(): any {
    throw new Error("Not Implemented");
  }
  /**
   * Holt die Konfiguration aus dem Formly Dialog
   */
  get_airbyte_config(): any {
    throw new Error("Not Implemented");
  }

  /**
   * Go to Define Streams page sending source key via url and file via route extras.
   * @param key
   * @param file
   */
  gotoDefineStreams(key: IntegratedSourceModel.DataSourceKey<number>, file?:File) {

    console.log("Gogo Define Streams");
    console.log("Data Source Key", key);
    console.log("File", file);

    const id = key.Id;
    const origin = key.Origin;

    // add file to routing
    //https://stackoverflow.com/questions/62027465/pass-file-between-components-in-angular
    // this.router.navigate(['/some-route'], {state: {data: myFile}});

    const extras = {state: {data: file}};
    this.router.navigate(['/', 'SourceIntegrationDefineStreams', origin, id], extras);
  }

  /**
   * Verbindungseinstellungen testen.
   */
  testSourceClick() {
    console.warn("Test Source Settings not implemented yet");
  }
}
