<app-create-workflow-dialog [sender]="sender" [selectedWorkflow]="selectedWorkflow"
    (click)="appMain.rightPanelClick = true"></app-create-workflow-dialog>
<app-open-workflow-dialog (click)="appMain.rightPanelClick = true"></app-open-workflow-dialog>
<app-create-datastore-dialog (click)="appMain.rightPanelClick = true"></app-create-datastore-dialog>
<app-create-datasource-dialog-new (click)="appMain.rightPanelClick = true"></app-create-datasource-dialog-new>
<app-load-data-to-psadialog (click)="appMain.rightPanelClick = true"></app-load-data-to-psadialog>
<app-node-data-preview (click)="appMain.rightPanelClick = true"></app-node-data-preview>

<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>

<p-overlayPanel #op>
    <div *ngIf="selectedWorkflow && selectedWorkflow.runInfo">
        <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0">
            <div class="field-checkbox">
                <p-checkbox [(ngModel)]="IsSimulationFlag" [binary]="true"></p-checkbox>
                <label for="binary">{{'Simulation' | translate}}</label>
            </div>
        </div>
        <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" *ngIf="IsSimulationFlag === true">
            <div class="p-fluid">
                <span class="p-float-label">
                    <input type="number" id="maxRowsInput" [disabled]="inputDisabled" pInputText [(ngModel)]="maxRows">
                    <label for="maxRowsInput">{{'Max Rows' | translate}}</label>
                </span>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" style="text-align: center;">
        <button pButton label="{{'Save' | translate }}" class="p-button-succes p-button-rounded p-button-text"
            (click)="onChangeRunMode()"></button>
    </div>

</p-overlayPanel>

<div class="p-grid" id="container" @fade
    style="top:-40px;left: -20px;width: calc(100% + 55px);padding: 0; margin: 0;height: calc(100% - 50px)">
    <div style="z-index: 1005; position:absolute;top:-58px;right:40%; display:flex;align-items: center">
        <input *ngIf="selectedWorkflow" type="text" pInputText [(ngModel)]="selectedWorkflow.name"
            (blur)="onChangeWorkflowName()" />
        <input *ngIf="!selectedWorkflow" [disabled]="true" type="text" pInputText [(ngModel)]="workflowName"
            (blur)="onChangeWorkflowName()" />
        <!-- <p-toggleButton  *ngIf="selectedWorkflow && selectedWorkflow.runInfo" [(ngModel)]="selectedWorkflow.runInfo.simulation.ReadOnly"  onLabel="'Simulation Mode'" offLabel="'Production Mode'" (onChange)="onChangeRunMode($event)"></p-toggleButton>
            <p-toggleButton  *ngIf="!selectedWorkflow || !selectedWorkflow.runInfo" [(ngModel)]="isSimulationFlag"  onLabel="'Simulation Mode'" offLabel="'Production Mode'" (onChange)="onChangeRunMode($event)"></p-toggleButton> -->
        <div stle="margin-left: 10px" *ngIf="selectedWorkflow && selectedWorkflow.runInfo">
            <p-tag class="simulationTag" value="Simulation"
                *ngIf="selectedWorkflow.runInfo.simulation.ReadOnly === true" [rounded]="true"
                (click)="op.toggle($event)"></p-tag>
            <p-tag class="simulationTag" severity="success"
                *ngIf="selectedWorkflow.runInfo.simulation.ReadOnly === false" value="Production" [rounded]="true"
                (click)="op.toggle($event)"></p-tag>
        </div>
        <!-- <h5 *ngIf="selectedWorkflow">{{selectedWorkflow.name}}</h5> -->
    </div>
    <!-- <span style="z-index: 1005; position:absolute;top:0px;left:50%" *ngIf="selectedWorkflow && selectedWorkflow.runInfo">
        <p-toggleButton [(ngModel)]="selectedWorkflow.runInfo.simulation.ReadOnly"  onLabel="'Simulation Mode'" offLabel="'Production Mode'" (onChange)="onChangeRunMode($event)"></p-toggleButton>
    </span> -->

    <div class="fadeInDown" style="z-index: 105; position:absolute;top: -10px;padding: 0; margin: 0 0px;"
        (click)="appMain.rightPanelClick = true" id="DesignerNavbar">
        <app-designer-nav-toolbar id="step-one"></app-designer-nav-toolbar>
        <!-- <app-designer-status id="step-five" style="z-index: 102; position:absolute; top: 10px;right:-40px;">
        </app-designer-status> -->
        <app-nodes-errors id="step-five"
            style="z-index: 102; position:absolute; top: 10px;right:-40px;"></app-nodes-errors>
    </div>
    <div style="z-index: 102;position:absolute;left: 0px;top:57px; width: 200px"
        (click)="appMain.rightPanelClick = true">
        <!-- <p-scrollPanel [style]="{ width: '100%' }" styleClass="custombar1">
            <p-panel header="{{'Basic' | translate}}" [toggleable]="true" [collapsed]="false">
                <app-nodes-list [plugInInfos]="plugInInfos" id="step-two" #plugInList></app-nodes-list>
            </p-panel>
            <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
            </p-scrollTop>
        </p-scrollPanel> -->
        <app-nodes-list [plugInInfos]="plugInInfos" id="step-two" #plugInList></app-nodes-list>

    </div>

    <!-- <div class="p-col-3 p-md-2 p-lg-2" style="padding: 0;margin: 0 0px;" >
        <app-nodes-list [plugInInfos]="plugInInfos" id="step-two"></app-nodes-list>
    </div> -->
    <!-- <div class="p-col-12 p-md-12 p-lg-12" style="height: calc(100% - 50px);padding: 0;margin: 0 0px" >
        <app-workflow-graph [isEditor]=true #workflowGraph style="height: calc(100% - 50px);flex-basis: auto;overflow: hidden" id="step-three">
        </app-workflow-graph>
    </div> -->
    <!-- <div class="box" id="infoi" [hidden]="!displayConfig" @fade>
        <app-node-config #WorkflowConfig id="step-four" ></app-node-config>
    </div> -->

</div>

<app-workflow-graph [isEditor]=true #workflowGraph [selectedWorkflow]="selectedWorkflow" id="step-three">
</app-workflow-graph>

<!-- <div class="sticky-button">
    <button pButton [icon]="appMain.rightPanelActive ? 'pi pi-arrow-right':'pi pi-arrow-left'"
        [label]="appMain.rightPanelActive ? (translate.instant('Close Config')): (translate.instant('Close Config'))"
        (click)="toggleConfig()"></button>
</div> -->
<div class="sticky-button">
    <button pButton [icon]="appMain.rightPanelActive ? 'pi pi-arrow-right' : 'pi pi-arrow-left'"
        [label]="appMain.rightPanelActive ? (translate.instant('Close Config')) : (translate.instant('Open Config'))"
        (click)="toggleConfig()"></button>
</div>

<div class="layout-rightpanel" (click)="appMain.rightPanelClick = true">
    <div class="rightpanel-wrapper">
        <app-node-config #WorkflowConfig id="step-four"></app-node-config>
    </div>
</div>