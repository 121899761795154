import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MessageService } from 'primeng/api';
import { forkJoin, Observable, of } from 'rxjs';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';
import * as dss from 'src/app/models/datasource.model';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    GeneralObjectViewComponent
} from '../../objectManagement/general-object-view/general-object-view.component';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { TranslateService } from '@ngx-translate/core';
import { DataSourceSearch, GeneralSearchComponent } from '../../objectManagement/general-search/general-search.component';
import { ObjectSearchService } from 'src/app/services/object-search.service';
import { UserDetailsRow } from 'src/app/models/user.model';
import {
    GenericTopToolbarComponent
} from '../../objectManagement/generic-top-toolbar/generic-top-toolbar.component';
import {
    GenericLatestActivitiesComponent
} from '../../objectManagement/generic-latest-activities/generic-latest-activities.component';
import { ScheduleActionPlan } from 'src/app/models/schedule.model';
import {
    DataSourceWidget,
    GenericWidgetsComponent
} from '../../objectManagement/generic-widgets/generic-widgets.component';
import { TasksService } from 'src/app/services/tasks.service';

import {
    GenericFieldInfosComponent
} from '../../objectManagement/generic-field-infos/generic-field-infos.component';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { concatMap, map } from 'rxjs/operators';
import { DataTablePreview } from 'src/app/models/staging.model';
import { DataSourceFieldInfoProvider, DataSourceRichFieldInfoProvider } from '../../objectManagement/generic-field-infos/field-info-data-source';
import { DataSourceBottomToolbar, DataSourceRichBottomToolbar } from '../../objectManagement/generic-bottom-toolbar/provider-data-source';
import { DataSourceActivity } from '../../objectManagement/generic-latest-activities/provider-data-source';
import { ExperimentalApi } from 'src/app/services/experimental-api.service';
import { DataSourceRecord, DataSourceRecordView } from '../../objectManagement/general-object-view/provider-data-source';
import { RichDataSourceCake } from 'src/app/models/api/controllers/staging/RichDataSourceCake';
import { DataSourceViewRecord } from '../../objectManagement/general-object-view/provider-data-source-view';
import { DataSourceNewActivity } from '../../objectManagement/generic-latest-activities/provider-data-source-new';
import { DataSourceToolbar } from '../../objectManagement/generic-top-toolbar/provider-data-source';
import { GenericObjectTasksComponent } from '../../objectManagement/generic-object-tasks/generic-object-tasks.component';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    selector: 'app-datasources-view',
    templateUrl: './datasources-view.component.html',
    styleUrls: ['./datasources-view.component.scss'],
    providers: [MessageService],
    animations: [trigger("fade", [
        state("void", style({ opacity: 0 })),
        transition(":enter", [animate(500)]),
        transition(":leave", [animate(500)]),
    ]),
    trigger('slideInOut', [
        transition(':enter', [
          style({transform: 'translateY(-100%)'}),
          animate('200ms ease-in', style({transform: 'translateY(0%)'}))
        ]),
        transition(':leave', [
          animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
        ])
      ])
]
})
export class DatasourcesViewRelayoutComponent implements OnInit, OnDestroy {
    // General UI control variables
    private subs = new SubSink();
    loading: boolean = false;
    //displaySearchPanel: boolean = true;
    //displayWidgets: boolean = true;

    // View Specific Object Classes --> for each individual view different objects are required, define them here
    datasources: dss.DataSource[] = [];
    userDetails: UserDetailsRow[] = [];
    selectedDatasource?: dss.DataSource;
    fieldInfos: dss.DataSourceField[] = [];
    connectorInfos: Object[] = [];
    psa: dss.DataTypePreview;
    psaInfos: dss.PersistentStagingArea[] = [];
    psaFields: dss.PsaDsField[] = [];
    psaProtocol: dss.DataPackageProtocolEntry[] = [];

    // View Specific Type Classs --> add new for new features
    dataSourceRecordTypeClass: DataSourceRecord = new DataSourceRecord(this.bionApi, this.systemLogService, this.userService, this.utilService, this.datasourceService, this.objectSearchService, this.tasksService);
    dataSourceRecordNewTypeClass: DataSourceViewRecord = new DataSourceViewRecord([], [],this.datasourceService, this.systemLogService, this.objectSearchService, this.userService,this.utilService);

    dataSourceSearchTypeClass: DataSourceSearch = new DataSourceSearch(this.bionApi, this.systemLogService, this.userService, this.utilService, this.datasourceService, this.objectSearchService);
    dataSourceToolbarTypeClass: DataSourceToolbar = new DataSourceToolbar(this.datasourceService, this.userService);

    dataSourceActivityTypeClass: DataSourceActivity = new DataSourceActivity(this.bionApi, this.datasourceService, this.userService);
    dataSourceActivityNewTypeClass: DataSourceNewActivity = new DataSourceNewActivity(this.datasourceService, [], this.userDetails, this.utilService, this.router);

    dataSourceWidgetClass: DataSourceWidget = new DataSourceWidget(this.bionApi);
    //calendarTypeClass = new DataSourceSchedulePart(this.bionApi,this.datasourceService,this.schedulesService);

    dataSourceBottomToolbarClass = new DataSourceBottomToolbar(this.bionApi, this.datasourceService);
    dataSourceBottomToolbarNewClass = new DataSourceRichBottomToolbar(this.bionApi, this.datasourceService, []);

    dataSourceFieldInfosClass = new DataSourceFieldInfoProvider(this.bionApi, this.datasourceService);
    dataSourceFieldInfosNewClass = new DataSourceRichFieldInfoProvider([],[], this.datasourceService);

    @ViewChild("dsObjectToolbar") dsobjectToolbar!: GenericTopToolbarComponent<dss.DataSource>;
    //@ViewChild("dsObjectView") dsobjectView: GeneralObjectViewComponent<dss.DataSource, DataSourceRecordView>;

    @ViewChild("dsObjectView") dsobjectView!: GeneralObjectViewComponent<dss.DataSource, DataSourceRecordView, any, any>;

    @ViewChild("dsObjectSearch") dsObjectSearch!: GeneralSearchComponent<[dss.DataSource[], dss.DataSourceField[], dss.DataPackageProtocolEntry[], dss.DataSourceConnector<any>[], UserDetailsRow[], ScheduleActionPlan[], WorkflowRepositoryEntry[]], any>;
    @ViewChild("dsLatestActivities") dsObjectActivities!: GenericLatestActivitiesComponent<dss.DataSource, any>;
    @ViewChild("dsObjectWidgets") dsObjectWidgets!: GenericWidgetsComponent<dss.DataSource>;
    //@ViewChild('calendarObjectWidget') calendarObjectWidget: GenericCalendarWidgetComponent<dss.DataSource>;
    //@ViewChild('dsBottomToolbar') dsBottomToolbar!: GenericBottomToolbarComponent<dss.DataSource, dss.PersistentStagingArea, DataSourceToolbarResult, any>;
    @ViewChild('datasourceFieldInfos') datasourceFieldInfos!: GenericFieldInfosComponent<dss.DataSource, dss.DataSourceField>;

    @ViewChild('dsObjectTasks') dsObjectTasks!: GenericObjectTasksComponent;

    justifyOptions: any[] = [
        { icon: 'pi pi-align-left', justify: 'Left' },
        { icon: 'pi pi-align-right', justify: 'Right' },
        { icon: 'pi pi-align-center', justify: 'Center' },
        { icon: 'pi pi-align-justify', justify: 'Justify' }
    ];
    value: any;

    //showSidebar: boolean = false;

    constructor(
        private bionApi: ApiBackendService,
        private datasourceService: DatasourcesService,
        private tasksService: TasksService,
        private systemLogService: SystemMessageLogService,
        private userService: UserService,
        private objectSearchService: ObjectSearchService,
        private utilService: UtilFunctionsService,
        public translate: TranslateService,
        public experimentalApi: ExperimentalApi,
        public router: Router,
        public appMain: AppMainComponent,
    ) { }

    ngOnInit(): void {


        const use_new_approach = true;

        if (use_new_approach) {

            this.loading = true;
            this.subs.sink = this.initViewNew().subscribe(() => {
                console.log("Initialization completed!");
                console.log("Data Sources", this.datasources);
            },
                err => this.systemLogService.handleError(err),
                () => { this.loading = false; }
            );
            this.subs.sink = this.datasourceService.datasourceChangedEmitter.subscribe(() => {
                this.subs.sink = this.initViewNew(this.selectedDatasource).subscribe(() => {
                    this.loading = false;
                }, err => {
                    this.systemLogService.handleError(err);
                },
                    () => {
                        this.loading = false;
                    });
            },
                (err) => {
                    this.systemLogService.handleError(err);
                });



        } else {

            this.subs.sink = this.initView().subscribe(() => {
                this.loading = false;
            }, err => {
                this.systemLogService.handleError(err);
            }, () => {
                this.loading = false;
            });

            this.subs.sink = this.datasourceService.datasourceChangedEmitter.subscribe(() => {
                this.subs.sink = this.initView(this.selectedDatasource).subscribe(() => {
                    this.loading = false;
                }, err => {
                    this.systemLogService.handleError(err);
                },
                    () => {
                        this.loading = false;
                    });
            },
                (err) => {
                    this.systemLogService.handleError(err);
                });
        }

        this.subs.sink = this.datasourceService.selectedDatasourceEmitter.subscribe((ds) => {
            this.selectedDatasource = ds;

            if(this.selectedDatasource === undefined) {
                this.appMain.onRightPanelClick(false);

            } else {
                this.appMain.onRightPanelClick(true);

            }
        })
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    newDataSourceInfos: RichDataSourceCake.DataSourceView<any>[] = [];

    /**
     * Experimentelle Initialisierung mit Objekten von der Mutter
     * @param ds Ausgewählte Data Source
     * @returns
     */
    initViewNew(ds?: dss.DataSource): Observable<boolean> {
        console.log("Init View New");

        // Get UserDetailRows
        const userDetailsObs = this.userService.getUserDetailsRow();
        const dsViewObs = this.experimentalApi.getDataSourceView();
        const dTypesObs = this.bionApi.getDataTypes();

        const finalObs = forkJoin(dsViewObs,userDetailsObs,dTypesObs);

        const obs = finalObs.pipe(concatMap((t) => {
            const rich_data_sources = t[0];
            const user_details = t[1];
            const d_types = t[2];
            this.userDetails = user_details;

            this.newDataSourceInfos = rich_data_sources;
            console.log("this.newDataSourceInfos",this.newDataSourceInfos);
            const data_sources = rich_data_sources.map(r => r.DataSource);

            this.datasources = data_sources;

            this.dataSourceRecordNewTypeClass = new DataSourceViewRecord(rich_data_sources, user_details, this.datasourceService, this.systemLogService, this.objectSearchService,this.userService,this.utilService);
            const ds_obs = this.dsobjectView.setObjects(data_sources, this.dataSourceRecordNewTypeClass);

            this.dataSourceFieldInfosNewClass = new DataSourceRichFieldInfoProvider(rich_data_sources, d_types,this.datasourceService);
            const fields_obs = this.datasourceFieldInfos.setObject(this.dataSourceFieldInfosClass);

            this.dataSourceActivityNewTypeClass = new DataSourceNewActivity(this.datasourceService, rich_data_sources,this.userDetails, this.utilService, this.router);
            const ac_obs = this.dsObjectActivities.setObject(this.dataSourceActivityNewTypeClass);

            const top_obs = this.dsobjectToolbar.setToolbar();

            this.dataSourceBottomToolbarNewClass = new DataSourceRichBottomToolbar(this.bionApi, this.datasourceService, rich_data_sources);
            //const bot_obs = this.dsBottomToolbar.setObject(this.dataSourceBottomToolbarNewClass);
            const task_obs = this.dsObjectTasks.setObject();

            const searchObs = this.dsObjectSearch.setSearchModel(this.dataSourceSearchTypeClass, this.datasources);

            const all_obs = forkJoin(Array(fields_obs, ds_obs, ac_obs, top_obs, searchObs,task_obs));
            return all_obs.pipe(map(() => true));
        }))

        return obs

    }

    initView(ds?: dss.DataSource): Observable<[boolean, boolean, boolean, boolean, boolean, boolean, DataTablePreview?, dss.PersistentStagingArea?]> {
        this.loading = true;
        const dsObs = this.bionApi.getDataSources();
        const dsFieldsObs = this.bionApi.getDataSourceFields();
        const dsPsaFieldsObs = this.bionApi.getPsaDataSourceFields();
        const dsDataPackagesObs = this.bionApi.getDataPackageProtocols();

        return forkJoin([dsObs, dsFieldsObs, dsPsaFieldsObs, dsDataPackagesObs]).pipe((concatMap(dsResults => {
            this.datasources = dsResults[0];
            this.fieldInfos = dsResults[1];
            this.psaFields = dsResults[2];
            this.psaProtocol = dsResults[3];

            const topToolbarObs = this.dsobjectToolbar.setToolbar();
            const dsObs = this.dsobjectView.setObjects(this.datasources, this.dataSourceRecordTypeClass);
            const searchObs = this.dsObjectSearch.setSearchModel(this.dataSourceSearchTypeClass, this.datasources);
            const activityObs = this.dsObjectActivities.setObject(this.dataSourceActivityTypeClass);

            const fieldInfoObs = this.datasourceFieldInfos.setObject(this.dataSourceFieldInfosClass);

            let psa_obs: Observable<DataTablePreview> = of(undefined);
            let psa_ds_obs: Observable<dss.PersistentStagingArea> = of(undefined);

            if (ds) {
                psa_obs = this.bionApi.psaApiQuery(ds.id, 100, 0, false, true, false);
                psa_ds_obs = this.bionApi.getPsaOfDataSource(ds.id);
            }
            const initComponentsObs: Observable<[boolean, boolean, boolean, boolean, boolean, boolean, DataTablePreview, dss.PersistentStagingArea]> = forkJoin(topToolbarObs, dsObs, searchObs, activityObs, fieldInfoObs, psa_obs, psa_ds_obs);

            return initComponentsObs;

        })))

    }



    onSendRequest(sender: string) {

        let subject: any;
        let bodyText: any;

        if(sender === "Api") {
            subject = this.translate.instant("I want to request a API");
            bodyText = this.translate.instant("Hi Bion support, i want to request a API. Here is the description:");
        }
        if(sender === "Filetype") {
            subject = this.translate.instant("I want to request a file type");
            bodyText = this.translate.instant("Hi Bion support, i want to request a new file type. Here is the description:");
        }

		const mailText = "mailto:support@bion-analytics.com?subject="+subject+"&body="+bodyText; // add the links to body
		window.location.href = mailText;
	}
}
