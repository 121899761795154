<p>stripe works!</p>
<div id="connector_settings" class="card">

    <div class="card">

        <div>account_id</div>
        <div class="field">
            <label htmlFor="data.account_id">Account ID</label><input pInputText id="account_id"
                aria-describedby="data.account_id-help" [(ngModel)]="data.account_id" />
            <small id="data.account_id-help">Your Stripe account ID (starts with 'acct_', find yours <a
                    href="https://dashboard.stripe.com/settings/account">here</a>).</small>
        </div>
        <div>start_date</div>
        <div class="field">
            <label htmlFor="data.start_date">Replication start date</label><input pInputText id="start_date"
                aria-describedby="data.start_date-help" [(ngModel)]="data.start_date" />
            <small id="data.start_date-help">UTC date and time in the format 2017-01-25T00:00:00Z. Only data generated
                after this date will be replicated.</small>
        </div>
        <div>slice_range</div>
        <div class="field">
            <label htmlFor="data.slice_range">Data request time increment in days</label><input pInputText
                id="slice_range" aria-describedby="data.slice_range-help" [(ngModel)]="data.slice_range" />
            <small id="data.slice_range-help">The time increment used by the connector when requesting data from the
                Stripe API. The bigger the value is, the less requests will be made and faster the sync will be. On the
                other hand, the more seldom the state is persisted.</small>
        </div>
        <div>client_secret</div>
        <div class="field">
            <label htmlFor="data.client_secret">Secret Key</label><input pInputText id="client_secret"
                aria-describedby="data.client_secret-help" [(ngModel)]="data.client_secret" />
            <small id="data.client_secret-help">Stripe API key (usually starts with 'sk_live_'; find yours <a
                    href="https://dashboard.stripe.com/apikeys">here</a>).</small>
        </div>
        <div>lookback_window_days</div>
        <div class="field">
            <label htmlFor="data.lookback_window_days">Lookback Window in days</label><input pInputText
                id="lookback_window_days" aria-describedby="data.lookback_window_days-help"
                [(ngModel)]="data.lookback_window_days" />
            <small id="data.lookback_window_days-help">When set, the connector will always re-export data from the past
                N days, where N is the value set here. This is useful if your data is frequently updated after creation.
                More info <a href="https://docs.airbyte.com/integrations/sources/stripe#requirements">here</a></small>
        </div>

    </div>

</div>