import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Message, MessageService } from "primeng/api";
import { GuiErrorException, GuiErrorInfo } from "src/app/models/gui.error.model";
import { PlayErrorResponse } from "src/app/models/play.error.model";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-system-message-log",
	templateUrl: "./system-message-log.component.html",
	styleUrls: ["./system-message-log.component.scss"],
	providers: [MessageService],
})
export class SystemMessageLogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	GuiErrorInfo?: GuiErrorInfo = undefined;
	messages: Message[] = [];
	constructor(private errorService: SystemMessageLogService) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	// playErrorInfo(playError:PlayErrorResponse) : GuiErrorInfo {
	// 	const info = new GuiErrorInfo();
	// 	return info;
	// }


	/**
	 * Handles an error based on its type.
	 * @param err Error
	 */
	dispatchError(err: Error) {
		if (err instanceof HttpErrorResponse) {
			this.handleHttpError(<HttpErrorResponse>err);
		}
		else {
			this.handleGeneralError(err);
		}
	}

	/**
	 * Handle common TypeScript errors.
	 * @param err Error
	 */
	handleGeneralError(err: Error) {
		console.log("Common Error", err);
		this.showGeneralError(err);
	}

	/**
	 * Handle a Gui Error.
	 * @param err Gui Error
	 */
	handleGuiError(err: GuiErrorException) {
		console.log("Gui Error: " + err);
		this.showGuiError(err);
	}

	/**
	 * Handle error from HTTP-Requests.
	 * @param err Error
	 */
	handleHttpError(err: HttpErrorResponse) {
		console.log("Http Error", err);

		const e = err.error;

		if (PlayErrorResponse.isShape(e)) {
			this.showPlayError(e, err);
		} else {
			this.showHttpError(err);
		}
	}

	ngOnInit(): void {
		this.subs.sink = this.errorService.httpErrorEventEmitter.subscribe(
			(err: HttpErrorResponse) => {

				this.handleHttpError(err);

				// console.log("SYSTEM MESSAGE LOG");

				// if (err === undefined)
				// 	console.log("WARNING: err is undefined");

				// this.dispatchError(err);

				// let httpErrorCode = err.status;
				// let httpErrorText = err.statusText;
				// let httpErrorObject = err.error;

				// // let httpObject:string = undefined;
				// // let httpStackTrace:string[] = undefined;

				// let commonGuiError: GuiErrorInfo = undefined;
				// if (err instanceof Error) {
				// 	const e: Error = err;
				// 	const st = e.stack.split("\n");
				// 	commonGuiError = new GuiErrorInfo(-1, e.message, e.name, st);
				// }
				// else if (err.status == 401) {
				// 	// httpObject = err.error;
				// 	// httpStackTrace = [];
				// 	// httpErrorText = err.statusText;
				// 	commonGuiError = new GuiErrorInfo(err.status, err.statusText, err.error, []);
				// }
				// else if (httpErrorObject) {
				// 	if (httpErrorObject.error) {
				// 		const playError = <PlayErrorResponse>httpErrorObject;
				// 		commonGuiError = new GuiErrorInfo(err.status,
				// 			playError.error.exception.description,
				// 			playError.error.exception.title,
				// 			playError.error.exception.stacktrace);
				// 	}
				// 	else {
				// 		// httpObject = err.message;
				// 		// httpStackTrace = [];
				// 		commonGuiError = new GuiErrorInfo(err.status,
				// 			err.name,
				// 			err.message, []);
				// 	}
				// } else {
				// 	// Handle any other errors that is not PlayErrorResponse
				// 	//httpObject = JSON.stringify(httpErrorObject);
				// 	const err_text = JSON.stringify(err);
				// 	commonGuiError = new GuiErrorInfo(-1,
				// 		err_text,
				// 		err_text, []);
				// }

				// // Create GuIErrorObject
				// // const newGuiError = new GuiErrorInfo(
				// // 	httpErrorCode,
				// // 	httpErrorText,
				// // 	httpObject,
				// // 	httpStackTrace
				// // );

				// const newGuiError = commonGuiError;
				// if (!newGuiError) {
				// 	console.log("WARNING - THE GUI ERROR IS UNDEFINED FOR INPUT: ", err);
				// 	return;
				// }

				// let currentDate = Date.now();
				// let currentDateString = new Date(currentDate);

				// this.messages = [
				// 	{
				// 		severity: "warn",
				// 		summary: newGuiError.httpMessage,
				// 		detail: newGuiError.httpObject + " - " + currentDateString,
				// 	},
				// ];
				// this.GuiErrorInfo = newGuiError;
			}
		);

		this.subs.sink = this.errorService.guiErrorEventEmitter.subscribe(
			(err: GuiErrorException) => this.showGuiError(err)
		);

		this.subs.sink = this.errorService.generalErrorEventEmitter.subscribe(
			(err: Error) => this.dispatchError(err)
		)

		this.subs.sink = this.errorService.clearMessageEventEmitter.subscribe(
			() => {
				this.messages = [];
				this.GuiErrorInfo = undefined;
			}
		)


	}

	/**
	 * Shows the common typescript error type.
	 * @param err Error
	 */
	showGeneralError(err: Error): void {
		this.setMessages(err.name, err.message);
	}

	/**
	 * Shows a gui error
	 * @param err Gui Error Code
	 */
	showGuiError(err: GuiErrorException): void {
		this.setMessages(err.title, err.message);
		const errorInfo = new GuiErrorInfo(err.code, err.title, err.message);
		this.GuiErrorInfo = errorInfo;
	}

	/**
	 * Sets the log to one message with the given arguments.
	 * @param summary Short Text
	 * @param detail Long Text
	 * @param severity level, usually 'warn' for errors
	 * @param currentDate Error time stamp
	 */
	setMessages(summary:string,detail:string, severity:string="warn", currentDate:number=Date.now()) {
		const currentDateString = new Date(currentDate);
		this.messages = [
			{
				severity: severity,
				summary: summary,
				detail: detail + " - " + currentDateString,
			},
		];
	}

	/**
	 * Displays a Play Server Error, usually with HTTP Code 500
	 * @param err Play Exception
	 * @param httpError Encapsulating HTTP Exception
	 */
	showPlayError(err: PlayErrorResponse, httpError: HttpErrorResponse): void {

		const errorInfo = new GuiErrorInfo(httpError.status,
			err.error.exception.description,
			err.error.exception.title,
			err.error.exception.stacktrace);

		this.GuiErrorInfo = errorInfo;
		this.setMessages(err.error.exception.title, err.error.exception.description);
	}

	/**
	 * Displays any kind of HTTP Errors.
	 * @param err HTTP Exception
	 */
	showHttpError(err:HttpErrorResponse) : void {
		const errorInfo = new GuiErrorInfo(err.status, 
			err.message, err.name, []);

			this.GuiErrorInfo = errorInfo;
			this.setMessages(err.name, err.message);
	}

	clearMessages() {
		this.GuiErrorInfo = undefined;
	}
}
