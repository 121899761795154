import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { forkJoin, from } from 'rxjs';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { IntegratedSourceController } from 'src/app/models/api/controllers/IntegratedSourceController';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { MColumn } from 'src/app/models/api/slick/jdbc/meta/MColumn';
import { ConnectorInfo } from 'src/app/models/connector.model';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { GenericLatestActivitiesComponent } from 'src/app/views/objectManagement/generic-latest-activities/generic-latest-activities.component';
import { DataSourceActivity } from 'src/app/views/objectManagement/generic-latest-activities/provider-data-source';
import { DataSourceNewActivity } from 'src/app/views/objectManagement/generic-latest-activities/provider-data-source-new';
import * as dss from 'src/app/models/datasource.model';
import { ActivityEntry } from 'src/app/views/objectManagement/generic-latest-activities/latest-activity-provider';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-view-source-int',
  templateUrl: './view-source-int.component.html',
  styleUrls: ['./view-source-int.component.scss'],
  providers: [MessageService]
})
export class ViewSourceIntComponent implements OnInit {
  items: MenuItem[] = [];
  home: MenuItem = { icon: 'pi pi-table', label: "Datenquellen", routerLink: '/SourceIntegrationCreateDatasource' };
@ViewChild('objectPipeline') objectPipeline;
    subs = new SubSink;



    editModeOn: boolean = false;
  constructor(private service_api: IntegratedSourceService, private translate: TranslateService,private router: Router, private messageService: MessageService, private datasourceService:DatasourcesService, bionApi: ApiBackendService, public userService : UserService, public utilService: UtilFunctionsService) { }

  isLoading: boolean = false;
  progressMode: string = "indeterminate";

  source_key?: IntegratedSourceModel.DataSourceKey<number> = undefined;
  data_source?: IntegratedSourceModel.DataSource<number> = undefined;
  data_source_copy?: IntegratedSourceModel.DataSource<number> = undefined;

  connectorInfo?: IntegratedSourceController.ConnectorInfo;
  activities: dss.DataPackageProtocolEntry[] = [];
  new_data_source_name: string = "";
  userDetails: any = this.userService.getUserDetailsRow();

  dataSourceActivityNewTypeClass: DataSourceNewActivity = new DataSourceNewActivity(this.datasourceService, [], this.userDetails, this.utilService, this.router);
  @ViewChild("dsLatestActivities") dsObjectActivities!: GenericLatestActivitiesComponent<dss.DataSource, any>;

  ngOnDestroy(): void{
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.home = { icon: 'pi pi-table', label: 'Datenquellen', routerLink: '/SourceIntegrationCreateDatasource' };



    this.source_key = this.getUrlContext();
    const source_key = this.source_key;

    console.warn("We filter. The backend will do this soon")
    const conns_ob = this.service_api.getConnectors();
    const ds_ob = this.service_api.getDataSource();
    //const userDetailsObs = this.userService.getUserDetailsRow();
    const fina_ob = forkJoin(conns_ob,ds_ob);


    this.subs.sink = fina_ob.subscribe(sources => {
      this.data_source = sources[1].find(d => d.Id.Id == this.source_key?.Id && d.Id.Origin == this.source_key?.Origin);
      this.data_source_copy = {...this.data_source};
      this.connectorInfo = sources[0].find( c => c.Key.Id === this.data_source.Connector.Id);
      //this.userDetails = sources[2];
      this.new_data_source_name = this.data_source?.Name;
      this.items = [{ label: this.new_data_source_name, routerLink: '/SourceIntegrationCreateDatasource' }];

      console.warn("Move onInitStreams to event when streams tab is opened first time");
      this.objectPipeline.createApiUrl(this.data_source);
      const dataSourceActivityNewTypeClass = new DataSourceNewActivity(this.datasourceService, [], this.userDetails, this.utilService, this.router);

      this.dsObjectActivities.setObject(dataSourceActivityNewTypeClass);

      this.onInitStreams(this.data_source.Id);

    })
  }

  /**
   * Der Datasource Schlüssel aus der Route.
   * @returns Data Source Key
   */
  getUrlContext(): IntegratedSourceModel.DataSourceKey<number> {
    const arr = this.router.url.split('/');
    const id = parseInt(arr.last());
    const origin = arr.getRight(1);
    console.log("Data Source ID: " + id);
    console.log("Data Source Origin: " + origin);
    return new IntegratedSourceModel.DataSourceKey(id, origin);
  }

  /**
   * Prüft, ob der Data Source Name geändert wurde.
   * Nützlich für Update-Option.
   * @returns
   */
  dsNameIsDirty(): boolean {
    return this.new_data_source_name == this.data_source?.Name;
  }


  // Stream and Query setion = START

  // -- modular, portable to other components
  psa_infos: IntegratedSourceModel.PsaInfo[] = [];
  catalog?: IntegratedSourceModel.GenCatalog = undefined;
  streams_initialized: boolean = false;
  stream_psa_list: Map<string, IntegratedSourceModel.PsaInfo[]> = new Map();  // look up map for speed

  selected_stream?: IntegratedSourceModel.GenCatalog.StreamInfo = undefined;
  selected_stream_psa_infos: IntegratedSourceModel.PsaInfo[] = [];
  selected_psa?: IntegratedSourceModel.PsaInfo = undefined;

  psa_table_data : any[] = [];
  psa_table_cols : MColumn[] = [];

  onInitStreams(key: IntegratedSourceModel.DataSourceKey<number>) {
    console.log("On Init Streams");

    const psa_arg = new IntegratedSourceModel.GetPsaInfosArg(key)
    const psa_ob = this.service_api.psaInfos(psa_arg);

    const stream_arg = new IntegratedSourceModel.GetStreamsArg(key);
    const stream_ob = this.service_api.getStreams(stream_arg);

    //this.selected_psa?.Table

    const all_ob = stream_ob.pipe(mergeMap(catalog => {
      return psa_ob.pipe(map(psa_list => {
        const result: [IntegratedSourceModel.GetStreamsResult, IntegratedSourceModel.PsaInfo[]] = [catalog, psa_list];
        return result;
      }))
    }));

    this.subs.sink = all_ob.subscribe(pair => {

      this.psa_infos = pair[1];
      this.catalog = pair[0].Catalog;


      for (let info of this.psa_infos) {
        if (this.stream_psa_list.has(info.Stream)) {
          this.stream_psa_list.get(info.Stream).push(info);
        } else {
          this.stream_psa_list.set(info.Stream, [info]);
        }
      }

      this.streams_initialized = true;

    })
  }

  onStreamSelected(event: any) {
    console.log("Event", event);

    if (event.data !== undefined) {

      const stream_info: IntegratedSourceModel.GenCatalog.StreamInfo = event.data;
      console.log("Stream Info", stream_info);

      const sel_psa_infos = this.stream_psa_list.get(stream_info.stream.name);
      if (sel_psa_infos !== undefined)
        this.selected_stream_psa_infos = sel_psa_infos;
      else
        this.selected_stream_psa_infos = [];
    }
  }

  onPsaSelected(event: any) {
    console.log(event);

    if (event.data !== undefined) {
        this.setLoading(true);
      const psa_info: IntegratedSourceModel.PsaInfo = event.data;
      console.log(psa_info);

      console.warn("Fire psa query instantly... maybe on button or with flag");
      this.onQueryPsa(psa_info);
    } else {
        this.selected_psa= undefined;
    }
  }

  setLoading(active:boolean) {
    this.isLoading = active;
  }

  handleError(err:Error) {
    console.error("Error Loading Data from PSA", err);
  }

  onQueryPsa(psa: IntegratedSourceModel.PsaInfo) {
    // selected psa

    // fire query
    console.warn("Add checks: data source set, stream set");
    const arg = new IntegratedSourceModel.QueryStreamArg(this.data_source.Id, this.selected_stream.stream.name,psa);
    const obs = this.service_api.queryStream(arg).pipe(concatMap(blob => {

      const p = new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.onload = () => {
          const buffer = reader.result as ArrayBuffer;
          const jsonString = new TextDecoder().decode(buffer);
          console.log(jsonString);
          const json_strings = jsonString.split("\n");
          console.log(json_strings);

          const json_strings_safe = json_strings.slice(0, json_strings.length - 1);
          const json_data_arr = json_strings_safe.map(s => JSON.parse(s));

          console.log(json_data_arr);

          resolve(json_data_arr);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);

      });

      const o = from(p);

      return o;

    }));


    this.subs.sink = obs.subscribe((json_data: any) => {

      console.log(json_data);
      console.log(typeof (json_data));

      console.log("PSA Columns", psa.Columns);

      const data_arr = <Array<any>>(json_data);
      console.log("Received PSA data:", data_arr);

      this.psa_table_cols = psa.Columns;
      this.psa_table_data = data_arr;

    },
      err => this.handleError(err),
      () => this.setLoading(false)
    );



    // display result
  }

  // Stream and Query setion = END

  editStreamsClicked(event:any) {
    const key = this.source_key;

    if(key !== undefined) {
      this.router.navigate(['/', 'SourceIntegrationGetStreams', key.Origin, key.Id]);
    }

  }

  switchEditMode:boolean = false;
  toggleStreamEdit() {
    if(this.switchEditMode) {
        this.switchEditMode = false
    } else {
        this.switchEditMode = true;
    }
  }

  onInitEndpoints() {
    this.objectPipeline.createApiUrl(this);
  }
  onChangeEditMode() {
    console.log("Save Source");

    if(this.editModeOn) {
        console.log("Save Source");
        const ds = this.data_source_copy;
        const arg = new IntegratedSourceModel.UpdateDataSourceArg(this.source_key,ds.Name)
        this.subs.sink = this.service_api.updateDataSource(arg).subscribe((res) => {
            this.data_source = {...ds};
            this.editModeOn = false;
            this.messageService.add({
                severity: "success",
                summary: this.translate.instant("Message.UpdateDataSourceSuccess.Title"),
                detail: this.translate.instant("Message.UpdateDataSourceSuccess.Text1") + ds.Id.Id +
                this.translate.instant("Message.UpdateDataSourceSuccess.Text2"),
            });
        },(err) => {
            this.handleError(err)
        },() => {this.editModeOn = false})

    } else {
        console.log("Activate EditMode");
        this.editModeOn = true;
    }

  }


}
