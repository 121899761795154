import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable } from "rxjs";
import { UserRow } from "../models/api/domain/NewDataSourceModelBase";
import { Accessible } from "../models/api/models/authorization/Accessible";
import { PermissionLike } from "../models/api/models/authorization/PermissionLike";
import { RightHolder } from "../models/api/models/authorization/RightHolder";
import { ViewPermission } from "../models/api/models/authorization/ViewPermission";
import { UserInfo } from "../models/api/models/session/UserInfo";
import { GroupRoleView, UserRoleView } from "../models/auth-service-decorator.model";
import { Permission } from "../models/authorization.model";
// import {
// 	Permission,
// 	RightHolder,
// 	Accessible,
// 	PermissionLike,
// 	GroupRole,
// 	PermissionBase,
// 	ViewPermission,
// } from "../models/authorization.model";
import { GeneralTypeInfo } from "../models/types.model";
import { ChangeUserPassword, UserDetailsRow } from "../models/user.model";
// import { ChangeUserPassword, User, UserDetailsRow, UserInfo } from "../models/user.model";
// import { WorkflowPermission } from "../models/workflow.model";
import { PermissionManager, PermissionView } from "../views/users/components/user-permissions-list/user-permissions-list.component";
import { ApiBackendService } from "./api-backend.service";

@Injectable({
	providedIn: "root",
})
export class UserService {
	@Output() displayNewUser = new EventEmitter<boolean>();
	@Output() displayEditUser = new EventEmitter<boolean>();
	@Output() displayDeleteUser = new EventEmitter<boolean>();
	@Output() displayChangeUserPassword = new EventEmitter<boolean>();
	@Output() displayChangeMyPassword = new EventEmitter<boolean>();

	@Output() displayInviteUser = new EventEmitter<boolean>();
	@Output() displayNewRole = new EventEmitter<boolean>();
	@Output() displayDeleteUserRole = new EventEmitter<boolean>();
	@Output() displayEditRole = new EventEmitter<boolean>();
	@Output() selectedUserRoleEmitter = new EventEmitter<UserRoleView>();
	@Output() selectedMenuUserRoleEmitter = new EventEmitter<UserRoleView>();

	@Output() selectedGroupRoleEmitter = new EventEmitter<GroupRoleView>();
	@Output() selectedMenuGroupRoleEmitter = new EventEmitter<GroupRoleView>();
	@Output() displayEditGroupRole = new EventEmitter<boolean>();

	@Output() selectedViewRoleEmitter = new EventEmitter<ViewPermission>();
	@Output() selectedMenuViewRoleEmitter = new EventEmitter<ViewPermission>();

	@Output() newUserEmitter = new EventEmitter<UserInfo>();
	@Output() updatedUserEmitter = new EventEmitter<UserInfo>();
	@Output() updatedUserPasswordEmitter = new EventEmitter<UserInfo>();

	@Output() deletedUserEmitter = new EventEmitter<UserInfo>();
	@Output() selectedUserEmitter = new EventEmitter<UserInfo>();
	@Output() selectedMenuUserEmitter = new EventEmitter<UserInfo>();

	@Output() displayAddPermission = new EventEmitter<boolean>();
	@Output() displayEditPermission = new EventEmitter<boolean>();
	@Output() displayDeletePermission = new EventEmitter<boolean>();

	// Generic Class Emitter
	@Output() selectedPermissionEmitter = new EventEmitter<PermissionView<any,any,any>>();
	@Output() selectedMenuPermissionEmitter = new EventEmitter<[PermissionView<any,any,any>, PermissionManager<any,any,any,any>]>();

	// Specific Class Emitter
	// @Output() selectedWorkflowPermissionEmitter =
	// 	new EventEmitter<WorkflowPermission>();
	// @Output() selectedMenuPermissionEmitter =
	// 	new EventEmitter<WorkflowPermission>();

	@Output() newPermissionEmitter = new EventEmitter<Permission>();
	@Output() updatedPermissionEmitter = new EventEmitter<Permission>();
	@Output() deletedPermissionEmitter = new EventEmitter<Permission>();

	constructor(private api: ApiBackendService) {}

	// API CALLS

	// User Session
	getUserInfo(): Observable<UserInfo> {
		return this.api.getUserInfo();
	}
	getUserSession(token: string): Observable<boolean> {
		return this.api.checkUserSession(token);
	}

    	// USER DETAILS
	public getUserDetailsRow(id?: number): Observable<UserDetailsRow[]> {
        return this.api.getUserDetailsRow(id);
	}
	public createUserDetailsRow(item: UserDetailsRow): Observable<UserDetailsRow> {
        return this.api.createUserDetailsRow(item);
	}
	public updateUserDetailsRow(item: UserDetailsRow): Observable<number> {
        return this.api.updateUserDetailsRow(item);
	}
	public deleteUserDetailsRow(id: number): Observable<number> {
        return this.api.deleteUserDetailsRow(id);
	}

	// User Functions
	public getUser(id?: number): Observable<UserInfo[]> {
		return this.api.getUser(id);
	}
	public createUser(item: UserRow): Observable<UserInfo> {
		return this.api.createUser(item);
	}
	public updateUser(item: UserInfo): Observable<number> {
		return this.api.updateUser(item);
	}
	public changeUserPassword(item: ChangeUserPassword): Observable<number> {
		return this.api.changePassword(item);
	}
	public deleteUser(id: number): Observable<number> {
		return this.api.deleteUser(id);
	}
	public inviteUser(email: string) {
		return this.api.inviteUser(email);
	}
	public getUserCommon(id?: number): Observable<UserInfo[]> {
		return this.api.getUserCommon(id);
	}

	// Personen, Rollen
	getRightHolders(name?: string): Observable<RightHolder[]> {
		return this.api.getRightHolders(name);
	}
	public getRightHolderTypes(): Observable<GeneralTypeInfo<string>[]> {
		return this.api.getRightHolderTypes();
	}

	// View (DataSourceView) und Objekte (ds,wf,schedule)
	getAccessibles(name?: string): Observable<Accessible[]> {
		return this.api.getAccessibles(name);
	}

	public getPermissions(name?: string): Observable<PermissionLike[]> {
		return this.api.getPermissions(name);
	}

	public getPermissionLevels(): Observable<string[]> {
		return this.api.getPermissionLevels();
	}

	// DIALOG HANDLING

	public displayNewUserDialog(evt: boolean) {
		this.displayNewUser.emit(evt);
	}
	public displayUpdateUserDialog(evt: boolean) {
		this.displayEditUser.emit(evt);
	}
	public displayDeleteUserDialog(evt: boolean) {
		this.displayDeleteUser.emit(evt);
	}

	//EMITTER HANDLING

	emitSelectedUser(user: UserInfo) {
		this.selectedUserEmitter.emit(user);
	}

	emitNewUser(user: UserInfo) {
		this.newUserEmitter.emit(user);
	}
	emitUpdatedUser(user: UserInfo) {
		this.updatedUserEmitter.emit(user);
	}
	emitDeletedUser(user: UserInfo) {
		this.deletedUserEmitter.emit(user);
	}
}
