import { Injectable } from "@angular/core";
import { IntegratedSourceController } from "../models/api/controllers/IntegratedSourceController";
import { Observable } from "rxjs";
import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { ApiServiceUtils } from "./api-util";
import { IntegratedSourceModel } from "../models/api/models/IntegratedSourceModel";
import { TaskJobModel } from "../models/api/models/task/core/TaskJobModel";

/**
 * Erstellt Data Sources für Bion und Airbyte über eine überwiegend eiheitliche Schnittstelle.
 * 'Überwiegend' deswegen, weil sich beide Modeelle nicht exakt integrieren lassen. Dies wird aber
 * subkzessive nachgezogen.
 * 
 * Im Folgenden ist mit Origin-API die jeweilige API von Bion bzw. Airbyte genannt.
 * 
 * Wichtigste Kernkonzepte
 * - Bion und Airbyte werden über einen einheitlichen Schlüssel identifiziert. Dieser besteht aus
 *   DB-Primärschlüssel und Origin (Bion,Airbyte)
 * - Jeder Call liefert auch das Original API Ergebnis der Origin-API.
 * - Die Calls nutzen nur GET- und POST-Requests, weil die Paramter komplex sein können.
 * - Die Bion-Metadaten und der Airbyte-Catalog werden über einen generischen Katalog abstrahiert. Dieser ist ein Super-Set und
 *   dienst der Auswahl von Streams und Schreibmodi.
 */
@Injectable({
    providedIn: "root",
})
export class IntegratedSourceService {

    constructor(private http: HttpClient) {

    }

    /**
     * 
     * @returns Alle Bion und Airbyte Konnektoren. 
     */
    getConnectors(): Observable<IntegratedSourceController.ConnectorInfo[]> {
        const options = ApiServiceUtils.makeOptions();
        return this.http.get<IntegratedSourceController.ConnectorInfo[]>("/exp/api/Staging/DataSources/Integrate/api/getConnectors", options);
    }

    /**
     * Konnektor Details und Konfigurationsinformationen.
     * @param arg Konnektor Schlüssel
     * @returns 
     */
    getConnectorSpecs(arg: IntegratedSourceModel.ConnectorKey<string>): Observable<IntegratedSourceModel.ConnectorSpec<string, any>> {
        return this.http.post<IntegratedSourceModel.ConnectorSpec<string, any>>("/exp/api/Staging/DataSources/Integrate/api/getConnectorSpecs", arg);
    }

    /**
     * Erstellt eine neue Datasource
     * @param arg Konnektorschlüssel, Konfiguration und Name.
     * @returns Datasource Id und Original API Ergebins
     */
    createDataSource<C, R>(arg: IntegratedSourceModel.CreateDataSourceArg<C>): Observable<IntegratedSourceModel.CreateDataSourceResult<R>> {
        return this.http.post<IntegratedSourceModel.CreateDataSourceResult<R>>("/exp/api/Staging/DataSources/Integrate/api/createDataSource", arg);
    }

    /**
     * Holt den Metadaten-Katalog für die angegebene Datasource.
     * @param arg Datasource Typ und Schlüssel.
     * @returns Verfügbare Metadaten. Diese sind je nach Typ unterschiedlich
     */
    getCatalog<R>(arg: IntegratedSourceModel.GetCatalogArg): Observable<IntegratedSourceModel.GetCatalogResult<R>> {
        return this.http.post<IntegratedSourceModel.GetCatalogResult<R>>("/exp/api/Staging/DataSources/Integrate/api/getCatalog", arg);
    }

    /**
     * Verbesserte Variante von getCatalog mit Datei-Upload
     * @param sourceKey Source Schlüssel
     * @param file Datei
     * @param progressMode Falls true die Event-basierte Variante, sonst das Standard-Ergebnis. 
     * @returns 
     */
    getCatalogFile<R>(sourceKey: IntegratedSourceModel.DataSourceKey<number>, file: File, progressMode: boolean): Observable<HttpEvent<Object>> | Observable<IntegratedSourceModel.GetCatalogResult<R>> {
        console.log("Upload File");

        const formData = new FormData();
        formData.append('file', file);
        formData.append('sourceKey', JSON.stringify(sourceKey));

        // return this.http.post('/exp/api/Staging/DataSources/Integrate/api/syncFile', formData, { reportProgress: true, observe: 'events' });

        if (progressMode)
            return this.http.post('/exp/api/Staging/DataSources/Integrate/api/getCatalogFile', formData, { reportProgress: true, observe: 'events' });
        else
            return this.http.post<IntegratedSourceModel.GetCatalogResult<R>>('/exp/api/Staging/DataSources/Integrate/api/getCatalogFile', formData);
    }

    /**
     * Legt die zu extrahierenden Streams/Tabellen/Entitäten fest.
     * @param arg Datasource Schlüssel und der Katalog mit den ausgewählten Streams.
     * @returns Das original API-Ergebnis. Wenn kein Fehler erzeugt wird war die Auswahl erfolgreich.
     */
    defineStreams<S, R>(arg: IntegratedSourceModel.DefineStreamsArg<S>): Observable<IntegratedSourceModel.DefineStreamsResult<R>> {
        return this.http.post<IntegratedSourceModel.DefineStreamsResult<R>>("/exp/api/Staging/DataSources/Integrate/api/defineStreams", arg);
    }

    sync(arg: IntegratedSourceModel.SyncArg): Observable<IntegratedSourceModel.SyncResult> {
        return this.http.post<IntegratedSourceModel.SyncResult>("/exp/api/Staging/DataSources/Integrate/api/sync", arg);
    }

    /**
     * Synchronisiert einen Bion File-Konnektor.
     * Diese optimierte Variante der sync-Funktion nutzt Form Data um die Datei hochuladen und liefert Status-Ereignisse für den Upload.
     * @param file Datei
     * @param source_key Data Source Schlüssel 
     */
    syncFile(file: File, source_key: IntegratedSourceModel.DataSourceKey<number>) {

        const formData = new FormData();
        formData.append('file', file);
        formData.append('sourceKey', JSON.stringify(source_key));

        return this.http.post('/exp/api/Staging/DataSources/Integrate/api/syncFile', formData, { reportProgress: true, observe: 'events' })
    }

    // Rest der Data Source CRUD-Operationen
    // TODO: Filter Option
    getDataSource(): Observable<IntegratedSourceModel.DataSource<number>[]> {
        const options = ApiServiceUtils.makeOptions();
        return this.http.get<IntegratedSourceModel.DataSource<number>[]>("/exp/api/Staging/DataSources/Integrate/DataSource", options);
    }

    getWriteModeInfos(): Observable<IntegratedSourceModel.GenCatalog.WriteModeInfo[]> {
        const options = ApiServiceUtils.makeOptions();
        return this.http.get<IntegratedSourceModel.GenCatalog.WriteModeInfo[]>("/exp/api/Staging/DataSources/Integrate/WriteModeInfos", options);
    }

    // = Data Source CRUD

    // Create : see above

    /**
     * Aktualisiert die Data Source. ACHTUNG: aktuell werden noch nicht alle Attribute unterstützt. Bei Bion ist das z.B. der Write-Mode,
     * denn dieser soll in Zukunft über den neuen Bion-Katalog aktualisiert werden.
     * @param arg 
     * @returns 
     */
    updateDataSource(arg: IntegratedSourceModel.UpdateDataSourceArg) {
        return this.http.post<number>("/exp/api/Staging/DataSources/Integrate/DataSource/update", arg);
    }

    /**
     * Löscht die Data Source und ihre Verbindungen. Es werden bei Airbyte keine Daten gelöscht. Bei Bion schon, das muss noch vereinheitlicht werden.
     * @param arg 
     * @returns 
     */
    deleteDataSource(arg: IntegratedSourceModel.DeleteDataSourceArg): Observable<number> {
        return this.http.post<number>("/exp/api/Staging/DataSources/Integrate/DataSource/delete", arg);
    }

    // == Streams CRUD

    getStreams(arg: IntegratedSourceModel.GetStreamsArg): Observable<IntegratedSourceModel.GetStreamsResult> {
        return this.http.post<IntegratedSourceModel.GetStreamsResult>("/exp/api/Staging/DataSources/Integrate/DataSource/Streams/get", arg);
    }

    updateStreams(arg: IntegratedSourceModel.UpdateStreamsArg) {
        return this.http.post<IntegratedSourceModel.UpdateStreamsResult>("/exp/api/Staging/DataSources/Integrate/DataSource/Streams/update", arg);
    }

    extractFromDataSource() {
        // Extrahiert alle ausgewählten Streams. 

        // sync (Airbyte)
        // extract (Bion)
    }

    psaInfos(arg: IntegratedSourceModel.GetPsaInfosArg) {
        return this.http.post<IntegratedSourceModel.PsaInfo[]>("/exp/api/Staging/DataSources/Integrate/api/psaInfos", arg);
    }

    /**
     * Query the Streams PSA as chunked response
     * @param arg 
     * @returns 
     */
    queryStream(arg: IntegratedSourceModel.QueryStreamArg) {

        const url = "/exp/api/staging/DataSources/Integrate/api/queryStream";

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        const options = { headers, responseType: 'blob' as 'json' }; // Wichtig: responseType auf 'blob' setzen

        return this.http.post<Blob>(url, arg, options);
    }
}