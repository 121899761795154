<p-messages [showTransitionOptions]="'2ms'" closable="true" [hideTransitionOptions]="'2ms'" [(value)]="messages" escape="true"></p-messages>


<!-- <p-overlayPanel #op1 [showCloseIcon]="false" [style]="{width: '750px'}">
    <p *ngIf="GuiErrorInfo">{{GuiErrorInfo.stackTrace}}</p>
</p-overlayPanel>
<div style="width:100%">
    <p-toolbar>
        <div >
            <div *ngIf="!GuiErrorInfo">
                <p >No errors</p>
            </div>
            <div *ngIf="GuiErrorInfo">
                <div class="p-col-12">
                    <p> <b>Error Code:</b> {{GuiErrorInfo.httpCode}} | {{GuiErrorInfo.httpMessage}} | {{GuiErrorInfo.httpObject}}</p>
                </div>

            </div>
        </div>
        <div *ngIf="GuiErrorInfo" class="p-toolbar-group-right">
            <p-button icon="pi pi-info-circle" styleClass="p-button p-button-text p-button-rounded" (click)="op1.toggle($event)"></p-button>
            <p-button icon="pi pi-trash" styleClass="p-button p-button-text p-button-rounded" (click)="clearMessages()"></p-button>
        </div>
    </p-toolbar>

</div> -->

