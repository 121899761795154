
/**
 * Exceptions from defined gui errors.
 * 
 * Gui Error Codes are unique and message and title are derived automatically.
 */
export class GuiErrorException extends Error{
	constructor(errorCode: GuiErrorCode) {
		const gui_error = GuiErrorList.find((guiError) => { return guiError.code === errorCode});
		super(gui_error.message);
		this.code = gui_error.code;
		this.title = gui_error.title;
	}
	readonly code: number;
	readonly title: string;
}

export interface ShowableError<A> {
	convert(arg:A) : GuiErrorInfo;
}

export class ShowError implements ShowableError<Error> {
	convert(arg: Error): GuiErrorInfo {
		const stack:string[] = arg.stack ? arg.stack.split("\n") : [];
		return new GuiErrorInfo(-1, arg.message, arg.name, stack);
	}
}

export class GuiErrorInfo {
	readonly httpCode: number;
	readonly httpMessage: string;
	readonly httpObject: string;
	readonly stackTrace: string[];

	constructor(
		code: number,
		message: string,
		object: string,
		stacktrace?: string[]
	) {
		this.httpCode = code;
		this.httpMessage = message;
		this.httpObject = object;

		if (stacktrace) {
			this.stackTrace = stacktrace;
		} else {
			this.stackTrace = [];
		}
	}
}

export enum GuiErrorCode {
	notAllFieldsEntered,
	invalidPassword,
    invalidCaptcha,
	notMatchPassword,
	notAgreedToTerms,
	noConnectorSelected,
	noSettingsProvided,
	noFileProvided,
	noColumnsSelected,
	entryIncomplete,
	noRightsSelected,
	noRoleSelected,
	noObjectSelected,
	dsNameOrWriteModeNotSet
}

export class GuiError {
	code: number;
	title: string;
	message: string;

	constructor(code: number, title: string, message: string) {
		this.code = code;
		this.title = title;
		this.message = message;
	}
}

export const GuiErrorList = Array(
	new GuiError(
		GuiErrorCode.notAllFieldsEntered,
		"Invalid parameter",
		"Not all mandatory fields are entered"
	),
	new GuiError(
		GuiErrorCode.invalidPassword,
		"Invalid parameter",
		"Password must contain at least 8 characters"
	),
	new GuiError(
		GuiErrorCode.notMatchPassword,
		"Invalid parameter",
		"Your entered passwords do not match"
	),
    new GuiError(
		GuiErrorCode.invalidCaptcha,
		"Invalid Captcha",
		"Please verify that you are human"
	),
	new GuiError(
		GuiErrorCode.noConnectorSelected,
		"Invalid parameter",
		"No connector selected"
	),
	new GuiError(
		GuiErrorCode.noSettingsProvided,
		"Invalid parameter",
		"No settings provided"
	),
	new GuiError(
		GuiErrorCode.noFileProvided,
		"Invalid parameter",
		"No file provided"
	),
	new GuiError(
		GuiErrorCode.noColumnsSelected,
		"Invalid parameter",
		"No columns are selected"
	),
	new GuiError(
		GuiErrorCode.entryIncomplete,
		"Invalid parameter",
		"Entry is incomplete"
	),
	new GuiError(
		GuiErrorCode.noRoleSelected,
		"Invalid parameter",
		"No role selected"
	),
	new GuiError(
		GuiErrorCode.noObjectSelected,
		"Invalid parameter",
		"No object selected"
	),
	new GuiError(
		GuiErrorCode.noRightsSelected,
		"Invalid parameter",
		"No rights selected"
	),
    new GuiError(
		GuiErrorCode.notAgreedToTerms,
		"Terms must be agreed",
		"Please agree to terms to proceed"
	),
	new GuiError(
		GuiErrorCode.dsNameOrWriteModeNotSet,
		"Missing datasource name,writemode",
		"No name or write mode selected"
	)
);
