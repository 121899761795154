<div class="card" style="height: calc(62%); margin-top: 20px">

    <!-- <p-tabView>
        <p-tabPanel header="{{item.header | translate }}" *ngFor="let item of tabs; let i = index" [selected]="i == 0">

            <div *ngIf="i === 0">            
                <app-generic-calendar-widget #calendarObjectWidget [typeClass]="calendarTypeClass" [objList]="objList"></app-generic-calendar-widget>
            </div>
            <div *ngIf="i === 1">            
                <app-generic-team-widget></app-generic-team-widget>
            </div>
            <div *ngIf="i === 2">            
                <div>
                    <div class="p-grid" style="display: flex; align-items: center;">
                        <div class="p-col-1">
                            <i class="pi pi-table" style="font-size: 24px"></i>
                        </div>
                        <div class="p-col-7">
                            <span>This datasource has 14 columns, 4230 rows and 4 datatypes</span>             
                        </div>
                        <div class="p-col">
                            <span style="float:right">
                                <p-avatarGroup styleClass="mb-3">
                                    <p-avatar icon="pi pi-calendar" size="large" shape="circle"></p-avatar>
                                    <p-avatar icon="pi pi-globe"size="large" shape="circle"></p-avatar>
                                    <p-avatar icon="pi pi-user" size="large"shape="circle"></p-avatar>
                                    <p-avatar label="+2" shape="circle" size="large" [style]="{'background-color':'#9c27b0', 'color': '#ffffff'}"></p-avatar>
                                </p-avatarGroup>
                            </span>
        
                        </div>
                    </div>
                    <div class="p-grid" style="display: flex; align-items: center;">
                        <div class="p-col-1">
                            <i class="pi pi-user" style="font-size: 24px"></i>
                        </div>
                        <div class="p-col-7">
                            <span>Current write mode </span>             
                        </div>
                        <div class="p-col">
                            <span  style="float:right" ><p-chip label="Append"></p-chip></span>
                        </div>
                    </div>
                    <div class="p-grid" style="display: flex; align-items: center;">
                        <div class="p-col-1">
                            <i class="pi pi-folder" style="font-size: 24px"></i>
                        </div>
                        <div class="p-col-7">
                            <span>Latest data package</span>             
                        </div>
                        <div class="p-col">
                            <span  style="float:right" ><p-chip label="V2"></p-chip></span>
                        </div>
                    </div>
                </div>
                <app-field-info-table></app-field-info-table>
                    <p-divider></p-divider>
                    <h5>Storing 22231239 entries                
                        <span style="float:right"><button pButton label="{{'ViewData' | translate}}"></button></span>
                    </h5>            
            </div>
            <div *ngIf="i === 3">            
            </div>
        </p-tabPanel>
    </p-tabView> -->

    <p-tabView [scrollable]="false">
        <p-tabPanel header="{{'Activities' | translate }}">
            <app-generic-latest-activities #dsLatestActivities [typeClass]="dataSourceActivityTypeClass" [currentView]="'Datasources'"></app-generic-latest-activities>

            <!-- <app-generic-calendar-widget #calendarObjectWidget [typeClass]="calendarTypeClass" [objList]="objList"></app-generic-calendar-widget> -->
        </p-tabPanel>
        <p-tabPanel header="{{'Team' | translate }}">
            <!-- <app-generic-team-widget></app-generic-team-widget> -->

            <app-user-permissions-list view="DatasourceView"></app-user-permissions-list>
        </p-tabPanel>
        <p-tabPanel header="{{'Data' | translate }}">
            <div class="p-grid" style="text-align: center;">
            <div class="p-col-6 p-grid" style="display: flex; align-items: center;background-color: brown;">
                <div class="p-col-2">
                    <i class="pi pi-table" style="font-size: 24px"></i>
                </div>
                <div class="p-col-10">
                    <span>Datatypes</span>             
                </div>
                <div class="p-col-12">
                    <span >
                        <p-avatarGroup styleClass="mb-3">
                            <p-avatar icon="pi pi-calendar" size="large" shape="circle"></p-avatar>
                            <p-avatar icon="pi pi-globe"size="large" shape="circle"></p-avatar>
                            <p-avatar icon="pi pi-user" size="large"shape="circle"></p-avatar>
                        </p-avatarGroup>
                    </span>

                </div>
            </div>
            <div class="p-col-6 p-grid" style="display: flex; align-items: center;">
                <div class="p-col-1">
                    <i class="pi pi-user" style="font-size: 24px"></i>
                </div>
                <div class="p-col-7">
                    <span>Current write mode </span>             
                </div>
                <div class="p-col">
                    <span  style="float:right" ><p-chip label="Append"></p-chip></span>
                </div>
            </div>
            <div class="p-grid" style="display: flex; align-items: center;">
                <div class="p-col-1">
                    <i class="pi pi-folder" style="font-size: 24px"></i>
                </div>
                <div class="p-col-7">
                    <span>Latest data package</span>             
                </div>
                <div class="p-col">
                    <span  style="float:right" ><p-chip label="V2"></p-chip></span>
                </div>
            </div>
            <div class="p-grid" style="display: flex; align-items: center;">
                <div class="p-col-1">
                    <i class="pi pi-database" style="font-size: 24px"></i>
                </div>
                <div class="p-col-7">
                    <span>Data pool</span>             
                </div>
                <div class="p-col" style="float:right">
                    <span ><button pButton label="{{'Fields' | translate}}"></button></span>
                    <span ><button pButton label="{{'Data' | translate}}"></button></span>
                </div>
            </div>
        </div>
        <!-- <p-scrollPanel [style]="{height: 'calc(150px)'}">
            <app-field-info-table></app-field-info-table>

            <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
            </p-scrollTop>
        </p-scrollPanel>
            <p-divider></p-divider>
            <h5>Current Load Mode:                    <span ><p-chip label="Append"></p-chip></span>                
                <span style="float:right"><button pButton label="{{'ViewData' | translate}}"></button></span>
            </h5> -->

        </p-tabPanel>
        <p-tabPanel header="{{'API' | translate }}">
         
                <app-pipeline [extensionUrl]="'dataSource/'"></app-pipeline>
            
        </p-tabPanel>
        <p-tabPanel header="{{'Download' | translate }}"> 
            <app-pipeline></app-pipeline>
  
        </p-tabPanel>
    </p-tabView>
    </div>