import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { WorkflowActionEvent, WorkflowDialogActionType } from 'src/app/models/dialog-actions.model';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { SubSink } from 'subsink';

import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

export class ExportWorkflowArg {
  Function: string;
  Input: WorkflowRepositoryEntry[];

  constructor(Function: string, Input: WorkflowRepositoryEntry[]) {
    this.Function = Function;
    this.Input = Input;
  }
}

@Component({
  selector: 'app-export-workflows-dialog',
  templateUrl: './export-workflows-dialog.component.html',
  styleUrls: ['./export-workflows-dialog.component.scss']
})
export class ExportWorkflowsDialogComponent implements OnInit {
  subs = new SubSink;
  displayDialog = false;
  savingInProgress = false;
  dialogEvent: WorkflowActionEvent;
  workflows: WorkflowRepositoryEntry[] = [];
  selectedWorkflows: WorkflowRepositoryEntry[] = [];
  header: string;
  constructor(private workflowService: WorkflowsService, private errorService: SystemMessageLogService, private messageService: MessageService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.subs.sink = this.workflowService.getWorkflowObjectList().subscribe((wfs: WorkflowRepositoryEntry[]) => {
      this.workflows = wfs;
    });
    this.subs.sink = this.workflowService.workflowDialogActionSendEmitter.subscribe((wfActionEvent) => {
      if(wfActionEvent.actionType === WorkflowDialogActionType.exportWorkflows) {
        this.dialogEvent = wfActionEvent;
        this.displayDialog = wfActionEvent.display;
        this.header = wfActionEvent.header

      }
    },(err:Error) => {
      this.errorService.handleError(err)
    })
  }



  exportWorkflows() {
    this.savingInProgress = true;
    console.log(this.selectedWorkflows);

    try{
      //let arg = new ExportWorkflowArg("Export Workflows", this.selectedWorkflows);
      var json = JSON.stringify(this.selectedWorkflows);
      
      // Create a Blob object from the JSON data
      const blob = new Blob([json], { type: 'application/json' });
    
      // Generate a unique filename for the exported JSON file
      const filename = 'exported_workflows.json';
    
      // Save the Blob as a file using FileSaver.js
      saveAs(blob, filename);
    } catch(err) {
      this.errorService.handleError(err);
    } finally {

      this.messageService.add({
        severity: "success",
        summary: this.translate.instant("Message.ExportWorkflowSuccess.Title"),
        detail: this.selectedWorkflows.length + this.translate.instant("Message.ExportWorkflowSuccess.Text"),
      });

      this.savingInProgress = false;
      this.displayDialog = false;
    }
  


  }
  

}
