import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { WorkflowProtocolEntry } from 'src/app/models/api/models/workflow/WorkflowProtocolEntry';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { DataSource } from 'src/app/models/datasource.model';
import { DataStore } from 'src/app/models/datastore.model';
import { WorkflowNodeInfo } from 'src/app/models/designer.models';
import { ScheduleActionPlan } from 'src/app/models/schedule.model';
import { UserDetailsRow } from 'src/app/models/user.model';
// import { WorkflowNode, WorkflowRepositoryEntry } from 'src/app/models/workflow.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { ObjectSearchService } from 'src/app/services/object-search.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { SubSink } from 'subsink';
import { WorkflowGraphComponent } from '../../designer/components/workflow-graph/workflow-graph.component';
import { GeneralObjectViewComponent } from '../../objectManagement/general-object-view/general-object-view.component';
import { WorkflowRecord, WorkflowRecordView, WorkflowViewRecord } from '../../objectManagement/general-object-view/provider-workflow';
import { GeneralSearchComponent, WorkflowSearch } from '../../objectManagement/general-search/general-search.component';
import { GenericBottomToolbarComponent } from '../../objectManagement/generic-bottom-toolbar/generic-bottom-toolbar.component';
import { WorkflowBottomToolbar, WorkflowToolbarData, WorkflowToolbarResult } from '../../objectManagement/generic-bottom-toolbar/provider-workflow';
import { GenericLatestActivitiesComponent } from '../../objectManagement/generic-latest-activities/generic-latest-activities.component';
import { WorkflowActivity, WorkflowViewActivity } from '../../objectManagement/generic-latest-activities/provider-workflow';
import { GenericTopToolbarComponent } from '../../objectManagement/generic-top-toolbar/generic-top-toolbar.component';
import { GenericWidgetsComponent, WorkflowWidget } from '../../objectManagement/generic-widgets/generic-widgets.component';
import { WorkflowToolbar } from '../../objectManagement/generic-top-toolbar/provider-workflow';
import { ExperimentalApi } from 'src/app/services/experimental-api.service';
import { GenericObjectTasksComponent } from '../../objectManagement/generic-object-tasks/generic-object-tasks.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { WorkflowActionEvent, WorkflowDialogActionType, WorkflowActionEventStatus } from 'src/app/models/dialog-actions.model';

@Component({
  selector: 'app-workflows-view',
  templateUrl: './workflows-view.component.html',
  styleUrls: ['./workflows-view.component.scss'],
  providers: [MessageService],
  animations: [trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition(":enter", [animate(500)]),
    transition(":leave", [animate(500)]),
  ]),]
})
export class WorkflowsViewRelayoutComponent implements OnInit {
  // Gui Elements
  navMenuItem: MenuItem[] = [];
  private subs = new SubSink();
  loading: boolean = false;
  displaySearchPanel: boolean = true;
  displayWidgets: boolean = true;
  // WF specific classes
  userDetails: UserDetailsRow[] = [];
  workflows:WorkflowRepositoryEntry[] = [];
  selectedWorkflow?: WorkflowRepositoryEntry;
  // WF specific TypeClass
  workflowToolbarTypeClass: WorkflowToolbar = new WorkflowToolbar(this.workflowsService, this.userService, this.router);
  workflowRecordTypeClass: WorkflowRecord = new WorkflowRecord(this.bionApi, this.systemLogService, this.userService, this.utilService, this.workflowsService, this.objectSearchService);
  workflowSearchTypeClass: WorkflowSearch = new WorkflowSearch(this.bionApi, this.systemLogService, this.userService, this.utilService, this.workflowsService, this.objectSearchService);
  workflowWidgetClass: WorkflowWidget = new WorkflowWidget(this.bionApi);
  workflowActivityTypeClass = new WorkflowActivity(this.bionApi, this.workflowsService, this.userDetails,this.utilService);
  workflowBottomToolbarTypeClass = new WorkflowBottomToolbar(this.bionApi, this.workflowsService);
  //calendarTypeClass = new WorkflowSchedulePart(this.bionApi,this.workflowsService,this.schedulesService);



  // New Type Classes
  // objects, activity, bottom toolbar
  workflowViewRecordTypeClass: WorkflowViewRecord = new WorkflowViewRecord([],[], this.workflowsService,  this.systemLogService, this.objectSearchService, this.utilService);
  workflowViewActivityTypeClass = new WorkflowViewActivity([], this.workflowsService,this.userDetails, this.utilService, this.router);
  workflowViewBottomToolbarTypeClass = new WorkflowBottomToolbar(this.bionApi, this.workflowsService);


  @ViewChild("wfObjectToolbar") wfObjectToolbar!: GenericTopToolbarComponent<WorkflowRepositoryEntry>;
  @ViewChild("wfObjectView") wfObjectView!: GeneralObjectViewComponent<WorkflowRepositoryEntry, WorkflowRecordView, any,any>;
  @ViewChild("wfObjectSearch") wfObjectSearch!: GeneralSearchComponent<[WorkflowRepositoryEntry[], WorkflowNodeInfo[], WorkflowProtocolEntry[], UserDetailsRow[], ScheduleActionPlan[], DataSource[], DataStore[]], any>;
  @ViewChild("wfObjectWidgets") wfObjectWidgets!: GenericWidgetsComponent<WorkflowRepositoryEntry>;
  //@ViewChild('calendarObjectWidget') calendarObjectWidget: GenericCalendarWidgetComponent<WorkflowRepositoryEntry>;
  @ViewChild('wfLatestActivities') wfLatestActivities!: GenericLatestActivitiesComponent<WorkflowRepositoryEntry, any>;
  @ViewChild('wfBottomToolbar') wfBottomToolbar!: GenericBottomToolbarComponent<WorkflowRepositoryEntry, never, WorkflowToolbarResult, WorkflowToolbarData>;

  @ViewChild('wfObjectTasks') wfObjectTasks!: GenericObjectTasksComponent;

  @ViewChild("workflowGraph") graph!: WorkflowGraphComponent;

  constructor(
    private bionApi: ApiBackendService,
    public appMain: AppMainComponent,
    private workflowsService: WorkflowsService,
    private systemLogService: SystemMessageLogService,
    private userService: UserService,
    private objectSearchService: ObjectSearchService,
    private utilService: UtilFunctionsService,
    public translate: TranslateService,
    public experimentalApi: ExperimentalApi,
    public router: Router,
    public messageService: MessageService
  ) { }

  ngOnInit(): void {

    const use_new_approach = true;

    this.subs.sink = this.workflowsService.selectedWorkflowEmitter.subscribe(
      (wf: WorkflowRepositoryEntry) => {
        this.selectedWorkflow = wf;

        if (wf !== undefined) {
          this.loadWorkflowIntoView(wf);
        }
      }
    );


    if (use_new_approach) {

      this.subs.sink = this.initViewNew().subscribe(() => {
        this.loading = false;
      }, err => {
        this.systemLogService.handleError(err);
      });

      this.subs.sink = this.workflowsService.workflowsChangedEmitter.subscribe(() => {
        this.subs.sink = this.initViewNew(this.selectedWorkflow).subscribe(() => {
          this.loading = false;
        }, err => {
          this.systemLogService.handleError(err);
        });
      },
        (err) => {
          this.systemLogService.handleError(err);
        });

    } else {

      this.subs.sink = this.initView().subscribe(() => {
        this.loading = false;
      }, err => {
        this.systemLogService.handleError(err);
      });

      this.subs.sink = this.workflowsService.workflowsChangedEmitter.subscribe(() => {
        this.subs.sink = this.initView(this.selectedWorkflow).subscribe(() => {
          this.loading = false;
        }, err => {
          this.systemLogService.handleError(err);
        });
      },
        (err) => {
          this.systemLogService.handleError(err);
        });

    }

    this.subs.sink = this.workflowsService.workflowDialogActionReceivedEmitter.subscribe((wfEvent:WorkflowActionEvent) => {
        this.handleWorkflowEvent(wfEvent);
    }, (err: Error) => {
        this.systemLogService.handleError(err);
    })



  }

  handleWorkflowEvent(event: WorkflowActionEvent) {
    if(event.actionType === WorkflowDialogActionType.createWorkflow) {
        this.createNewWorkflow(event)
    }
    if(event.actionType === WorkflowDialogActionType.editWorkflow) {
        this.editWorkflow(event)
    }
  }

  	/**
	 * Erstellt einen neuen und leeren Workflow
	 */
	createNewWorkflow(wfEvent: WorkflowActionEvent) {

		const wf_obs = wfEvent.wf ? of(wfEvent.wf) : throwError(new Error("No workflow entry given"));

		const create_obs = wf_obs.pipe(concatMap(wf => this.workflowsService.createWorkflowObject(wf)));

		this.subs.sink = create_obs
			.subscribe((res: WorkflowRepositoryEntry) => {
				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.CreateWorkflowSuccess.Title"),
					detail: this.translate.instant("Message.CreateWorkflowSuccess.Text1") + res.id + this.translate.instant("Message.CreateWorkflowSuccess.Text2"),
				});

				this.selectedWorkflow = { ...res };
				this.workflowsService.workflowsChangedEmitter.emit("New workflow created");
				this.workflowsService.workflowDialogActionStatusEmitter.emit(new WorkflowActionEventStatus(true, wfEvent.actionType, true, false, res));
				//this.createWorkflowSuccess = true;
			}, (error: Error) => {
				this.systemLogService.handleError(error);

			});
	}

    	/**
	 * Aktualisiert den bereits geöffneten Workflow
	 */
	editWorkflow(wfEvent: WorkflowActionEvent) {
		// let wfEntry = {...wfEvent.wf};
		// const workflowData = this.graph.getWorkflow();
		// wfEntry.workflowData = workflowData;

		const wf_obs = wfEvent.wf ? of(wfEvent.wf) : throwError(new Error("No workflow entry given"));
		const wf_ready_obs = wf_obs.pipe(map(wf => {
			const wfEntry: WorkflowRepositoryEntry = { ...wf };
			wfEntry.workflowData = wf.workflowData;
			return wfEntry;
		}))

		const update_obs: Observable<[WorkflowRepositoryEntry, number]> = wf_ready_obs
			.pipe(concatMap(wf => this.workflowsService.updateWorkflowObject(wf)
				.pipe(map(count => {
					const result: [WorkflowRepositoryEntry, number] = [wf, count];
					return result;
				}))));

		this.subs.sink = update_obs.subscribe(
			(res: [WorkflowRepositoryEntry, number]) => {

				const wfEntry = res[0];

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.UpdateWorkflowSuccess.Title"),
					detail: this.translate.instant("Message.UpdateWorkflowSuccess.Text1") + wfEntry.id + this.translate.instant("Message.UpdateWorkflowSuccess.Text2"),
				});
				this.workflowsService.workflowsChangedEmitter.emit("workflow updated");
				this.workflowsService.workflowDialogActionStatusEmitter.emit(new WorkflowActionEventStatus(false, wfEvent.actionType, true, false, wfEntry));

				//this.displayWorkflowDialog = false;
			},
			(error: Error) => {
				this.systemLogService.handleError(error);

			}
		);

	}


  loadWorkflowIntoView(wf: WorkflowRepositoryEntry) {
    //this.subs.sink = this.graph.setWorkflow(wf.workflowData).subscribe();
  }

  clearWorkflow() {
    //this.graph = undefined;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initViewNew(wf?: WorkflowRepositoryEntry): Observable<boolean> {

    this.loading = true;
    // top, search, object, activity, bottom

    const userDetailsObs = this.userService.getUserDetailsRow();
    const wfObs = this.experimentalApi.getWorkflowView();

    const obs = forkJoin(wfObs,userDetailsObs);

     const finalObs = obs.pipe(concatMap(rich_data => {

        console.log("Init View New Data", rich_data);

        const workflows = rich_data[0].map(d => d.Workflow);
        this.workflows = workflows;
        this.userDetails = rich_data[1];


        //const top_obs = this.wfObjectToolbar.setToolbar();
        //const search_obs = this.wfObjectSearch.setSearchModel(this.workflowSearchTypeClass, this.workflows);

        this.workflowViewRecordTypeClass = new WorkflowViewRecord(rich_data[0], this.userDetails, this.workflowsService, this.systemLogService, this.objectSearchService, this.utilService);
        const object_obs = this.wfObjectView.setObjects(workflows, this.workflowViewRecordTypeClass);

        //this.workflowViewActivityTypeClass = new WorkflowViewActivity(rich_data[0], this.workflowsService,this.userDetails, this.utilService);
        //const activity_obs = this.wfLatestActivities.setObject(this.workflowViewActivityTypeClass);

        //const tasks_obs = this.wfObjectTasks.setObject();

        // replace with new one!
        console.log("ATTENTION: top, search and bottom toolbar need still adjustements!");
        //const bottom_obs = this.wfBottomToolbar.setObject(this.workflowBottomToolbarTypeClass);

        //return forkJoin(Array(top_obs, search_obs, object_obs, activity_obs,tasks_obs)).pipe(map(a => a.reduce((a, b) => a && b)));
        return forkJoin(Array(object_obs)).pipe(map(a => a.reduce((a, b) => a && b)));
     }));

     return finalObs;

  }


  initView(wf?: WorkflowRepositoryEntry): Observable<[boolean]> {
    this.loading = true;
    const userDetailsObs = this.userService.getUserDetailsRow();
    const wfObs = this.workflowsService.getWorkflowObjectList();

    let obs = forkJoin(wfObs,userDetailsObs)

    let wfResultObs = obs.pipe(concatMap((dsResults) => {
      this.workflows = dsResults[0];
      this.userDetails = dsResults[1];

      //let topToolBarObs = this.wfObjectToolbar.setToolbar();
      let wfObs = this.wfObjectView.setObjects(this.workflows, this.workflowRecordTypeClass);
      //console.log(this.wfObjectView.records);
      //let searchObs = this.wfObjectSearch.setSearchModel(this.workflowSearchTypeClass, this.workflows);
      //let calendarObs = this.calendarObjectWidget.setObjects(this.calendarTypeClass, this.workflows);
      //let activitiesObs = this.wfLatestActivities.setObject(this.workflowActivityTypeClass);
      //let bottomToolbarObs = this.wfBottomToolbar.setObject(this.workflowBottomToolbarTypeClass);

      let initCompObs = forkJoin( wfObs);
      return initCompObs
    }));

    return wfResultObs

  }


}
