import { MColumn } from "../slick/jdbc/meta/MColumn";
import { MTable } from "../slick/jdbc/meta/MTable";
import { TaskJobModel } from "./task/core/TaskJobModel";

export namespace IntegratedSourceModel {
    export class ConnectorKey<A> {
        Id: A;
        Origin: string;
        constructor(Id: A, Origin: string) {
            if (Id === undefined) throw new Error("Class 'ConnectorKey': Required value 'Id' is undefined");
            if (Origin === undefined) throw new Error("Class 'ConnectorKey': Required value 'Origin' is undefined");
            this.Id = Id;
            this.Origin = Origin;
        }
    }

    export class ConnectorSpec<K, S> {
        ConnectorId: ConnectorKey<K>;
        OriginalSpecs: S;
        constructor(ConnectorId: ConnectorKey<K>, OriginalSpecs: S) {
            if (ConnectorId === undefined) throw new Error("Class 'ConnectorSpec': Required value 'ConnectorId' is undefined");
            if (OriginalSpecs === undefined) throw new Error("Class 'ConnectorSpec': Required value 'OriginalSpecs' is undefined");
            this.ConnectorId = ConnectorId;
            this.OriginalSpecs = OriginalSpecs;
        }
    }

    export class CreateDataSourceArg<C> {
        ConnectorKey: ConnectorKey<string>;
        Config: C;
        Name: string;
        constructor(ConnectorKey: ConnectorKey<string>, Config: C, Name: string) {
            if (ConnectorKey === undefined) throw new Error("Class 'CreateDataSourceArg': Required value 'ConnectorKey' is undefined");
            if (Config === undefined) throw new Error("Class 'CreateDataSourceArg': Required value 'Config' is undefined");
            if (Name === undefined) throw new Error("Class 'CreateDataSourceArg': Required value 'Name' is undefined");
            this.ConnectorKey = ConnectorKey;
            this.Config = Config;
            this.Name = Name;
        }
    }

    export class DataSourceKey<A> {
        Id: A;
        Origin: string;
        constructor(Id: A, Origin: string) {
            if (Id === undefined) throw new Error("Class 'DataSourceKey': Required value 'Id' is undefined");
            if (Origin === undefined) throw new Error("Class 'DataSourceKey': Required value 'Origin' is undefined");
            this.Id = Id;
            this.Origin = Origin;
        }
    }

    export class SyncArg {
        SourceKey: DataSourceKey<number>;
        data?: Array<number>;
        constructor(SourceKey: DataSourceKey<number>, data?: Array<number>) {
            if (SourceKey === undefined) throw new Error("Class 'SyncArg': Required value 'SourceKey' is undefined");
            this.SourceKey = SourceKey;
            this.data = data;
        }
    }

    export class SyncResult {
        TaskJob: TaskJobModel.JobRequestResult;
        constructor(TaskJob: TaskJobModel.JobRequestResult) {
            if (TaskJob === undefined) throw new Error("Class 'SyncResult': Required value 'TaskJob' is undefined");
            this.TaskJob = TaskJob;
        }
    }

    export class CreateDataSourceResult<A> {
        SourceKey: DataSourceKey<number>;
        OriginalResult: A;
        constructor(SourceKey: DataSourceKey<number>, OriginalResult: A) {
            if (SourceKey === undefined) throw new Error("Class 'CreateDataSourceResult': Required value 'SourceKey' is undefined");
            if (OriginalResult === undefined) throw new Error("Class 'CreateDataSourceResult': Required value 'OriginalResult' is undefined");
            this.SourceKey = SourceKey;
            this.OriginalResult = OriginalResult;
        }
    }

    export class GetCatalogArg {
        SourceKey: DataSourceKey<number>;
        constructor(SourceKey: DataSourceKey<number>) {
            if (SourceKey === undefined) throw new Error("Class 'GetCatalogArg': Required value 'SourceKey' is undefined");
            this.SourceKey = SourceKey;
        }
    }

    export class GetCatalogResult<A> {
        Catalog: GenCatalog;
        OriginalResult: A;
        constructor(Catalog: GenCatalog, OriginalResult: A) {
            if (Catalog === undefined) throw new Error("Class 'GetCatalogResult': Required value 'Catalog' is undefined");
            if (OriginalResult === undefined) throw new Error("Class 'GetCatalogResult': Required value 'OriginalResult' is undefined");
            this.Catalog = Catalog;
            this.OriginalResult = OriginalResult;
        }
    }

    export class DefineStreamsArg<S> {
        SourceKey: DataSourceKey<number>;
        StreamSchema: S;
        Catalog?: GenCatalog;
        constructor(SourceKey: DataSourceKey<number>, StreamSchema: S, Catalog?: GenCatalog) {
            if (SourceKey === undefined) throw new Error("Class 'DefineStreamsArg': Required value 'SourceKey' is undefined");
            if (StreamSchema === undefined) throw new Error("Class 'DefineStreamsArg': Required value 'StreamSchema' is undefined");
            this.SourceKey = SourceKey;
            this.StreamSchema = StreamSchema;
            this.Catalog = Catalog;
        }
    }

    export class DefineStreamsResult<A> {
        OriginalResult: A;
        constructor(OriginalResult: A) {
            if (OriginalResult === undefined) throw new Error("Class 'DefineStreamsResult': Required value 'OriginalResult' is undefined");
            this.OriginalResult = OriginalResult;
        }
    }

    export class UpdateDataSourceArg {
        SourceKey: DataSourceKey<number>;
        Name: string;
        constructor(SourceKey: DataSourceKey<number>, Name: string) {
            if (SourceKey === undefined) throw new Error("Class 'UpdateDataSourceArg': Required value 'SourceKey' is undefined");
            if (Name === undefined) throw new Error("Class 'UpdateDataSourceArg': Required value 'Name' is undefined");
            this.SourceKey = SourceKey;
            this.Name = Name;
        }
    }

    export class DeleteDataSourceArg {
        SourceKey: DataSourceKey<number>;
        constructor(SourceKey: DataSourceKey<number>) {
            if (SourceKey === undefined) throw new Error("Class 'DeleteDataSourceArg': Required value 'SourceKey' is undefined");
            this.SourceKey = SourceKey;
        }
    }

    export class GetStreamsResult {
        Catalog: GenCatalog;
        constructor(Catalog: GenCatalog) {
            if (Catalog === undefined) throw new Error("Class 'GetStreamsResult': Required value 'Catalog' is undefined");
            this.Catalog = Catalog;
        }
    }

    export class DataSource<A> {
        Id: DataSourceKey<A>;
        Name: string;
        Description?: string;
        Connector: ConnectorKey<string>;
        constructor(Id: DataSourceKey<A>, Name: string, Connector: ConnectorKey<string>, Description?: string) {
            if (Id === undefined) throw new Error("Class 'DataSource': Required value 'Id' is undefined");
            if (Name === undefined) throw new Error("Class 'DataSource': Required value 'Name' is undefined");
            if (Connector === undefined) throw new Error("Class 'DataSource': Required value 'Connector' is undefined");
            this.Id = Id;
            this.Name = Name;
            this.Description = Description;
            this.Connector = Connector;
        }
    }

    export class GetStreamsArg {
        SourceKey: DataSourceKey<number>;
        constructor(SourceKey: DataSourceKey<number>) {
            if (SourceKey === undefined) throw new Error("Class 'GetStreamsArg': Required value 'SourceKey' is undefined");
            this.SourceKey = SourceKey;
        }
    }

    export class UpdateStreamsArg {
        SourceKey: DataSourceKey<number>;
        Catalog: GenCatalog;
        SkipReset: boolean;
        constructor(SourceKey: DataSourceKey<number>, Catalog: GenCatalog, SkipReset: boolean) {
            if (SourceKey === undefined) throw new Error("Class 'UpdateStreamsArg': Required value 'SourceKey' is undefined");
            if (Catalog === undefined) throw new Error("Class 'UpdateStreamsArg': Required value 'Catalog' is undefined");
            if (SkipReset === undefined) throw new Error("Class 'UpdateStreamsArg': Required value 'SkipReset' is undefined");
            this.SourceKey = SourceKey;
            this.Catalog = Catalog;
            this.SkipReset = SkipReset;
        }
    }

    export class UpdateStreamsResult {
        Catalog: number;
        constructor(Catalog: number) {
            if (Catalog === undefined) throw new Error("Class 'UpdateStreamsResult': Required value 'Catalog' is undefined");
            this.Catalog = Catalog;
        }
    }

    export class PsaInfo {
        Name: string;
        Table: string;
        Stream: string;
        Connection?: number;
        TableInfo: MTable;
        Columns: Array<MColumn>;
        IsRaw: boolean;
        IsNormalized: boolean;
        IsSubTable: boolean;
        Origin: string;
        constructor(Name: string, Table: string, Stream: string, TableInfo: MTable, Columns: Array<MColumn>, IsRaw: boolean, IsNormalized: boolean, IsSubTable: boolean, Origin: string, Connection?: number) {
            if (Name === undefined) throw new Error("Class 'PsaInfo': Required value 'Name' is undefined");
            if (Table === undefined) throw new Error("Class 'PsaInfo': Required value 'Table' is undefined");
            if (Stream === undefined) throw new Error("Class 'PsaInfo': Required value 'Stream' is undefined");
            if (TableInfo === undefined) throw new Error("Class 'PsaInfo': Required value 'TableInfo' is undefined");
            if (Columns === undefined) throw new Error("Class 'PsaInfo': Required value 'Columns' is undefined");
            if (IsRaw === undefined) throw new Error("Class 'PsaInfo': Required value 'IsRaw' is undefined");
            if (IsNormalized === undefined) throw new Error("Class 'PsaInfo': Required value 'IsNormalized' is undefined");
            if (IsSubTable === undefined) throw new Error("Class 'PsaInfo': Required value 'IsSubTable' is undefined");
            if (Origin === undefined) throw new Error("Class 'PsaInfo': Required value 'Origin' is undefined");
            this.Name = Name;
            this.Table = Table;
            this.Stream = Stream;
            this.Connection = Connection;
            this.TableInfo = TableInfo;
            this.Columns = Columns;
            this.IsRaw = IsRaw;
            this.IsNormalized = IsNormalized;
            this.IsSubTable = IsSubTable;
            this.Origin = Origin;
        }
    }

    export class GetPsaInfosArg {
        SourceKey: DataSourceKey<number>;
        constructor(SourceKey: DataSourceKey<number>) {
            if (SourceKey === undefined) throw new Error("Class 'GetPsaInfosArg': Required value 'SourceKey' is undefined");
            this.SourceKey = SourceKey;
        }
    }

    export class QueryStreamArg {
		SourceKey: DataSourceKey<number>;
		Stream: string;
		PSA: PsaInfo;
		constructor(SourceKey: DataSourceKey<number>,Stream: string,PSA: PsaInfo) {
			if(SourceKey === undefined) throw new Error( "Class 'QueryStreamArg': Required value 'SourceKey' is undefined" );
			if(Stream === undefined) throw new Error( "Class 'QueryStreamArg': Required value 'Stream' is undefined" );
			if(PSA === undefined) throw new Error( "Class 'QueryStreamArg': Required value 'PSA' is undefined" );
			this.SourceKey = SourceKey;
			this.Stream = Stream;
			this.PSA = PSA;
		}
	}

    export class GenCatalog {
        streams: Array<GenCatalog.StreamInfo>;
        rules?: GenCatalog.CatalogRules;
        constructor(streams: Array<GenCatalog.StreamInfo>, rules?: GenCatalog.CatalogRules) {
            if (streams === undefined) throw new Error("Class 'GenCatalog': Required value 'streams' is undefined");
            this.streams = streams;
            this.rules = rules;
        }
    }

    export namespace GenCatalog {

        export class CatalogRules {
            supportsMultipleStream: boolean;
            constructor(supportsMultipleStream: boolean) {
                if (supportsMultipleStream === undefined) throw new Error("Class 'CatalogRules': Required value 'supportsMultipleStream' is undefined");
                this.supportsMultipleStream = supportsMultipleStream;
            }
        }

        export class StreamDetail {
            name: string;
            namespace?: string;
            sourcePrimaryKeys: Array<Array<string>>;
            constructor(name: string, sourcePrimaryKeys: Array<Array<string>>, namespace?: string) {
                if (name === undefined) throw new Error("Class 'StreamDetail': Required value 'name' is undefined");
                if (sourcePrimaryKeys === undefined) throw new Error("Class 'StreamDetail': Required value 'sourcePrimaryKeys' is undefined");
                this.name = name;
                this.namespace = namespace;
                this.sourcePrimaryKeys = sourcePrimaryKeys;
            }
        }

        export class StreamConfig {
            selected: boolean;
            suggested?: boolean;
            writeMode: string;
            constructor(selected: boolean, writeMode: string, suggested?: boolean) {
                if (selected === undefined) throw new Error("Class 'StreamConfig': Required value 'selected' is undefined");
                if (writeMode === undefined) throw new Error("Class 'StreamConfig': Required value 'writeMode' is undefined");
                this.selected = selected;
                this.suggested = suggested;
                this.writeMode = writeMode;
            }
        }

        export class StreamInfo {
            stream: StreamDetail;
            config: StreamConfig;
            constructor(stream: StreamDetail, config: StreamConfig) {
                if (stream === undefined) throw new Error("Class 'StreamInfo': Required value 'stream' is undefined");
                if (config === undefined) throw new Error("Class 'StreamInfo': Required value 'config' is undefined");
                this.stream = stream;
                this.config = config;
            }
        }

        export class WriteModeInfo {
            Label: string;
            SyncMode: string;
            DestSyncMode: string;
            GenWriteMode: string;
            Origins: Array<string>;
            constructor(Label: string, SyncMode: string, DestSyncMode: string, GenWriteMode: string, Origins: Array<string>) {
                if (Label === undefined) throw new Error("Class 'WriteModeInfo': Required value 'Label' is undefined");
                if (SyncMode === undefined) throw new Error("Class 'WriteModeInfo': Required value 'SyncMode' is undefined");
                if (DestSyncMode === undefined) throw new Error("Class 'WriteModeInfo': Required value 'DestSyncMode' is undefined");
                if (GenWriteMode === undefined) throw new Error("Class 'WriteModeInfo': Required value 'GenWriteMode' is undefined");
                if (Origins === undefined) throw new Error("Class 'WriteModeInfo': Required value 'Origins' is undefined");
                this.Label = Label;
                this.SyncMode = SyncMode;
                this.DestSyncMode = DestSyncMode;
                this.GenWriteMode = GenWriteMode;
                this.Origins = Origins;
            }
        }
    }
}