import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { concatMap, map } from 'rxjs/operators';
import { AppMainComponent } from 'src/app/app.main.component';
import { UserInfo } from 'src/app/models/api/models/session/UserInfo';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { UserDetailsRow } from 'src/app/models/user.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { ExperimentalApi } from 'src/app/services/experimental-api.service';
import { ObjectSearchService } from 'src/app/services/object-search.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { WorkflowViewRecord } from 'src/app/views/objectManagement/general-object-view/provider-workflow';
import { GenericLatestActivitiesComponent } from 'src/app/views/objectManagement/generic-latest-activities/generic-latest-activities.component';
import { WorkflowViewActivity } from 'src/app/views/objectManagement/generic-latest-activities/provider-workflow';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-workflow-view',
  templateUrl: './workflow-view.component.html',
  styleUrls: ['./workflow-view.component.scss'],
  providers: [MessageService]
})
export class WorkflowViewComponent implements OnInit, OnDestroy {
    subs = new SubSink;
    selectedWorkflow?: WorkflowRepositoryEntry;
    selectedWorkflow_copy?: WorkflowRepositoryEntry;
    editModeOn: boolean = false;
    loading: boolean = false;
    confirmDialog: boolean = false;
    userDetails: UserDetailsRow[]= [];
    @ViewChild('wfLatestActivities') wfLatestActivities!: GenericLatestActivitiesComponent<WorkflowRepositoryEntry, any>;
    @ViewChild('permView') permView;

    workflowActivityTypeClass = new WorkflowViewActivity([], this.workflowsService,this.userDetails, this.utilService, this.router);
    //workflowActivityTypeClass = new WorkflowActivity(this.bionApi, this.workflowsService, this.userDetails,this.utilService);




    constructor(
        private bionApi: ApiBackendService,
        public appMain: AppMainComponent,
        private workflowsService: WorkflowsService,
        private systemLogService: SystemMessageLogService,
        private userService: UserService,
        private objectSearchService: ObjectSearchService,
        private utilService: UtilFunctionsService,
        public translate: TranslateService,
        public experimentalApi: ExperimentalApi,
        public router: Router,
        public messageService: MessageService
      ) { }

  ngOnInit(): void {
    this.subs.sink = this.initView().subscribe(() => {
        this.loading = false;
    },(err) => this.systemLogService.handleError(err));
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  /**
   * Der Datasource Schlüssel aus der Route.
   * @returns Data Source Key
   */
  getUrlContext(): number {
    const arr = this.router.url.split('/');
    const id = parseInt(arr.last());
    const origin = arr.getRight(1);
    console.log("Workflow ID: " + id);
    //console.log("Workflow Origin: " + origin);
    return id;
  }
  initView(): Observable<boolean> {

    this.loading = true;
    // top, search, object, activity, bottom

    const current_id = this.getUrlContext();

    const userDetailsObs = this.userService.getUserDetailsRow();
    const wfObs = this.experimentalApi.getWorkflowView();

    const obs = forkJoin(wfObs,userDetailsObs);

     const finalObs = obs.pipe(concatMap(rich_data => {

        console.log("Init View Workflow", rich_data);
        const workflows = rich_data[0].map(d => d.Workflow);
        this.selectedWorkflow = workflows.find( w => w.id === current_id);
        this.selectedWorkflow_copy = {...this.selectedWorkflow};
        this.permView.selectedObject = this.selectedWorkflow;
        //this.workflows = workflows;
        this.userDetails = rich_data[1];

        this.workflowActivityTypeClass = new WorkflowViewActivity(rich_data[0], this.workflowsService,this.userDetails, this.utilService, this.router);
        const activity_obs = this.wfLatestActivities.setObject(this.workflowActivityTypeClass);

        return forkJoin(Array(activity_obs)).pipe(map(a => a.reduce((a, b) => a && b)));
     }));

     return finalObs;

  }

  onChangeEditMode() {
    if(this.editModeOn) {
        if(this.selectedWorkflow.name === this.selectedWorkflow_copy.name && this.selectedWorkflow.description == this.selectedWorkflow_copy.description && this.selectedWorkflow.runInfo.simulation.ReadOnly == this.selectedWorkflow_copy.runInfo.simulation.ReadOnly) {
            // Do nothing, just disable input
            this.editModeOn = false;
        } else {
            // show popup to ask

            this.confirmDialog = true;
        }
    } else {
        // activate input field to change data
        this.editModeOn = true;
    }
  }
  onChangeWorkflow(){
    this.loading = true;

    this.subs.sink = this.workflowsService.updateWorkflowObject(this.selectedWorkflow_copy).subscribe(() => {
        this.subs.sink = this.initView().subscribe(() => {
            this.confirmDialog = false;
            this.workflowsService.workflowsChangedEmitter.emit("workflowChanged");
            this.messageService.add({
                severity: "success",
                summary: this.translate.instant("Message.UpdateWorkflowSuccess.Title"),
                detail: this.translate.instant("Message.UpdateWorkflowSuccess.Text1") + this.selectedWorkflow_copy.id + this.translate.instant("Message.UpdateWorkflowSuccess.Text2"),
            });
        },(err) => {
            this.systemLogService.handleError(err)
        });

    },(err) => this.systemLogService.handleError(err),() => {
        this.editModeOn = false;
        this.loading = false;

    });
  }
  onResetDialog() {
    this.selectedWorkflow_copy = this.selectedWorkflow;
    this.editModeOn = false;
    this.confirmDialog = false;
  }
}
