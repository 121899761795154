<!-- <div class="spinner-container" *ngIf="savingInProgress">
    <app-loading-screen></app-loading-screen>
</div> -->
<!-- <div style="height: 2px">
    <p-progressBar *ngIf="savingInProgress" mode="indeterminate" [style]="{'height': '4px'}"></p-progressBar>
</div> -->
<p-menubar appendTo=body>
    <ng-template pTemplate="start">
        <button pButton pRipple type="button" icon="pi pi-folder" pTooltip="{{'Workflows.OpenWorkflow' | translate}}"
            tooltipPosition="top" class="p-button  p-mr-2 p-mb-2" [disabled]="savingInProgress"
            (click)="this.workflowService.displayOpenWorkflow.emit(true)">
        </button>
        <button pButton pRipple type="button" icon="pi pi-plus" pTooltip="{{'Workflows.CreateNewWorkflow' | translate}}"
            tooltipPosition="top" class="p-button  p-mr-2 p-mb-2" [disabled]="savingInProgress"
            (click)="onCreateNewWorkflow()">
        </button>
        <p-splitButton icon="pi pi-save" pTooltip="{{'Save workflow' | translate}}" tooltipPosition="top"
            styleClass="p-mr-2 p-mb-2" [disabled]="savingInProgress" (onClick)="onSaveWorkflow()"
            [model]="saveButtonItems">
        </p-splitButton>

        <button pButton pRipple type="button" icon="pi pi-arrow-left" pTooltip="{{'Undo' | translate}}"
            [disabled]="disableButton" tooltipPosition="top" (click)="onUndo()"
            class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button>
        <button pButton pRipple type="button" icon="pi pi-arrow-right" pTooltip="{{'Redo' | translate}}"
            tooltipPosition="top" (click)="onRedo()" class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button>
        <!-- <button pButton pRipple type="button" icon="pi pi-trash" pTooltip="{{'Delete' | translate}}"
            [disabled]="disableButton" tooltipPosition="top" (click)="onDelete()"
            class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button>
        <button pButton pRipple type="button" icon="pi pi-copy" pTooltip="{{'Copy' | translate}}"
            [disabled]="disableButton" tooltipPosition="top" (click)="onCopy()"
            class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button>
        <button pButton pRipple type="button" icon="pi pi-file" pTooltip="{{'Cut' | translate}}"
            [disabled]="disableButton" tooltipPosition="top" (click)="onCut()"
            class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button>
        <button pButton pRipple type="button" icon="pi pi-clone" pTooltip="{{'Paste' | translate}}"
            tooltipPosition="top" (click)="onPaste()" class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button> -->
        <!-- <p style="display:inline-block;margin-left:10px">{{wfName}}</p> -->
    </ng-template>
    <ng-template pTemplate="end">
        <span class="p-input-icon-right">
            <div>



                <!-- <button pButton pRipple type="button" icon="pi pi-arrow-left" pTooltip="{{'Undo' | translate}}"
                    tooltipPosition="top" (click)="onUndo()"
                    class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button>
                <button pButton pRipple type="button" icon="pi pi-arrow-right" pTooltip="{{'Redo' | translate}}"
                    tooltipPosition="top" (click)="onRedo()"
                    class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button> -->



                <button pButton pRipple type="button" icon="pi pi-play" pTooltip="{{'RunNode' | translate}}"
                    tooltipPosition="left" class="p-button-rounded p-button-success p-mr-2 p-mb-2"
                    [disabled]="savingInProgress || disableRunPartiallyButton"
                    (click)="onRunWorkflowPartially()"></button>

                <button pButton pRipple type="button" icon="pi pi-step-forward" pTooltip="{{'Run' | translate}}"
                    tooltipPosition="left" class="p-button-rounded p-button-success p-mr-2 p-mb-2"
                    [disabled]="savingInProgress || disableRunFullButton" (click)="onRunWorkflow()"></button>

                <!-- <button pButton pRipple type="button" icon="pi pi-save" pTooltip="{{'Save' | translate}}"
                    label="{{'Save' | translate}}" tooltipPosition="top" class="p-button  p-mr-2 p-mb-2"
                    [disabled]="savingInProgress" (click)="displaySaveWorkflow()"></button> -->





            </div>
        </span>
    </ng-template>
</p-menubar>
<!-- <div>
    <p *ngIf="updateSettingsStatus" style="display:inline-block;margin-right:10px"><i class="pi pi-spin pi-spinner"
            style="margin-right: 5px"></i><span style="font-style:italic;">{{'Updating...' | translate}}</span></p>
    <p *ngIf="savingInProgress" style="display:inline-block;margin-right:10px"><i class="pi pi-spin pi-spinner"
            style="margin-right: 5px"></i><span style="font-style:italic;">{{'Workflow running...' | translate}}</span>
    </p>
</div> -->
