import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Arrays } from "src/app/helper/arrays";
import { WorkflowNode } from "src/app/models/api/com/bion/etl/Workflow";
import { RichDataStoreCake } from "src/app/models/api/models/staging/RichDataStoreCake";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { DataSource } from "src/app/models/datasource.model";
import { DataStore } from "src/app/models/datastore.model";
import { DataStoreOutPlugInSettings } from "src/app/models/nodeSettings.model";
import { RunWorkflowAction, ScheduleActionPlan, ScheduleBaseAction } from "src/app/models/schedule.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { CubesService } from "src/app/services/cubes.service";
import { BottomToolbarProvider, PreviewData, ToolbarResult } from "./bottom-toolbar-provider";

export interface DataStoreToolbarData {
  ScheduleActionPlans: ScheduleActionPlan[];
  ScheduleActions: ScheduleBaseAction[];
  DataSources: DataSource[];
  Workflows: WorkflowRepositoryEntry[];
  DataStores: DataStore[];
}

export class DataStoreToolbarResult implements ToolbarResult {
  WorkflowNodes: WorkflowNode[];
  Workflows: WorkflowRepositoryEntry[];
  ScheduleActionPlans: ScheduleActionPlan[];
  Matches: boolean[];

  constructor(WorkflowNodes: WorkflowNode[],
    Workflows: WorkflowRepositoryEntry[],
    ScheduleActionPlans: ScheduleActionPlan[],
    Matches: boolean[]) {
    this.Workflows = Workflows;
    this.WorkflowNodes = WorkflowNodes;
    this.ScheduleActionPlans = ScheduleActionPlans;
    this.Matches = Matches;
  }

  DataSources: DataSource[] = [];
  DataStoreOutNodes: WorkflowNode[] = [];
  DataStores: DataStore[] = [];
  PsaInNodes: WorkflowNode[] = [];
  ScheduleActions: ScheduleBaseAction[] = [];
}

export class DestinationBottomToolbar
  implements BottomToolbarProvider<DataStore, DataStore, DataStoreToolbarResult, DataStoreToolbarData> {

  constructor(private bionApi: ApiBackendService, private dataStoresService: CubesService) { }
  getPsaData(obj: DataStore) {

    return this.bionApi.datastoreApiQuery(obj.id).pipe(map(data => {
      const psa_data: PreviewData<DataStore> = { DataTablePreview: data, DataHolder: obj }
      return psa_data;
    }))
  }

  getData(): Observable<DataStoreToolbarData> {

    const datasourcesObs = this.bionApi.getDataSources();
    const schedulesObs = this.bionApi.getScheduleActionPlan();
    const scheduleActionsObs = this.bionApi.getScheduleActions();
    const workflowsObs = this.bionApi.getWorkflowObjectList();
    const dataStoresObs = this.bionApi.getDataStoreObjectList();

    return forkJoin(schedulesObs, scheduleActionsObs, datasourcesObs, workflowsObs, dataStoresObs).pipe(map(all => {
      return { ScheduleActionPlans: all[0], ScheduleActions: all[1], DataSources: all[2], Workflows: all[3], DataStores: all[4] }
    }))
  }
  subscribeToEmitter(): Observable<DataStore> {
    const selectedDsObs = this.dataStoresService.selectedDataStoreEmitter;
    return selectedDsObs
  }
  buildToolbar(data: DataStoreToolbarData, obj?: DataStore) {
    const schedules: ScheduleActionPlan[] = data.ScheduleActionPlans;
    const scheduleActions: ScheduleBaseAction[] = data.ScheduleActions;
    const workflows = data.Workflows;

    let filteredWorkflows: WorkflowRepositoryEntry[] = [];

    for (let workflow of workflows) {
      let wfData = workflow.workflowData;
      let wfNodes: WorkflowNode[] = wfData.Nodes;

      if (wfNodes && wfNodes.length > 0) {
        let filteredNodes = wfNodes.filter((node) => { return node.Name === "DataStoreOutput" });
        for (let node of filteredNodes) {
          let config = <DataStoreOutPlugInSettings>node.Properties.Configuration;
          if (obj) {
            if (config.SelectedDataStore?.id === obj.id) {
              filteredWorkflows.push(workflow);
            }
          } else {
            filteredWorkflows.push(workflow);
          }
        }
      }

    }

    let filteredDS: WorkflowNode[] = [];
    let filteredSchedules: ScheduleActionPlan[] = [];

    for (let wf of filteredWorkflows) {
      let wfNodes = wf.workflowData?.Nodes;
      filteredDS = wfNodes.filter((node) => { return node.Name == "PsaInput" });

      let runActions = <RunWorkflowAction[]>scheduleActions.filter((schedule) => {
        return schedule._type === "models.scheduler.RunWorkflowAction"
      });

      for (let i of runActions) {
        for (let y of schedules) {
          if (i.actionPlan === y.id) {
            filteredSchedules.push(y);
          }
        }
      }

    }

    let statusSuccess: boolean;
    statusSuccess = !(filteredWorkflows.length === 0 && filteredSchedules.length === 0);
    console.log([filteredSchedules, filteredWorkflows, statusSuccess]);

    return new DataStoreToolbarResult(filteredDS, filteredWorkflows, filteredSchedules, [statusSuccess]);
  }

}

export class DestinationRichBottomToolbar
  implements BottomToolbarProvider<DataStore, DataStore, DataStoreToolbarResult, RichDataStoreCake.DataStoreView[]> {

  constructor(private bionApi: ApiBackendService, private dataStoresService: CubesService, private data:RichDataStoreCake.DataStoreView[]) { }
  getPsaData(obj: DataStore) {

    return this.bionApi.datastoreApiQuery(obj.id).pipe(map(data => {
      const psa_data: PreviewData<DataStore> = { DataTablePreview: data, DataHolder: obj }
      return psa_data;
    }))
  }

  getData(): Observable<RichDataStoreCake.DataStoreView[]> {

    return of(this.data);

    throw new Error("Not implemented");

    // const datasourcesObs = this.bionApi.getDataSources();
    // const schedulesObs = this.bionApi.getScheduleActionPlan();
    // const scheduleActionsObs = this.bionApi.getScheduleActions();
    // const workflowsObs = this.bionApi.getWorkflowObjectList();
    // const dataStoresObs = this.bionApi.getDataStoreObjectList();

    // return forkJoin(schedulesObs, scheduleActionsObs, datasourcesObs, workflowsObs, dataStoresObs).pipe(map(all => {
    //   return { ScheduleActionPlans: all[0], ScheduleActions: all[1], DataSources: all[2], Workflows: all[3], DataStores: all[4] }
    // }))
  }
  subscribeToEmitter(): Observable<DataStore> {
    const selectedDsObs = this.dataStoresService.selectedDataStoreEmitter;
    return selectedDsObs
  }
  buildToolbar(data: RichDataStoreCake.DataStoreView[], obj?: DataStore) : DataStoreToolbarResult {

    const target_data = obj ? data.filter(d => d.DataStore.id === obj.id) : data;

    //const filteredSchedules: ScheduleActionPlan[] = Arrays.flatMap(target_data, d => d.ExtractActions.map(a => a.Plan));
    const filteredSchedules: ScheduleActionPlan[] = [];
    const filteredWorkflows: WorkflowRepositoryEntry[] = Arrays.flatMap(target_data, d => d.Workflows);
    const workflow_nodes: WorkflowNode[] = Arrays.flatMap(target_data, d => Arrays.flatMap(d.Workflows, w => w.workflowData.Nodes));
    console.log("WARNING: The new API must still provide the Scheduler Data");
    const statusSuccess = !(filteredWorkflows.length === 0 && filteredSchedules.length === 0);

    const result = new DataStoreToolbarResult(workflow_nodes, filteredWorkflows, filteredSchedules, [statusSuccess]);

    return result;
  }
}