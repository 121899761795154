import { forkJoin, Observable } from 'rxjs';
import * as dss from 'src/app/models/datasource.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { UserService } from 'src/app/services/user.service';
import { ActivityEntry, LatestActivityProvider } from './latest-activity-provider';

export class DataSourceActivity implements LatestActivityProvider<dss.DataSource, [dss.DataPackageProtocolEntry[], dss.DataSourceProtocolEntry[], dss.DataSource[]]> {
    constructor(private bionApi: ApiBackendService, private datasourcesService: DatasourcesService, private userService: UserService) {

    }
    getContextUrl() {
        throw new Error('Method not implemented.');
    }
    getData(): Observable<[dss.DataPackageProtocolEntry[], dss.DataSourceProtocolEntry[], dss.DataSource[]]> {
        const dsDataPackagesObs = this.bionApi.getDataPackageProtocols();
        const dsProtocolObs = this.bionApi.getDataSourceProtocolEntry();
        const datasourcesObs = this.datasourcesService.getDatasources();

        const finalRecordsObs = forkJoin(dsDataPackagesObs, dsProtocolObs, datasourcesObs);

        return finalRecordsObs;
    }
    buildGuI(data: [dss.DataPackageProtocolEntry[], dss.DataSourceProtocolEntry[],
        dss.DataSource[]], select: dss.DataSource): ActivityEntry[] {

        const dataPackages: dss.DataPackageProtocolEntry[] = data[0];
        const dsProtocols: dss.DataSourceProtocolEntry[] = data[1];
        console.log(dataPackages, dsProtocols);
        let aggregatedProtocols: Array<dss.DataPackageProtocolEntry | dss.DataSourceProtocolEntry> = [...dataPackages, ...dsProtocols];

        if (select) {
            aggregatedProtocols = aggregatedProtocols.filter((dsPackage) => {
                return dsPackage.dataSource === select.id
            })
        }

        let sortedProtocols = aggregatedProtocols.sort(
            (objA, objB) => new Date(objB.end).getTime() - new Date(objA.end).getTime()
        );

        let latestProtocol = sortedProtocols;

        return latestProtocol
    }
    subscribeToEmitter(): Observable<dss.DataSource> {
        let selectedDsObs = this.datasourcesService.selectedDatasourceEmitter;
        return selectedDsObs
    }

    selectedObject?: dss.DataSource;

}
