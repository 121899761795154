<p>google-sheets works!</p>
<!-- <label>Client ID</label>
<input type="text" pInputText [(ngModel)]="data.credentials.client_id" />
<label>Client Secret</label>
<input type="text" pInputText [(ngModel)]="data.credentials.client_secret" />
<label>Refresh Token</label>
<input type="text" pInputText [(ngModel)]="data.credentials.refresh_token" />
<label>Sheet Name</label>
<input type="text" pInputText [(ngModel)]="data.spreadsheet_id" /> -->

<div id="connector_settings" class="card">

    <div class="card">
        <label htmlFor="port">Authentication</label>
        <!-- <input pInputText id="port" aria-describedby="port-help" [(ngModel)]="data.port" /> -->
        <p-dropdown [options]="credential_options" [(ngModel)]="credential_options_selected" optionLabel="value"></p-dropdown>
        <small id="port-help">Authentication</small>

        <div [ngSwitch]="credential_options_selected.value">

            <div *ngSwitchCase="'Client'" class="card">

                <div class="field">
                    <label htmlFor="credentials.auth_type">Auth Type</label>
                    <input pInputText id="host" aria-describedby="credentials.auth_type-help"
                        [(ngModel)]="data.credentials.auth_type" [readonly]="true" type="hidden" />
                    <small id="credentials.auth_type-help">Auth Type</small>
                </div>

                <div class="field">
                    <label htmlFor="credentials.client_id">Client ID</label>
                    <input pInputText id="host" aria-describedby="credentials.client_id-help"
                        [(ngModel)]="data.credentials.client_id" />
                    <small id="credentials.client_id-help">Enter your Google application's Client ID.</small>
                </div>

                <div class="field">
                    <label htmlFor="credentials.client_secret">Client Secret</label>
                    <input pInputText id="host" aria-describedby="credentials.client_secret-help"
                        [(ngModel)]="data.credentials.client_secret" />
                    <small id="credentials.client_secret-help">Enter your Google application's Client Secret.</small>
                </div>

                <div class="field">
                    <label htmlFor="credentials.refresh_token">Refresh Token</label>
                    <input pInputText id="host" aria-describedby="credentials.refresh_token-help"
                        [(ngModel)]="data.credentials.refresh_token" />
                    <small id="credentials.refresh_token-help">Enter your Google application's refresh token</small>
                </div>


            </div>

            <div *ngSwitchCase="'Service'" class="card">

                <div class="field">
                    <label htmlFor="credentials.auth_type">Auth Type</label>
                    <input pInputText id="host" aria-describedby="credentials.auth_type-help"
                        [(ngModel)]="data.credentials.auth_type" value="Service" [readonly]="true" type="hidden" />
                    <small id="credentials.auth_type-help">AuthType</small>
                </div>

                <div class="field">
                    <label htmlFor="credentials.service_account_info">Service Account Information.</label>
                    <input pInputText id="host" aria-describedby="credentials.service_account_info-help"
                        [(ngModel)]="data.credentials.service_account_info" />
                    <small id="credentials.service_account_info-help">Enter your Google Cloud <a
                            href="https://cloud.google.com/iam/docs/creating-managing-service-account-keys#creating_service_account_keys">service
                            account key</a> in JSON format</small>
                </div>

            </div>

        </div>

    </div>

    <div class="card">

        <div class="field">
            <label htmlFor="row_batch_size">Row Batch Size</label>
            <input pInputText id="host" aria-describedby="row_batch_size-help" [(ngModel)]="data.row_batch_size" />
            <small id="row_batch_size-help">Row Batch Size.</small>
        </div>

        <div class="field">
            <label htmlFor="spreadsheet_id">Spreadsheet Link</label>
            <input pInputText id="spreadsheet_id" aria-describedby="spreadsheet_id-help"
                [(ngModel)]="data.spreadsheet_id" />
            <small id="spreadsheet_id-help">Spreadsheet Link helptext.</small>
        </div>

        <div class="field">
            <label htmlFor="names_conversion">Columns Name Conversion</label>
            <input pInputText id="names_conversion" aria-describedby="names_conversion-help"
                [(ngModel)]="data.names_conversion" />
            <small id="names_conversion-help">Columns name conversion using a set of rules, for example, 'My Name' ->
                'my-name'..</small>
        </div>

    </div>

</div>