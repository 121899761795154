import { TreeNode } from "primeng/api";
import { Observable, forkJoin, of } from "rxjs";
import { Arrays } from "src/app/helper/arrays";
import { RichDataSourceCake } from "src/app/models/api/controllers/staging/RichDataSourceCake";
import { DataSourceFieldRow, DataSourceRow } from "src/app/models/api/domain/NewDataSourceModelBase";
import { DataSource, DataSourceField, DataTypeInfo, FieldInfo } from "src/app/models/datasource.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { FieldInfoProvider, FieldInfoView } from "./field-info-provider";

export class DataSourceFieldInfoProvider implements FieldInfoProvider<DataSource, DataSourceField, [DataSource[], DataSourceField[], DataTypeInfo[]]> {
    constructor(private bionApi: ApiBackendService, private datasourcesService: DatasourcesService) { }
    objectsToTreeNodes(obj: DataTypeInfo, fields: FieldInfoView<DataSourceField, DataSource>[]): TreeNode<any> {
        // let treeNode: TreeNode = {label: obj.name, data: obj};

        // const relatedFields = fields.filter((field) => field.obj.id === obj.id);
        // const relatedFieldsTreeNodes = relatedFields.map((field) => this.fieldToTreeNode(field)).sort(
        //     (objA, objB) => objA.data.index - objB.data.index
        // );


        // treeNode.children = relatedFieldsTreeNodes;

        let relatedFields = fields.filter((field) => field.fieldInfo.dataType === obj.Name);
        //let dTypes = relatedFields.map((field) => field.fieldInfo.dataType);



        let treeNode: TreeNode = {label: obj.Name, data: obj};

        const relatedFieldsTreeNodes = relatedFields.map((field) => this.fieldToTreeNode(field)).sort(
            (objA, objB) => objA.data.index - objB.data.index
        );


        treeNode.children = relatedFieldsTreeNodes;


        return treeNode
    }
    fieldToTreeNode(field: FieldInfoView<DataSourceField, DataSource>): TreeNode<any> {
        let treeNode: TreeNode = {label:field.fieldInfo.name, data: field};

        return treeNode
    }

    buildGuI(res: [DataSource[], DataSourceField[], DataTypeInfo[]], obj?: DataSource): FieldInfoView<DataSourceField, DataSource>[] {

        const datasources: DataSource[] = res[0];
        const fieldInfos: DataSourceField[] = res[1];
        let finalFieldInfos: FieldInfoView<DataSourceField, DataSource>[] = [];

        for (let field of fieldInfos) {
            const fieldView = new FieldInfoView(field, datasources.find(ds => ds.id === field.dataSource));
            finalFieldInfos.push(fieldView)
        }

        if (obj !== undefined) {
            finalFieldInfos = finalFieldInfos.filter(field => field.fieldInfo.dataSource === obj.id);
        }
        return finalFieldInfos;

    }
    getData(): Observable<[DataSource[], DataSourceField[], DataTypeInfo[]]> {
        let datasourcesObs = this.bionApi.getDataSources();

        let datasourcesFieldInfosObs = this.bionApi.getDataSourceFields();
        let dTypesObs = this.bionApi.getDataTypes();

        let finalObs = forkJoin(datasourcesObs, datasourcesFieldInfosObs,dTypesObs);

        return finalObs;
    }
    subscribeToEvents(): Observable<DataSource> {
        return this.datasourcesService.selectedDatasourceEmitter;
    }
}

export class DataSourceRichFieldInfoProvider implements FieldInfoProvider<DataSource, DataSourceField, [RichDataSourceCake.DataSourceView<any>[], DataTypeInfo[]]> {
    constructor(private data:RichDataSourceCake.DataSourceView<any>[], private dTypes: DataTypeInfo[],private datasourcesService: DatasourcesService) { }
    objectsToTreeNodes(obj: DataSource, fields: FieldInfoView<DataSourceField, DataSource>[]): TreeNode<any> {
        let treeNode: TreeNode = {label: obj.name, data: obj};

        const relatedFields = fields.filter((field) => field.obj?.id === obj.id);
        const relatedFieldsTreeNodes = relatedFields.map((field) => this.fieldToTreeNode(field)).sort(
            (objA, objB) => objA.data.index - objB.data.index
        );


        treeNode.children = relatedFieldsTreeNodes;

        return treeNode
    }
    fieldToTreeNode(field: FieldInfoView<DataSourceField, DataSource>): TreeNode<any> {
        let treeNode: TreeNode = {label:field.fieldInfo.name, data: field};

        return treeNode
    }

    // objectsToTreeNodes(obj: DataSource, fields: FieldInfoView<FieldInfo,DataSource>[]): TreeNode<any> {
    //     let treeNode: TreeNode = {label: obj.name, data: obj};

    //     const relatedFields = fields.filter((field) => field.obj.id === obj.id);
    //     const relatedFieldsTreeNodes = relatedFields.map((field) => this.fieldToTreeNode(field)).sort(
    //         (objA, objB) => objA.data.index - objB.data.index
    //     );


    //     treeNode.children = relatedFieldsTreeNodes;

    //     return treeNode

    // }
    // /**
    //  * macht aus einer BaseAction ein TreeNode
    //  */
    // fieldToTreeNode(field: FieldInfoView<FieldInfo,DataSource>): TreeNode<any> {
    //     let treeNode: TreeNode = {label:field.fieldInfo.Name, data: field};

    //     return treeNode
    // }

    // buildGui2(res: RichDataSourceCake.DataSourceView<any>[], obj?: DataSource): FieldInfoView<DataSourceField, DataSource>[] {
    //     throw new Error("Method not implemented.");
    // }

    /**
     * Hilfsfunktionen, bis das Model grade gezogen ist.
     * @param ds Korrekt DS Model
     * @returns Old DS Model
     */
    static asDs(ds:DataSourceRow) : DataSource {
        return new DataSource(ds.id, ds.name, ds.sourceSystem, ds.age, ds.usePsa, ds.description);
    }

        /**
     * Hilfsfunktionen, bis das Model grade gezogen ist.
     * @param ds Korrekt Field Model
     * @returns Old Field Model
     */
    static asField(f:DataSourceFieldRow) : DataSourceField {
        const ff =  new DataSourceField();

        ff.dataSource = f.dataSource;
        ff.dataType = f.dataType;
        ff.format = f.format;
        ff.id = f.id;
        ff.isFixedSize = f.isFixedSize;
        ff.isKey = f.isKey;
        ff.length = f.length;
        ff.name = f.name;
        ff.position = f.position;
        ff.precision = f.precision

        return ff;
    }

    buildGuI(res: [RichDataSourceCake.DataSourceView<any>[], DataTypeInfo[]], obj?: DataSource): FieldInfoView<DataSourceField, DataSource>[] {
        const target_sources = obj ? res[0].filter(view => view.DataSource.id == obj.id) : res;

        const infos = target_sources
            .map(view => view.Fields
                .map(field => new FieldInfoView(DataSourceRichFieldInfoProvider.asField(field), DataSourceRichFieldInfoProvider.asDs(view.DataSource))));

        return Arrays.flatten(infos);
    }

    getData(): Observable<[RichDataSourceCake.DataSourceView<any>[], DataTypeInfo[]]> {
        return of([this.data, this.dTypes]);
    }
    subscribeToEvents(): Observable<DataSource> {
        return this.datasourcesService.selectedDatasourceEmitter;
    }
}
