import { TreeNode } from "primeng/api";
import { Observable, forkJoin, of } from "rxjs";
import { Arrays } from "src/app/helper/arrays";
import { RichDataStoreCake } from "src/app/models/api/models/staging/RichDataStoreCake";
import { DataTypeInfo } from "src/app/models/datasource.model";
import { DataStore, DataStoreField } from "src/app/models/datastore.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { CubesService } from "src/app/services/cubes.service";
import { FieldInfoProvider, FieldInfoView } from "./field-info-provider";

export class DestinationFieldInfoProvider implements FieldInfoProvider<DataStore, DataStoreField, [DataStore[], DataStoreField[],DataTypeInfo[]]> {
    // OLD NOT USED ANYMORE!!!!
    constructor(private bionApi: ApiBackendService, private datastoresService: CubesService) { }
    objectsToTreeNodes(obj: DataTypeInfo, fields: FieldInfoView<DataStoreField, DataStore>[]): TreeNode<any> {
        // let treeNode: TreeNode = {label: obj.name, data: obj};

        // const relatedFields = fields.filter((field) => field.obj.id === obj.id);
        // const relatedFieldsTreeNodes = relatedFields.map((field) => this.fieldToTreeNode(field)).sort(
        //     (objA, objB) => objA.data.index - objB.data.index
        // );


        // treeNode.children = relatedFieldsTreeNodes;

        let relatedFields = fields.filter((field) => field.fieldInfo.dataType === obj.Name);
        //let dTypes = relatedFields.map((field) => field.fieldInfo.dataType);



        let treeNode: TreeNode = {label: obj.Name, data: obj};

        const relatedFieldsTreeNodes = relatedFields.map((field) => this.fieldToTreeNode(field)).sort(
            (objA, objB) => objA.data.index - objB.data.index
        );


        treeNode.children = relatedFieldsTreeNodes;


        return treeNode
    }
    fieldToTreeNode(field: FieldInfoView<DataStoreField, DataStore>): TreeNode<any> {
        let treeNode: TreeNode = {label:field.fieldInfo.name, data: field};

        return treeNode
    }

    buildGuI(res: [DataStore[], DataStoreField[], DataTypeInfo[]], obj?: DataStore): FieldInfoView<DataStoreField, DataStore>[] {
        const datastores: DataStore[] = res[0];
        const fieldInfos: DataStoreField[] = res[1];
        let finalFieldInfos: FieldInfoView<DataStoreField, DataStore>[] = [];

        for (let field of fieldInfos) {
            const fieldView = new FieldInfoView(field, datastores.find(ds => ds.id === field.dataStore));
            finalFieldInfos.push(fieldView);
        }

        if (obj !== undefined) {
            finalFieldInfos = finalFieldInfos.filter(field => field.fieldInfo.dataStore === obj.id);
        }

        return finalFieldInfos;
    }
    getData(): Observable<[DataStore[], DataStoreField[], DataTypeInfo[]]> {

        let destinationsObs = this.bionApi.getDataStoreObjectList();
        let destinationsFieldInfosObs = this.bionApi.getDataStoreField();
        let dTypesObs = this.bionApi.getDataTypes();

        let finalObs = forkJoin(destinationsObs, destinationsFieldInfosObs,dTypesObs);

        return finalObs;

    }
    subscribeToEvents(): Observable<DataStore> {
        return this.datastoresService.selectedDataStoreEmitter;
    }
}

export class DestinationRichFieldInfoProvider implements FieldInfoProvider<DataStore, DataStoreField, [RichDataStoreCake.DataStoreView[], DataTypeInfo[]]> {

    constructor(private data: RichDataStoreCake.DataStoreView[], private dTypes:DataTypeInfo[], private datastoresService: CubesService) { }
    objectsToTreeNodes(obj: DataTypeInfo, fields: FieldInfoView<DataStoreField, DataStore>[]): TreeNode<any> {
        // let treeNode: TreeNode = {label: obj.name, data: obj};

        // const relatedFields = fields.filter((field) => field.obj.id === obj.id);
        // const relatedFieldsTreeNodes = relatedFields.map((field) => this.fieldToTreeNode(field)).sort(
        //     (objA, objB) => objA.data.index - objB.data.index
        // );


        // treeNode.children = relatedFieldsTreeNodes;

        let relatedFields = fields.filter((field) => field.fieldInfo.dataType === obj.Name);
        //let dTypes = relatedFields.map((field) => field.fieldInfo.dataType);



        let treeNode: TreeNode = {label: obj.Name, data: obj};

        const relatedFieldsTreeNodes = relatedFields.map((field) => this.fieldToTreeNode(field)).sort(
            (objA, objB) => objA.data.index - objB.data.index
        );


        treeNode.children = relatedFieldsTreeNodes;


        return treeNode
    }
    fieldToTreeNode(field: FieldInfoView<DataStoreField, DataStore>): TreeNode<any> {
        let treeNode: TreeNode = {label:field.fieldInfo.name, data: field};

        return treeNode
    }

    buildGuI(data: [RichDataStoreCake.DataStoreView[], DataTypeInfo[]], obj?: DataStore): FieldInfoView<DataStoreField, DataStore>[] {

        const target_sources = obj ? data[0].filter(view => view.DataStore.id == obj.id) : data[0];

        const infos = target_sources
            .map(view => view.Fields
                .map(field => new FieldInfoView(field, view.DataStore)));

        return Arrays.flatten(infos);



        // const datastores: DataStore[] = res[0];
        // const fieldInfos: DataStoreField[] = res[1];

        // let finalFieldInfos: FieldInfoView<DataStoreField, DataStore>[] = [];

        // for (let field of fieldInfos) {
        //     const fieldView = new FieldInfoView(field, datastores.find(ds => ds.id === field.dataStore));
        //     finalFieldInfos.push(fieldView);
        // }

        // if (obj !== undefined) {
        //     finalFieldInfos = finalFieldInfos.filter(field => field.fieldInfo.dataStore === obj.id);
        // }

        // return finalFieldInfos;
    }
    getData(): Observable<[RichDataStoreCake.DataStoreView[], DataTypeInfo[]]> {

        return of([this.data, this.dTypes]);

        // let destinationsObs = this.bionApi.getDataStoreObjectList();
        // let destinationsFieldInfosObs = this.bionApi.getDataStoreField();

        // let finalObs = forkJoin(destinationsObs, destinationsFieldInfosObs);

        // return finalObs;

    }
    subscribeToEvents(): Observable<DataStore> {
        return this.datastoresService.selectedDataStoreEmitter;
    }
}
