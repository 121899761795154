export class PlayException {
	title: string;
	description: string;
	stacktrace: string[];

	static isShape(obj:any): obj is PlayException {
		if(util.isUnset(obj)) return false;

		return util.isSet(obj.title) && util.isSet(obj.description) && util.isSet(obj.stacktrace);
		//return obj.title !== undefined && obj.description !== undefined && obj.stacktrace !== undefined;
	}
}

export class PlayError {
	id: string;
	requestId: number;
	exception: PlayException;

	static isShape(obj:any): obj is PlayError {
		if(util.isUnset(obj)) return false;

		return util.isSet(obj.id) && util.isSet(obj.requestId) && PlayException.isShape(obj.exception);
		//return obj.id !== undefined && obj.requestId !== undefined && PlayException.isShape(obj.exception);
	}
}

export class PlayErrorResponse {
	error: PlayError;
	
	static isShape(obj:any): obj is PlayErrorResponse {
		if(util.isUnset(obj)) return false;

		return PlayError.isShape(obj.error);
	}
}

class util {
	static isUnset(v:any) : boolean {
		return (v === undefined || v == undefined || v == null || v === null);
	}

	static isSet(v:any) : boolean {
		return !this.isUnset(v);
	}
}