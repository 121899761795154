import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SelectItem } from 'primeng/api';
import { IntegratedSourceController } from 'src/app/models/api/controllers/IntegratedSourceController';
import { ConsoleLogger, LogLevel } from 'src/app/views/designer/components/workflow-graph/logger';

interface DropdownChangeEvent<A> {
  originalEvent:Event;
  value:A;
}

@Component({
  selector: 'app-choose-connector-int',
  templateUrl: './choose-connector-int.component.html',
  styleUrls: ['./choose-connector-int.component.scss']
})
export class ChooseConnectorIntComponent implements OnInit {
  sortOptions: SelectItem[] = [];
  sortOrder: number = 1;
  sortField?: string;
  
  @Input() ConnectorInfos: IntegratedSourceController.ConnectorInfo[];
  @Output() onConnectorChanged: EventEmitter<IntegratedSourceController.ConnectorInfo> = new EventEmitter<IntegratedSourceController.ConnectorInfo>();

  private logger = new ConsoleLogger("CreateDatasourceIntComponent", LogLevel.Debug);
  selected_connector_info?: IntegratedSourceController.ConnectorInfo;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }
  getSafeHtml(icon?: string): SafeHtml {
    const fixedWidth= 100;
    const modifiedSvgString = icon.replace(/<svg[^>]*width\s*=\s*["']?\d+["']?/i, `<svg width="100%"`);
    const finalSvgString = modifiedSvgString.replace(/<svg[^>]*height\s*=\s*["']?\d+["']?/i, `<svg height="100%" viewBox="0 0 250 250"`);
    return this.sanitizer.bypassSecurityTrustHtml(finalSvgString);
  }

  onConnChanged(connInfo: IntegratedSourceController.ConnectorInfo) {
    // onChange
    this.logger.debug("On Connector Changed", connInfo);
    console.log("Selected Value", this.selected_connector_info);
    console.log("TODO: Change back to event argument to prevent invalidation error!");

    this.selected_connector_info = connInfo;
    this.onConnectorChanged.emit(connInfo);

    //this.onConnectorChanged.emit(this.selected_connector_info);
  }
}