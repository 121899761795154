import {
	HttpClient,
	HttpEvent,
	HttpHeaders,
	HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "../../environments/environment";
import { ChangeUserPassword, CreateUserAdminArg, SetNewPassword, UserDetailsRow } from "../models/user.model";
import {
	ApiServiceUtils,
	NodeConfigUtil,
	ResultConversionUtil,
} from "./api-util";
import * as dss from "./../models/datasource.model";
import { map } from "rxjs/operators";
import { DataSet } from "../models/datamarket.model";
import { TaskInfo } from "../models/task.model";
import {
	ActionPlanPermission,
	ScheduleActionPlan,
	ScheduleBaseAction,

	TimeStampInfo,
} from "../models/schedule.model";
import {
	ExecuteUpdateSettingsArg,
	UpdateWorkflowSettingsResult,
	WorkflowNodeInfo,
	WorkflowResult,
} from "../models/designer.models";
// import {
// 	EngineInfo,
// 	MetaInfo,
// 	PlayWorkflowPermission,
// 	RunWorkflowArg,
// 	Workflow,
// 	WorkflowNodeSettings,
// 	WorkflowPermission,
// 	WorkflowRepositoryEntry,
// } from "../models/workflow.model";
import {
	DataStore,
	DataStoreField,
	DataStoreTypeInfo,
} from "../models/datastore.model";

import { TokenStorageService } from "./token-storage.service";
import {
	DataTablePreview,
	ExportResult
} from "../models/staging.model";
import {
	AdapterTypeInfo,
	ConnectionSettings,
	ExtractMetaFromConnectorArg,
} from "../models/connector.model";
import { SampleTypeInfo } from "../models/general";

import { GeneralTypeInfo } from "../models/types.model";
// import {
// 	RightHolder,
// 	Accessible,
// 	PermissionLike,
// 	GroupRole,
// 	Permission,
// 	UserRole,
// 	RoleMembership,
// 	ViewInfoRow,
// 	ViewPermission,
// } from "../models/authorization.model";
import { InstallSystemArg, InstallSystemResult, TimeZoneInfo } from "../models/system.model";
import { AuthCodeRequest, UserAzureRow, UserLogIn } from "../models/azure.model";
import { EndpointInfo } from "../models/odata.model";
// import { JobRequestResult, JobStatusInfo } from "../models/staging/LoadDataModel";
import { ObjectProtocolRow } from "./../models/datasource.model";
import cloneDeep from "lodash.clonedeep";
import { WorkflowProtocolEntry } from "../models/api/models/workflow/WorkflowProtocolEntry";
import { DataStoreProtocolEntry } from "../models/api/models/staging/DataStoreProtocolEntry";
import { ActionPlanProtocolEntry } from "../models/api/models/scheduler/ActionPlanProtocolEntry";
import { DataStorePermission } from "../models/api/models/staging/DataStorePermission";
import { FrequencyProtocolEntry } from "../models/api/models/scheduler/FrequencyProtocolEntry";
import { ExtractDataToPsaStream } from "../models/api/models/api/ExtractDataToPsaStream";
import { ExportFormatInfo } from "../models/api/models/ExportModel";
import { DataProviderIdentifier } from "../models/api/models/staging/DataProviderIdentifier";
import { DataProviderInfo } from "../models/api/models/staging/DataProviderInfo";
import { ExportDataConfig } from "../models/api/models/staging/ExportDataConfig";
//import { ExportFormatConfig } from "../models/api/models/staging/ExportFormatConfig";
import { UserInfo } from "../models/api/models/session/UserInfo";
import { UserRow, UserLicStripeRow, ViewInfoRow, RoleMembershipRow } from "../models/api/domain/NewDataSourceModelBase";
import { SubscriptionInfo } from "../models/api/controllers/license/SubscriptionInfo";
import { EngineInfo } from "../models/api/com/bion/etl/EngineInfo";
import { MetaInfo } from "../models/api/com/bion/etl/NodeMetaData";
import { Workflow, WorkflowNodeSettings } from "../models/api/com/bion/etl/Workflow";
import { Accessible } from "../models/api/models/authorization/Accessible";
import { GroupRole } from "../models/api/models/authorization/GroupRoleLike";
import { PermissionLike } from "../models/api/models/authorization/PermissionLike";
import { RightHolder } from "../models/api/models/authorization/RightHolder";
import { UserRole } from "../models/api/models/authorization/UserRoleLIke";
import { ViewPermission } from "../models/api/models/authorization/ViewPermission";
import { PlayWorkflowPermission } from "../models/api/models/workflow/PlayWorkflowPermission";
import { RunWorkflowArg } from "../models/api/models/workflow/RunWorkflowArg";
import { WorkflowPermission } from "../models/api/models/workflow/WorkflowPermission";
import { WorkflowRepositoryEntry } from "../models/api/models/workflow/WorkflowRepositoryEntry";
import { TaskJobModel } from "../models/api/models/staging/TaskJobModel";
import { CronFrequency } from "../models/api/models/scheduler/CronFrequency";
import { SchedulerStatus } from "../models/api/controllers/scheduler/SchedulerStatus";
import { DataSourceEndpointInfo } from "../models/api/odata/DataSourceEndpointInfo";
import { SessionController } from "../models/api/controllers/SessionController";
import { KeyCloakUserController } from "../models/api/controllers/KeyCloakUserController";
import { AuthDummyModel } from "../models/api/models/experimental/AuthDummyModel";
import { DummyAuthController } from "../models/api/controllers/experimental/DummyAuthController";
import { WorkflowController } from "../models/api/controllers/WorkflowController";
import { NewWorkflowResult } from "../models/api/models/workflow/NewWorkflowResult";
import { WorkflowEngineControllers } from "../models/api/controllers/WorkflowEngineController";
import { SettingsResult } from "../models/api/com/bion/etl/SettingsResult";
import { CreatePsaArg } from "../models/api/controllers/api/CreatePsaArg";
import { CreatePsaResult } from "../models/api/models/staging/CreatePsaResult";



@Injectable({
	providedIn: "root",
})
export class ApiBackendService {
	// BASE API URL
	readonly BackEndUrl = environment.API_URL;


	// USER - CRUD
	private userLoginUrl = this.BackEndUrl + "/Session/login";
	private userInfoUrl = this.BackEndUrl + "/Session/userInfo";
	private userRegisterUrl = this.BackEndUrl + "/Session/register";
	private userCheckSessionUrl = this.BackEndUrl + "/Session/checkSession";

	// STA - DataSource
	private dataSourceUrl = this.BackEndUrl + "/Staging/DataSources";
	private dataSourceFieldsUrl = this.BackEndUrl + "/Staging/DataSourceFields";
	// STA - PSA
	private psaQueryUrl = this.BackEndUrl + "/Staging/PSA/query";
	private psaDataSizeUrl = this.BackEndUrl + "/Staging/PSA/count";
	private psaUrl = this.BackEndUrl + "/Staging/PSA";
	private psaDataSourceFieldsUrl =
		this.BackEndUrl + "/Staging/PSA/DataSourceFields";
	private psaInfoFieldsUrl = this.BackEndUrl + "/Staging/PSA/InfoFields";
	// STA - DataPackages
	private dataPackagesUrl = this.BackEndUrl + "/Staging/DataPackages";
	private deleteDataPackageUrl = this.BackEndUrl + "/Staging/DataPackages";
	private DataPackageProtocolUrl =
		this.BackEndUrl + "/Staging/DataPackageProtocol";
	// STA - Connectors
	private dataSourceConnectorsUrl = this.BackEndUrl + "/Staging/Connectors";
	private ConnectorsInfoUrl =
		this.BackEndUrl + "/Staging/DataSourceConnections";
	private businessApiUrl = this.BackEndUrl + "/businessApi/apiCall";
	// DataMarket
	private dataMarketRepoUrl = this.BackEndUrl + "/DataMarketRepository";
	// Workflows
	private workflowRepoUrl = this.BackEndUrl + "/Workflow/WorkflowRepository";
	private workflowRepoObjectUrl =
		this.BackEndUrl + "/Workflow/WorkflowRepository/obj";
	private executeWorkflowUrl =
		this.BackEndUrl + "/Workflow/api/executeWorkflow";
	private executeUpdateSettingsUrl =
		this.BackEndUrl + "/Workflow/api/executeUpdateSettings";
	// DataStore
	private dataStoreRepoUrl = this.BackEndUrl + "/DataWarehouse/DataStore";
	private dataStoreRepoApiUrl =
		this.BackEndUrl + "/DataWarehouse/DataStore/api";
	// PlugIns
	private plugInListUrl = this.BackEndUrl + "/Workflow/PlugIns";
	private dataTypesListUrl = this.BackEndUrl + "/Workflow/DataTypes";
	private workflowDataTypesUrl = this.BackEndUrl + "/Workflow/DataTypes";
	private executePlugInUrl = this.BackEndUrl + "/Workflow/api/executePLugIn";
	// Node Settings
	private getNodeSettingsUrl =
		this.BackEndUrl + "/Workflow/api/getNodeSettings";
	private updateNodeSettingsUrl =
		this.BackEndUrl + "/Workflow/api/updateNodeSettings";

	constructor(
		private http: HttpClient,
		private tokenService: TokenStorageService
	) { }

	//---------------------------------------API UTILITY -------------------------------------------------------------------------------------------------

	public getJson<T>(url: string, args?: Map<string, any>): Observable<T> {
		const _args = args ? args : new Map<string, any>();
		let options = ApiServiceUtils.makeMapOption(_args);
		return this.http.get<T>(url, options);
	}

	public postJson<S, T>(url: string, functionArg: S): Observable<T> {
		let headers = new HttpHeaders();

		headers.append("Content-Type", "application/json");
		headers.append("Access-Control-Allow-Headers", "Content-Type");
		headers.append("Accept", "application/json");

		let options = { headers: headers };

		return this.http.post<T>(url, functionArg, options);
	}

	public putJson<T>(url: string, args: Map<string, any>): Observable<T> {
		let headers = new HttpHeaders();

		headers.append("Content-Type", "application/json");
		headers.append("Access-Control-Allow-Headers", "Content-Type");
		headers.append("Accept", "application/json");

		let options = { headers: headers };

		return this.http.put<T>(url, options);
	}

	public deleteJson<T>(
		url: string,
		args: Map<string, any>
	): Observable<HttpEvent<T>> {
		let options = ApiServiceUtils.makeMapOption(args);
		return this.http.delete<HttpEvent<T>>(url, options);
	}

	//---------------------------------------API System Configuration

	/**
	 * Installs the Systems backend with the given System Admin Credentials.
	 * @param arg Installation Information
	 * @returns The Installation result containing the Admins Name.
	 */
	public installSystem(arg: InstallSystemArg) {
		return this.http.post<InstallSystemResult>(this.BackEndUrl + "/Config/installSystem", arg);
	}

	//---------------------------------------API DATASOURCES -------------------------------------------------------------------------------------------------

	//GET-Service > all Datasources
	public getDataSources(
		id?: number,
		sourceSystem?: number
	): Observable<dss.DataSource[]> {
		return this.getJson(
			this.dataSourceUrl,
			new Map<string, any>([
				["id", id],
				["sourceSystem", sourceSystem],
			])
		);
	}
	//GET-Service > all Datasource Field information
	public getDataSourceFields(
		id?: number,
		dataSource?: number
	): Observable<dss.DataSourceField[]> {
		return this.getJson<dss.DataSourceField[]>(
			this.dataSourceFieldsUrl,
			new Map<string, any>([
				["id", id],
				["dataSource", dataSource],
			])
		);
	}

	//GET-Service > Query Connector list
	public getConnectors(): Observable<dss.DataSourceAdapter[]> {
		return this.http
			.get<dss.DataSourceAdapter[]>(this.dataSourceConnectorsUrl)
			.pipe(map((response) => response));
	}

	public getAdapterTypeInfo(
		id?: number,
		name?: string
	): Observable<AdapterTypeInfo<any, any>[]> {

		let options = ApiServiceUtils.makeOptions({
			param: "id",
			value: id,
		}, {
			param: "name",
			value: name,
		});

		return this.http
			.get<AdapterTypeInfo<any, any>[]>(this.stagingUrl + "/ConnectorInfo", options)
			.pipe(map((response) => response));
	}

	// /api/Staging/Connectors/JDBC/Settings
	readonly ConnectorsJdbcSettingsURL =
		this.BackEndUrl + "/Staging/Connectors/JDBC/Settings";
	public getJdbcConnectionSettings(): Observable<
		SampleTypeInfo<ConnectionSettings>[]
	> {
		return this.http.get<SampleTypeInfo<ConnectionSettings>[]>(
			this.ConnectorsJdbcSettingsURL
		);
	}

	//GET-Service > all Datasource Field information
	public getConnectorsInfo(dataSource?: number): Observable<dss.DataSourceConnector<any>[]> {
		let options = ApiServiceUtils.makeOptions({
			param: "dataSource",
			value: dataSource,
		});
		return this.http.get<dss.DataSourceConnector<any>[]>(this.ConnectorsInfoUrl, options);
	}

	public createConnectorInfo(
		arg: dss.CreateDataSourceConnectorArg
	): Observable<number> {
		//console.log("createConnectorInfo", arg);
		return this.http.post<number>(this.ConnectorsInfoUrl, arg);
		//return this.postJson<dss.CreateDataSourceConnectorArg,number>(this.ConnectorsInfoUrl, arg);
	}

	public deleteConnectorInfo(dataSource: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({
			param: "dataSource",
			value: dataSource,
		});
		return this.http.delete<number>(this.ConnectorsInfoUrl, options);
	}

	public updateCreateDataSourceConnectorArg(item: dss.CreateDataSourceConnectorArg): Observable<number> {
		return this.http.put<number>(this.ConnectorsInfoUrl, item);
	}


	public psaApiCount(dataSource: number) {
		const options = ApiServiceUtils.makeOptions(
			{ param: "dataSource", value: dataSource }
		);

		return this.http.get<number>(this.BackEndUrl + "/Staging/PSA/api/count", options);
	}

	/**
	 * Query Data Store Data.
	 *
	 * This version uses a new Slick based query and is faster than `psaQuery`.
	 * @param dataSource
	 * @param limit
	 * @param offset
	 * @returns
	 */
	public datastoreApiQuery(dataSource: number, limit?: number, offset?: number, useLastDelta: boolean = false,
		includeMetaFields: boolean = false, lastPackageOnly: boolean = false) {
		const options = ApiServiceUtils.makeOptions(
			{ param: "dataStore", value: dataSource },
			{ param: "limit", value: limit },
			{ param: "offset", value: offset },
			{ param: "useLastDelta", value: useLastDelta },
			{ param: "includeMetaFields", value: includeMetaFields },
			{ param: "lastPackageOnly", value: lastPackageOnly }
		);

		return this.http.get<DataTablePreview>(this.BackEndUrl + "/DataWarehouse/DataStore/api/query", options);
	}





	public datastoreApiCount(dataSource: number) {
		const options = ApiServiceUtils.makeOptions(
			{ param: "dataStore", value: dataSource }
		);

		return this.http.get<number>(this.BackEndUrl + "/DataWarehouse/DataStore/api/count", options);
	}

	/**
	 * Query PSA Data.
	 *
	 * This version uses a new Slick based query and is faster than `psaQuery`.
	 * @param dataSource
	 * @param limit
	 * @param offset
	 * @returns
	 */
	public psaApiQuery(dataSource: number, limit: number, offset: number, useLastDelta: Boolean, includeMetaFields: Boolean, lastPackageOnly: Boolean) {
		const options = ApiServiceUtils.makeOptions(
			{ param: "dataSource", value: dataSource },
			{ param: "limit", value: limit },
			{ param: "offset", value: offset },
			{ param: "useLastDelta", value: useLastDelta },
			{ param: "includeMetaFields", value: includeMetaFields },
			{ param: "lastPackageOnly", value: lastPackageOnly }
		);

		return this.http.get<DataTablePreview>(this.BackEndUrl + "/Staging/PSA/api/query", options);
	}

	/**
	 * Query PSA Data. OBSOLETE: please use `psaApiQuery`.
	 * @param dataSource Data Source ID
	 * @param limit Record Limit
	 * @param offset Record start offset
	 */
	public psaQuery(dataSource: number, limit?: number, offset?: number) {
		// create request params
		let params = new HttpParams();
		params = params.set("dataSource", dataSource.toString());

		if (limit !== undefined) {
			params = params.set("limit", limit.toString());
		}
		if (offset !== undefined) {
			params = params.set("offset", offset.toString());
		}

		return this.http.get<dss.DataTypePreview>(this.psaQueryUrl, {
			params: params,
		});
	}

	/**
	 * PSA record count.
	 * @param dataSource Data Source ID
	 * @returns
	 */
	public psaRowCount(dataSource: number) {
		// create request params
		let params = new HttpParams();
		params = params.set("dataSource", dataSource.toString());

		//console.log(params);
		return this.http.get<number>(this.psaDataSizeUrl, { params: params });
	}

	public getPsaOfDataSource(id: number): Observable<dss.PersistentStagingArea> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<dss.PersistentStagingArea>(this.BackEndUrl + "/Staging/PSA/api/getPsaOfDataSource", options);
	}

	public getPsa(id?: number): Observable<dss.PersistentStagingArea[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<dss.PersistentStagingArea[]>(this.psaUrl, options);
		// return this.getJson(this.psaUrl,
		// 	new Map<string, any>([
		// 		["name", name]
		// 	])
		// );
	}
	public updatePsa(psa: dss.PersistentStagingArea): Observable<number> {
		return this.http.put<number>(this.psaUrl, psa);
	}

	public getPsaInfoFields(psa?: string): Observable<dss.PsaInfoFieldsRow[]> {
		const filter = psa === undefined ? "" : `?psa=${psa}`;
		return this.http
			.get<dss.PsaInfoFieldsRow[]>(this.psaInfoFieldsUrl + filter)
			.pipe(map((response) => response));
	}

	public getPsaDataSourceFields(psa?: string): Observable<dss.PsaDsField[]> {
		const filter = psa === undefined ? "" : `?psa=${psa}`;
		return this.http
			.get<dss.PsaDsField[]>(this.psaDataSourceFieldsUrl + filter)
			.pipe(map((response) => response));
	}

	public getDataPackage(dataSource?: number): Observable<dss.DataPackage> {
		const filter = dataSource === undefined ? "" : `?dataSource=${dataSource}`;
		return this.http.get<dss.DataPackage>(`${this.dataPackagesUrl}` + filter);
	}
	public getDataPackageProtocols(psa?: number): Observable<dss.DataPackageProtocolEntry[]> {
		return this.getJson(
			this.DataPackageProtocolUrl,
			new Map<string, any>([["psa", psa]])
		);
	}
	public deleteDataPackage(id: number) {
		return this.http.delete(`${this.deleteDataPackageUrl}/${id}`).toPromise();
	}

	// public extractFromConnector(
	// 	arg: dss.ExtractFromConnectorArg
	// ): Observable<dss.DataTypePreview> {
	// 	let name = "ExtractFromConnector";
	// 	let context = "bion.bw";
	// 	let argument = arg;

	// 	return this.callBusinessFunction(context, name, argument);
	// }

	public getObjectProtocolRow(id?: number): Observable<ObjectProtocolRow[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<ObjectProtocolRow[]>("/api/System/ObjectProtocol", options);
	}

	public extractFromConnector(arg: dss.ExtractFromConnectorArg): Observable<dss.ExtractFromConnectorResult> {
		return this.http.post<dss.ExtractFromConnectorResult>("/api/Staging/Connectors/api/ExtractFromConnector", arg);
	}

	public getDataSourceProtocolEntry(dataSource?: number): Observable<dss.DataSourceProtocolEntry[]> {
		let options = ApiServiceUtils.makeOptions({ param: "dataSource", value: dataSource });
		return this.http.get<dss.DataSourceProtocolEntry[]>("/api/Staging/DataSourceProtocol", options);
	}

	public getWorkflowProtocolEntry(workflow?: number): Observable<WorkflowProtocolEntry[]> {
		let options = ApiServiceUtils.makeOptions({ param: "workflow", value: workflow });
		return this.http.get<WorkflowProtocolEntry[]>("/api/Workflow/WorkflowProtocol", options);
	}

	public getDataStoreProtocolEntry(dataStore?: number): Observable<DataStoreProtocolEntry[]> {
		let options = ApiServiceUtils.makeOptions({ param: "dataStore", value: dataStore });
		return this.http.get<DataStoreProtocolEntry[]>("/api/DataWarehouse/DataStoreProtocol", options);
	}

	public getActionPlanProtocolEntry(actionPlan?: number): Observable<ActionPlanProtocolEntry[]> {
		let options = ApiServiceUtils.makeOptions({ param: "actionPlan", value: actionPlan });
		return this.http.get<ActionPlanProtocolEntry[]>("/api/System/Scheduler/ActionPlanProtocol", options);
	}

	public getFrequencyProtocolEntry(frequency?: number): Observable<FrequencyProtocolEntry[]> {
		let options = ApiServiceUtils.makeOptions({ param: "frequency", value: frequency });
		return this.http.get<FrequencyProtocolEntry[]>("/api/System/Scheduler/FrequencyProtocol", options);
	}



	/**
	 * Extracts meta and navigation information from the Data Source
	 * @param arg Meta Extraction Settings. Usually not needed but added for future enhancements, e.g. JDBC Table Limit
	 *
	 * DEPRECATED!
	 * Use api_extractMetaFromConnector instead!
	 *
	 */
	public ExtractMetaFromConnector(
		arg: ExtractMetaFromConnectorArg
	): Observable<dss.ExtractMetaFromConnectorResult> {
		let name = "ExtractMetaFromConnector";
		let context = "bion.bw";
		let argument = arg;

		return this.callBusinessFunction(context, name, argument);
	}

	// /api/Staging/Connectors/api/ExtractMetaFromConnector

	public api_extractMetaFromConnector(
		arg: ExtractMetaFromConnectorArg
	): Observable<dss.ExtractMetaFromConnectorResult> {
		return this.http.post<dss.ExtractMetaFromConnectorResult>(
			this.dataSourceConnectorsUrl + "/api/ExtractMetaFromConnector",
			arg
		);
	}

	public deleteDataSource(id?: number): Observable<HttpEvent<number>> {
		console.log(id);
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<HttpEvent<number>>(this.dataSourceUrl, options);
	}

	public updateDataSource(dataSource: dss.DataSource) {
		return this.http.put<dss.DataSource>(this.dataSourceUrl, dataSource);
	}

	public createDataSource(dataSource: dss.DataSource) {
		return this.http.post<dss.DataSource>(this.dataSourceUrl, dataSource); // TODO: Test!
	}

	// // ExtractDataToPsaArg
	// public ExtractDataToPsa(
	// 	arg: dss.ExtractDataToPsaArg
	// ): Observable<dss.ExtractDataToPsaResult> {
	// 	let name = "ExtractDataToPsa";
	// 	let context = "bion.bw";
	// 	let argument = arg;

	// 	return this.callBusinessFunction(context, name, argument);
	// }



	/**
	 * Extracts data to the psa asychronously.
	 *
	 * The functions returns the job id. Use it to retrieve the JobStatusInfo from `ExtractDataToPsa_status`.
	 *
	 * When the attribute 'Completed' of JobStatusInfo is true, fetch the result with `ExtractDataToPsa_result`.
	 * @param arg Extract Arguments
	 * @returns
	 */
	public ExtractDataToPsa_async(arg: dss.ExtractDataToPsaArg): Observable<TaskJobModel.JobRequestResult> {
		return this.postJson(
			this.BackEndUrl + "/Staging/api/async/extractDataToPsa",
			arg
		);
	}

	/**
	 * Retrieve the status information of a running ExtractDataToPsa execution.
	 * @param id Job ID
	 * @returns Job status information
	 */
	public ExtractDataToPsa_status(id: string): Observable<TaskJobModel.JobStatusInfo<any>> {
		const url = this.BackEndUrl + "/Staging/api/async/extractDataToPsa/" + id;
		return this.getJson<TaskJobModel.JobStatusInfo<any>>(url, new Map());
	}

	public ExtractDataToPsa_jobs_status(): Observable<TaskJobModel.JobStatusInfo<any>[]> {
		const url = this.BackEndUrl + "/Staging/api/async/jobs";
		return this.getJson<TaskJobModel.JobStatusInfo<any>[]>(url);
	}

	public ExtractDataToPsa_result(id: String): Observable<dss.ExtractDataToPsaResult> {
		const url = this.BackEndUrl + `/Staging/api/async/extractDataToPsa/${id}/result`;
		return this.getJson<dss.ExtractDataToPsaResult>(url);
	}


	// Extract data to PSA stream

	/**
	 * Extracts data to the psa asychronously.
	 *
	 * The functions returns the job id. Use it to retrieve the JobStatusInfo from `ExtractDataToPsa_status`.
	 *
	 * When the attribute 'Completed' of JobStatusInfo is true, fetch the result with `ExtractDataToPsa_result`.
	 * @param arg Extract Arguments
	 * @returns
	 */
	public ExtractDataToPsaStream_async(arg: ExtractDataToPsaStream.Argument): Observable<TaskJobModel.JobRequestResult> {
		return this.postJson(this.BackEndUrl + "/Staging/api/async/extractDataToPsaStream", arg);
	}

	public ExtractDataToPsaStream_status(id: string): Observable<TaskJobModel.JobStatusInfo<any>> {
		const url = this.BackEndUrl + "/Staging/api/async/extractDataToPsaStream/" + id;
		return this.getJson<TaskJobModel.JobStatusInfo<any>>(url, new Map());
	}

	public ExtractDataToPsaStream_jobs_status(): Observable<TaskJobModel.JobStatusInfo<any>[]> {
		const url = this.BackEndUrl + "/Staging/api/async/jobs";
		return this.getJson<TaskJobModel.JobStatusInfo<any>[]>(url);
	}

	public ExtractDataToPsaStream_cancel(id: String): Observable<boolean> {
		const url = this.BackEndUrl + `/Staging/api/async/extractDataToPsaStream/${id}/cancel`;
		return this.getJson<boolean>(url);
	}

	public ExtractDataToPsaStream_result(id: String): Observable<ExtractDataToPsaStream.Result> {
		const url = this.BackEndUrl + `/Staging/api/async/extractDataToPsaStream/${id}/result`;
		return this.getJson<ExtractDataToPsaStream.Result>(url);
	}

	// public getAsyncOperations(): Observable<JobStatusInfo[]> {
	//     throw new Error('Not Implemented');
	// }





	/**
	 * Call a business API function via POST request
	 * @param functionContext The functions context
	 * @param functionName The functions name / identifier
	 * @param functionArg The functions argument object
	 */
	public callBusinessFunction<S, T>(
		functionContext: string,
		functionName: string,
		functionArg: S
	): Observable<T> {
		let headers = new HttpHeaders();

		headers.append("Content-Type", "application/json");
		headers.append("Access-Control-Allow-Headers", "Content-Type");
		headers.append("Accept", "application/json");

		let options = { headers: headers };

		// Prepare API call information
		let businessFunctionArg = new dss.FunctionCall();
		businessFunctionArg.Context = functionContext;
		businessFunctionArg.Name = functionName;
		businessFunctionArg.Argument = functionArg;

		console.log(businessFunctionArg);

		return this.http.post<T>(this.businessApiUrl, businessFunctionArg, options);
	}

	// New API for Create Data Soure Dialog
	readonly PSA_API_URL = "/Staging/PSA/api";
	public api_createPersistentStagingArea(
		arg: CreatePsaArg
	): Observable<CreatePsaResult> {
		return this.postJson<CreatePsaArg, CreatePsaResult>(
			this.BackEndUrl + this.PSA_API_URL + "/createPsa",
			arg
		);
	}

	readonly DATA_SOURCE_FIELDS_API = "/Staging/DataSourceFields/api";
	public api_createDataSourceFields(
		arg: dss.CreateDataSourceFieldsArg
	): Observable<dss.DataSourceField[]> {
		return this.postJson<dss.CreateDataSourceFieldsArg, dss.DataSourceField[]>(
			this.BackEndUrl + this.DATA_SOURCE_FIELDS_API + "/createFields",
			arg
		);
	}
	//------------------------------------ DATATYPES ------------------------------

	public getDataTypes(): Observable<dss.DataTypeInfo[]> {
		return this.http.get<dss.DataTypeInfo[]>(this.workflowDataTypesUrl);
	}

	//------------------------------------ DATAMARKET API ------------------------------

	public getDataMarketRepository(): Observable<DataSet[]> {
		return this.http
			.get(this.dataMarketRepoUrl)
			.pipe(map((response: DataSet[]) => response));
	}

	//------------------------------------ EXPERIMENTAL API ------------------------------

	readonly STAGING_API_URL = "/Staging/api";
	public ExtractDataToPsaNew(
		arg: dss.ExtractDataToPsaArg
	): Observable<dss.ExtractDataToPsaResult> {
		return this.postJson(
			this.BackEndUrl + this.STAGING_API_URL + "/extractDataToPsa",
			arg
		);
	}

	readonly SYSTEM_API_URL = "/System";
	public getTask(id?: number): Observable<TaskInfo[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		// return this.getJson(this.BackEndUrl + this.SYSTEM_API_URL + "/Tasks", options);
		return this.http.get<TaskInfo[]>(
			this.BackEndUrl + this.SYSTEM_API_URL + "/Tasks",
			options
		);
	}

	//------------------------------------ PLUGINS / SETTINGS API -----------------------------------

	public listPlugIns(): Observable<WorkflowNodeInfo[]> {
		return this.http.get<WorkflowNodeInfo[]>(this.plugInListUrl);
	}

	// public importWorkflows(entries): Observable<Array<WorkflowRepositoryEntry>> {
	// 	return this.http.post<Array<WorkflowController.ImportResult<WorkflowRepositoryEntry>>>("/exp/Workflow/api/importWorkflows", entries);
	// }

	/**
	 * Experimental unsecured version with dummy user.
	 * Use for testing only
	 * @param entries 
	 * @returns 
	 */
	public importWorkflowsExp(entries: Array<WorkflowRepositoryEntry>): Observable<Array<WorkflowController.ImportResult<WorkflowRepositoryEntry>>> {
		return this.http.post<Array<WorkflowController.ImportResult<WorkflowRepositoryEntry>>>("/exp/Workflow/api/importWorkflows", entries);
	}

	/**
	 * Imports the given workflows into the system.
	 * @param entries Workflows
	 * @returns 
	 */
	public importWorkflows(entries: Array<WorkflowRepositoryEntry>): Observable<Array<WorkflowController.ImportResult<WorkflowRepositoryEntry>>> {
		return this.http.post<Array<WorkflowController.ImportResult<WorkflowRepositoryEntry>>>("/api/Workflow/api/importWorkflows", entries);
	}




	// ----------------------------------- WORKFLOWS API ---------------------------------------------

	// /**
	//  * Currently the MetaInfo object is delivered as JSON object but defined as TypeScript Map here.
	//  * In the future we need to correct both, replace the delivered JSON with tuple and so for the TypeScript Map.
	//  * @param w
	//  * @returns
	//  */
	// static workflow_object_to_map(w: Workflow): Workflow {
	// 	const clean_nodes = w.Nodes.map((n) => {
	// 		const clean_meta_info = NodeConfigUtil.objectToMap<MetaInfo[]>(
	// 			n.Properties.MetaInfo
	// 		);
	// 		const new_node = { ...n };
	// 		new_node.Properties.MetaInfo = clean_meta_info;
	// 		return new_node;
	// 	});
	// 	w.Nodes = clean_nodes;
	// 	return w;
	// }

	workflow_map_to_object(w: Workflow): Workflow {
		const clean_nodes = w.Nodes.map((n) => {
			const clean_meta_info = NodeConfigUtil.mapToObject<MetaInfo[]>(
				n.Properties.MetaInfo
			);
			const new_node = { ...n };
			new_node.Properties.MetaInfo = clean_meta_info;
			return new_node;
		});
		w.Nodes = clean_nodes;
		return w;
	}

	public getWorkflowObjectList(
		id?: number
	): Observable<WorkflowRepositoryEntry[]> {
		// const headers = ApiServiceUtils.makeAuthHeaders(
		// 	this.tokenService.getToken()
		// );
		const param_options = ApiServiceUtils.makeOptions({
			param: "id",
			value: id,
		});
		const options = { params: param_options.params };
		return this.http
			.get<WorkflowRepositoryEntry[]>(this.workflowRepoObjectUrl, options)
			.pipe(
				map((result) => {
					return result.map((r) => {
						r.workflowData = NodeConfigUtil.workflow_object_to_map(r.workflowData);
						return r;
					});
				})
			);
	}
	public createWorkflowObject(entry: WorkflowRepositoryEntry) {
		const clean_entry = { ...entry };
		const oldWorkflow = cloneDeep(entry.workflowData);
		clean_entry.workflowData = this.workflow_map_to_object(oldWorkflow);

		// const headers = ApiServiceUtils.makeAuthHeaders(
		// 	this.tokenService.getToken()
		// );
		const options = {};

		console.log("Options", options);

		return this.http
			.post<WorkflowRepositoryEntry>(
				this.workflowRepoObjectUrl,
				clean_entry,
				options
			)
			.pipe(
				map((result) => {
					result.workflowData = NodeConfigUtil.workflow_object_to_map(result.workflowData);
					return result;
				})
			);
	}
	public updateWorkflowObject(entry: WorkflowRepositoryEntry) {
		const clean_entry = { ...entry };
		const oldWorkflow = cloneDeep(entry.workflowData);
		clean_entry.workflowData = this.workflow_map_to_object(oldWorkflow);
		return this.http.put<number>(this.workflowRepoObjectUrl, clean_entry)
	}
	public deleteWorkflowObject(id: string) {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		//console.log(id);
		//console.log(options);

		return this.http
			.delete(`${this.workflowRepoObjectUrl}?id=${id}`)
			.toPromise();
	}

	exportWorkflowObjects(arg): Observable<number> {
		console.log("DUMMY API:", arg);
		return of(1)
	}

	// Workflow Auth Permission

	public getWorkflowAuthPermissions(
		id?: number,
		workflow?: number
	): Observable<WorkflowPermission[]> {
		// const headers = ApiServiceUtils.makeAuthHeaders(
		// 	this.tokenService.getToken()
		// );
		const params = ApiServiceUtils.make_params_pure(
			{ param: "id", value: id },
			{ param: "workflow", value: workflow }
		);
		const options = { params: params };

		return this.http.get<WorkflowPermission[]>(
			this.BackEndUrl + "/Workflow/Auth/Permissions",
			options
		);
	}

	public getWorkflowAuthPlayPermissions(
		id?: number,
		workflow?: number
	): Observable<PlayWorkflowPermission[]> {
		// const headers = ApiServiceUtils.makeAuthHeaders(
		// 	this.tokenService.getToken()
		// );
		const params = ApiServiceUtils.make_params_pure(
			{ param: "id", value: id },
			{ param: "workflow", value: workflow }
		);
		const options = { params: params };

		return this.http.get<PlayWorkflowPermission[]>(
			this.BackEndUrl + "/Workflow/Auth/Permissions/Rich",
			options
		);
	}

	public createWorkflowPermission(
		item: WorkflowPermission
	): Observable<WorkflowPermission> {
		// const headers = ApiServiceUtils.makeAuthHeaders(
		// 	this.tokenService.getToken()
		// );
		const options = {};
		return this.http.post<WorkflowPermission>(
			"/api/Workflow/Auth/Permissions",
			item,
			options
		);
	}
	public updateWorkflowPermission(
		item: WorkflowPermission
	): Observable<number> {
		// const headers = ApiServiceUtils.makeAuthHeaders(
		// 	this.tokenService.getToken()
		// );
		const options = {};
		return this.http.put<number>(
			"/api/Workflow/Auth/Permissions",
			item,
			options
		);
	}
	public deleteWorkflowPermission(id: number): Observable<number> {
		// const headers = ApiServiceUtils.makeAuthHeaders(
		// 	this.tokenService.getToken()
		// );
		const params = ApiServiceUtils.make_params_pure({ param: "id", value: id });
		const options = { params: params };
		return this.http.delete<number>("/api/Workflow/Auth/Permissions", options);
	}

	public getWorkflowRights(): Observable<string[]> {
		return this.http.get<string[]>(
			this.BackEndUrl + "/Workflow/Auth/Rights"
		);
	}

	// WORKFLOW API - AUTH
	// GET     /api/Workflow/Auth/Permissions          controllers.WorkflowController.getAuth()

	// def getAuthPermissions : Future[Seq[PermissionLike]] = {
	// 	authModel.getPermissions(None, Some(this.getClass.getName))
	//   }

	//------------------------------------ DESIGNER API ------------------------------------

	public executeWorkflow(workflow: Workflow): Observable<WorkflowResult> {
		return this.postJson<Workflow, WorkflowResult>(
			this.executeWorkflowUrl,
			workflow
		).pipe(
			map((result) => {
				return ResultConversionUtil.convert_workflow_result(result);
			})
		);
	}







	/**
 * Run a full workflow asychronously.
 *
 * The functions returns the job id. Use it to retrieve the JobStatusInfo from `ExtractDataToPsa_status`.
 *
 * When the attribute 'Completed' of JobStatusInfo is true, fetch the result with `ExtractDataToPsa_result`.
 * @param arg Extract Arguments
 * @returns
 */
	public executeWorkflow_async(arg: Workflow): Observable<TaskJobModel.JobRequestResult> {
		return this.postJson(
			this.BackEndUrl + "/Workflow/api/async/executeWorkflow",
			arg
		);
	}

	/**
	 *
	 * @param arg Variante mit erweitertem Argument, z.B. für Simulation.
	 * @returns
	 */
	public executeWorkflowNew_async(arg: RunWorkflowArg): Observable<TaskJobModel.JobRequestResult> {
		return this.postJson(
			this.BackEndUrl + "/Workflow/api/async/executeWorkflowNew",
			arg
		);
	}

	/**
	 * Retrieve the status information of a running Job execution.
	 * @param id Job ID
	 * @returns Job status information
	 */
	public executeWorkflow_status(id: string): Observable<TaskJobModel.JobStatusInfo<any>> {
		const url = this.BackEndUrl + "/Workflow/api/async/executeWorkflow/" + id;
		return this.getJson<TaskJobModel.JobStatusInfo<any>>(url, new Map());
	}

	/**
	 *
	 * @param id
	 * @returns
	 */
	public executeWorkflow_result(id: string): Observable<WorkflowResult> {
		const url = this.BackEndUrl + `/Workflow/api/async/executeWorkflow/${id}/result`;
		return this.getJson<WorkflowResult>(url).pipe(
			map((result) => {
				return ResultConversionUtil.convert_workflow_result(result);
			})
		);
	}


	public exp_executeWorkflow_obj_async(arg: RunWorkflowArg): Observable<TaskJobModel.JobRequestResult> {
		const url = "/exp/Workflow/api/async/executeWorkflowNewObj";
		return this.postJson<RunWorkflowArg, TaskJobModel.JobRequestResult>(url, arg);
	}

		/**
	 * Retrieve the status information of a running Job execution.
	 * @param id Job ID
	 * @returns Job status information
	 */
		public exp_executeWorkflow_obj_status(id: string): Observable<TaskJobModel.JobStatusInfo<WorkflowEngineControllers.RunState<string>>> {
			const url = "/exp/Workflow/api/async/executeWorkflowNewObj/" + id;
			return this.getJson<TaskJobModel.JobStatusInfo<WorkflowEngineControllers.RunState<string>>>(url, new Map());
		}


	/**
	 * Holt das Workflow Ergebnis und konvert es in das neue Objekt mit Error Informationen.
	 * 
	 * Diese Funktion ist experimentell und sollte Teil der API werden sobald sie den QA Prozess durchlaufen hat.
	 * @param id Job ID
	 * @returns Das neue Workflow Result Objekt
	 */
	public exp_executeWorkflow_obj_result(id: string): Observable<NewWorkflowResult.RunResult<WorkflowEngineControllers.NodeRunResult<any>>> {
		const url = `/exp/Workflow/api/async/executeWorkflowNewObj/${id}/result`;
		return this.getJson<NewWorkflowResult.RunResult<WorkflowEngineControllers.NodeRunResult<any>>>(url);
	}



	/**
	* Run a partial workflow asychronously.
	*
	* The functions returns the job id. Use it to retrieve the JobStatusInfo from `ExtractDataToPsa_status`.
	*
	* When the attribute 'Completed' of JobStatusInfo is true, fetch the result with `ExtractDataToPsa_result`.
	* @param arg Extract Arguments
	* @returns
	*/
	public executePartialWorkflow_async(arg: RunWorkflowArg): Observable<TaskJobModel.JobRequestResult> {
		return this.postJson(
			this.BackEndUrl + "/Workflow/api/async/executePartialWorkflow",
			arg
		);
	}

	/**
	 * Retrieve the status information of a running Job execution.
	 * @param id Job ID
	 * @returns Job status information
	 */
	public executePartialWorkflow_status(id: string): Observable<TaskJobModel.JobStatusInfo<any>> {
		const url = this.BackEndUrl + "/Workflow/api/async/executePartialWorkflow/" + id;
		return this.getJson<TaskJobModel.JobStatusInfo<any>>(url, new Map());
	}

	/**
	 *
	 * @param id
	 * @returns
	 */
	public executePartialWorkflow_result(id: String): Observable<WorkflowResult> {
		const url = this.BackEndUrl + `/Workflow/api/async/executePartialWorkflow/${id}/result`;
		return this.getJson<WorkflowResult>(url).pipe(
			map((result) => {
				return ResultConversionUtil.convert_workflow_result(result);
			})
		);
	}




	/**
	 * Executes the workflow to the given End Node.
	 * @param arg Workflow & Run Settings
	 * @returns Workflow Result
	 */
	public executePartialWorkflow(arg: RunWorkflowArg): Observable<WorkflowResult> {
		return this.postJson<RunWorkflowArg, WorkflowResult>(
			this.BackEndUrl + "/Workflow/api/executePartialWorkflow",
			arg
		).pipe(
			map((result) => {
				return ResultConversionUtil.convert_workflow_result(result);
			})
		);
	}

	public executeUpdateSettings(
		arg: ExecuteUpdateSettingsArg
	): Observable<UpdateWorkflowSettingsResult> {
		//console.log("Execute Update Settings Arg BEFORE TRANSFORMATION", arg);
		const new_arg = { ...arg };

		// -- Convert Nodes to correct meta information
		const new_nodes = new_arg.Workflow.Nodes.map((node) => {
			const new_node = { ...node };
			const new_properties = { ...node.Properties };

			const meta_info_object = NodeConfigUtil.mapToObject(
				node.Properties.MetaInfo
			);
			new_properties.MetaInfo = meta_info_object;

			new_node.Properties = new_properties;

			return new_node;
		});

		const new_workflow = { ...new_arg.Workflow };
		new_workflow.Nodes = new_nodes;
		new_arg.Workflow = new_workflow;

		//console.log("ExecuteUpdateSettingsArg AFTER Transformation", new_arg);

		return this.postJson<
			ExecuteUpdateSettingsArg,
			UpdateWorkflowSettingsResult
		>(this.executeUpdateSettingsUrl, new_arg).pipe(
			map((result) => {
				// console.log(
				// 	"UpdateWorkflowSettingsResult BEFORE Transformation",
				// 	result
				// );
				const new_result =
					ResultConversionUtil.otm_update_workflow_settings_result(result);
				console.log(
					"UpdateWorkflowSettingsResult AFTER Transformation",
					new_result
				);
				return new_result;
			})
		);
	}

	/**
	 * Get Initial Node Settings
	 * @param engineInfo Plug In Engine Information
	 * @returns Initial Node Settings
	 */
	public getNodeSettings(
		engineInfo: EngineInfo
	): Observable<SettingsResult<WorkflowNodeSettings, MetaInfo>> {
		return this.postJson<
			EngineInfo,
			SettingsResult<WorkflowNodeSettings, MetaInfo>
		>(this.getNodeSettingsUrl, engineInfo).pipe(
			map((result) => {
				const new_result = { ...result };
				const metaInfoMap = NodeConfigUtil.objectToMap<MetaInfo[]>(
					result.MetaInfo
				);
				new_result.MetaInfo = metaInfoMap;
				return new_result;
			})
		);
	}

	//------------------------------------ DATASTORE API -----------------------------------

	public getDataStoreObjectList(id?: number): Observable<DataStore[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });

		return this.http.get<DataStore[]>(this.dataStoreRepoUrl, options);
	}
	public createDataStoreObject(datastore: DataStore): Observable<DataStore> {
		return this.http.post<DataStore>(this.dataStoreRepoUrl, datastore);
	}
	public updateDataStoreObject(datastore: DataStore) {
		return this.http.put<DataStore>(this.dataStoreRepoUrl, datastore);
	}
	public deleteDataStoreObject(id: number) {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		console.log(id);
		//console.log(options);

		return this.http.delete(`${this.dataStoreRepoUrl}?id=${id}`).toPromise();
	}

	readonly DataStoreFieldRow_URL =
		this.BackEndUrl + "/DataWarehouse/DataStoreField";
	public getDataStoreField(id?: number): Observable<DataStoreField[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<DataStoreField[]>(this.DataStoreFieldRow_URL, options);
	}
	public createDataStoreField(
		item: DataStoreField
	): Observable<DataStoreField[]> {
		return this.http.post<DataStoreField[]>(this.DataStoreFieldRow_URL, item);
	}
	public updateDataStoreField(
		item: DataStoreField
	): Observable<DataStoreField[]> {
		return this.http.put<DataStoreField[]>(this.DataStoreFieldRow_URL, item);
	}
	public deleteDataStoreField(id?: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>(this.DataStoreFieldRow_URL, options);
	}

	readonly DataStoreTypes_URL =
		this.BackEndUrl + "/DataWarehouse/DataStore/StoreTypes";
	public getDataStoreTypes(): Observable<DataStoreTypeInfo[]> {
		return this.http.get<DataStoreTypeInfo[]>(this.DataStoreTypes_URL);
	}

	public queryDataStore(
		dataStore: number,
		limit?: number,
		offset?: number
	): Observable<dss.DataTypePreview> {
		// dataStoreRepoApiUrl
		let options = ApiServiceUtils.makeOptions(
			{ param: "dataStore", value: dataStore },
			{ param: "limit", value: limit },
			{ param: "offset", value: offset }
		);

		return this.http.get<dss.DataTypePreview>(
			this.dataStoreRepoApiUrl + "/queryDataStore",
			options
		);
	}

	// GET     /api/DataWarehouse/DataStore/api/queryDataStoreRecordCount  controllers.DataSourceController.queryDataStoreRecordCount(dataStore:Int)
	public queryDataStoreRecordCount(dataStore: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({
			param: "dataStore",
			value: dataStore,
		});
		return this.http.get<number>(
			this.dataStoreRepoApiUrl + "/queryDataStoreRecordCount",
			options
		);
	}

	// Permissions
	public getDataStorePermission(id?: number, dataStore?: number): Observable<DataStorePermission[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id }, { param: "dataStore", value: dataStore });
		return this.http.get<DataStorePermission[]>("/api/DataWarehouse/DataStore/Auth/Permissions", options);
	}
	public createDataStorePermission(item: DataStorePermission): Observable<DataStorePermission> {
		return this.http.post<DataStorePermission>("/api/DataWarehouse/DataStore/Auth/Permissions", item);
	}
	public updateDataStorePermission(item: DataStorePermission): Observable<number> {
		return this.http.put<number>("/api/DataWarehouse/DataStore/Auth/Permissions", item);
	}
	public deleteDataStorePermission(id?: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>("/api/DataWarehouse/DataStore/Auth/Permissions", options);
	}

	//------------------------------------ SCHEDULE API -----------------------------------
	readonly SCHEDULER_URL = this.BackEndUrl + "/System/Scheduler";

	// New Scheduler Plan API
	readonly ACTION_PLAN_URL = this.SCHEDULER_URL + "/ActionPlans";
	public getScheduleActionPlan(id?: number): Observable<ScheduleActionPlan[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });

		return this.http.get<ScheduleActionPlan[]>(this.ACTION_PLAN_URL, options);
	}
	public createScheduleActionPlan(
		item: ScheduleActionPlan
	): Observable<ScheduleActionPlan> {
		return this.http.post<ScheduleActionPlan>(this.ACTION_PLAN_URL, item);
	}
	public updateScheduleActionPlan(item: ScheduleActionPlan) {
		return this.http.put<ScheduleActionPlan>(this.ACTION_PLAN_URL, item);
	}
	public deleteScheduleActionPlan(id: number): Observable<ScheduleActionPlan> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<ScheduleActionPlan>(this.ACTION_PLAN_URL, options);
	}
	readonly RUN_ACTION_PLAN_URL =
		this.SCHEDULER_URL + "/ActionPlans/api/forceExecution";
	public runScheduleActionPlan(id: number): Observable<ScheduleActionPlan> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<ScheduleActionPlan>(this.RUN_ACTION_PLAN_URL, options);
	}

	// New Scheduler Action API
	readonly ACTIONS_URL = this.SCHEDULER_URL + "/Actions";
	public getScheduleActions(
		id?: number,
		actionPlan?: number
	): Observable<ScheduleBaseAction[]> {
		let options = ApiServiceUtils.makeOptions(
			{ param: "id", value: id },
			{ param: "actionPlan", value: actionPlan }
		);
		return this.http.get<ScheduleBaseAction[]>(this.ACTIONS_URL, options);
	}
	public createScheduleActions(
		item: ScheduleBaseAction
	): Observable<ScheduleBaseAction> {
		return this.http.post<ScheduleBaseAction>(this.ACTIONS_URL, item);
	}
	public updateScheduleActions(item: ScheduleBaseAction) {
		return this.http.put<ScheduleBaseAction>(this.ACTIONS_URL, item);
	}
	public deleteScheduleActions(id: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>(this.ACTIONS_URL, options);
	}
	readonly ACTIONS_BATCH_URL = "/api/System/Scheduler/Actions/api/updateActions";
	public updateActionBatch(items: ScheduleBaseAction[]): Observable<number[]> {
		return this.http.post<number[]>(this.ACTIONS_BATCH_URL, items)
	}

	// New Scheduler Frequency API
	readonly FREQUENCIES_URL = this.SCHEDULER_URL + "/CronFrequencies";
	public getScheduleFrequencies(
		id?: number,
		actionPlan?: number
	): Observable<CronFrequency[]> {
		let options = ApiServiceUtils.makeOptions(
			{ param: "id", value: id },
			{ param: "actionPlan", value: actionPlan }
		);
		return this.http.get<CronFrequency[]>(this.FREQUENCIES_URL, options);
	}
	public createScheduleFrequency(
		item: CronFrequency
	): Observable<CronFrequency> {
		return this.http.post<CronFrequency>(this.FREQUENCIES_URL, item);
	}
	public updateScheduleFrequency(
		item: CronFrequency
	): Observable<CronFrequency> {
		return this.http.put<CronFrequency>(this.FREQUENCIES_URL, item);
	}
	public deleteScheduleFrequency(
		id?: number,
		actionPlan?: number
	): Observable<number> {
		let options = ApiServiceUtils.makeOptions(
			{ param: "id", value: id },
			{ param: "actionPlan", value: actionPlan }
		);
		return this.http.delete<number>(this.FREQUENCIES_URL, options);
	}

	/**
	 * Gets the frequency time stamps in a given interval
	 *
	 *
	 * Note: The parameter sequency was changed due to Typescripts Limitations in optional paramters
	 * which must be the last
	 *
	 * @param end Interval End
	 * @param id  Frequency ID
	 * @param actionPlan Action Plan ID
	 * @param start Interval Start
	 * @returns Valid Time Stamps as ISO 8601 String.
	 */
	public getFrequencyTimeStamps(
		end: string,
		id?: number,
		actionPlan?: number,
		start?: string
	): Observable<TimeStampInfo[]> {
		let options = ApiServiceUtils.makeOptions(
			{ param: "id", value: id },
			{ param: "actionPlan", value: actionPlan },
			{ param: "start", value: start },
			{ param: "end", value: end }
		);

		return this.http.get<TimeStampInfo[]>(
			this.FREQUENCIES_URL + "/api/getTimeStamps",
			options
		);
	}

	/**
	 * Gets the scheduler plan tasks states
	 * @returns Running (or completed) Scheduler Action Plan States
	 */
	public getSchedulerStatus(): Observable<SchedulerStatus> {
		return this.http.get<SchedulerStatus>(this.BackEndUrl + "/System/Scheduler/ActionPlans/api/getStatus");
	}

	//------------------------------------ TIME API ------------------------------------

	public getTimeZones(id?: string): Observable<TimeZoneInfo[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<TimeZoneInfo[]>(
			this.BackEndUrl + "/System/Time/TimeZones",
			options
		);
	}

	public getDefaultTimeZone(): Observable<TimeZoneInfo> {
		return this.http.get<TimeZoneInfo>(
			this.BackEndUrl + "/System/Time/DefaultTimeZone"
		);
	}

	//------------------------------------ LOGIN API -----------------------------------

	/**
	 * Log in a user
	 * @param username Username
	 * @param password Password
	 * @returns Access Token
	 */
	public loginUser(username: string, password: string): Observable<string> {
		let options = ApiServiceUtils.makeOptions(
			{ param: "username", value: username },
			{ param: "password", value: password }
		);

		return this.http.get<string>(this.userLoginUrl, options);
	}

	/**
	 * Log in a user via POST
	 * @param arg Credentials
	 * @returns Access Token
	 */
	public loginUserPost(arg: SessionController.LogInArg): Observable<string> {
		return this.http.post<string>("/api/Session/login", arg);
	}

	// get userProfileInfo (such as avatar, name etc.)
	public getUserInfo(): Observable<UserInfo> {
		//const token = this.tokenService.getToken();
		//const headers = ApiServiceUtils.makeAuthHeaders(token);
		const options = {};

		// console.log(token);

		return this.http.get<UserInfo>(this.userInfoUrl, options);
	}

	public getUserInfoPure(): Observable<UserInfo> {
		return this.http.get<UserInfo>(this.userInfoUrl);
	}

	// register a new user
	public registerUser(username: string, password: string) {
		let options = ApiServiceUtils.makeOptions(
			{ param: "username", value: username },
			{ param: "password", value: password }
		);

		//console.log("ApiBackend: ", options);
		return this.http.get<UserInfo>(this.userRegisterUrl, options);
	}

	//invite a user
	public inviteUser(email: string) {
		return email;
	}

	// check if session is valid/active or not
	public checkUserSession(token: string) {
		let options = ApiServiceUtils.makeOptions({ param: "token", value: token });

		return this.http.get<boolean>(this.userCheckSessionUrl, options);
	}

	// LOGIN - Key Cloak

	public keyCloakAuth(arg: KeyCloakUserController.LogInArg): Observable<string> {
		return this.http.post<string>(this.BackEndUrl + "/Session/KeyCloak/auth", arg);
	}

	public keyCloakAuthOrRegister(item: KeyCloakUserController.AuthOrRegisterArg): Observable<string> {
		return this.http.post<string>(this.BackEndUrl + "/Session/KeyCloak/authOrRegister", item);
	}

	// Key Cloak Experimental API

	public kc_registerUser(arg: AuthDummyModel.RegisterArg): Observable<AuthDummyModel.RegisterResult> {
		return this.http.post<AuthDummyModel.RegisterResult>(this.BackEndUrl + "/experimental/auth/api/registerUser", arg);
	}

	public kc_getUsers(id?: number): Observable<DummyAuthController.UserDetailInfo[]> {
		const options = ApiServiceUtils.makeOptions({ param: "userId", value: id });
		return this.http.get<DummyAuthController.UserDetailInfo[]>(this.BackEndUrl + "/experimental/auth/api/users", options);
	}

	public kc_getUser(id: number): Observable<DummyAuthController.UserDetailInfo> {
		const options = ApiServiceUtils.makeOptions({ param: "userId", value: id });
		return this.http.get<DummyAuthController.UserDetailInfo>(this.BackEndUrl + "/experimental/auth/api/user", options);
	}

	public test_be_api_token(headers: HttpHeaders) {
		const options = {
			headers: headers
		}
		return this.http.get<WorkflowNodeInfo[]>(this.BackEndUrl + "/Workflow/PlugIns", options)
	}


	// USERS -------------------------------------------------------------------------------------------------

	readonly UserRow_URL = this.BackEndUrl + "/System/User";

	/**
	 * Vereinigung aller User-Typen, Basic & Keycloak.
	 * @param id
	 * @returns
	 */
	public getUserCommon(id?: number): Observable<UserInfo[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<UserInfo[]>(this.BackEndUrl + "/System/User/Common", options);
	}

	public getUser(id?: number): Observable<UserInfo[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<UserInfo[]>(this.UserRow_URL, options);
	}
	public createUser(item: UserRow): Observable<UserInfo> {
		return this.http.post<UserInfo>(this.UserRow_URL, item);
	}
	public updateUser(item: UserInfo): Observable<number> {
		// // let token = this.tokenService.getUser();
		// const token = this.tokenService.getToken();
		// //console.log(token);
		// // add auth token to header

		// const headers = new HttpHeaders({
		// 	"Content-Type": "application/json",
		// 	TokenAuthorization: token,
		// });

		// const headers = ApiServiceUtils.makeAuthHeaders(
		// 	this.tokenService.getToken()
		// );
		const options = {};

		return this.http.put<number>(this.UserRow_URL, item, options);
	}
	public changeUserPassword(item: UserRow): Observable<UserInfo> {
		// let token = this.tokenService.getUser();
		// //console.log(token);
		// // add auth token to header

		// const headers = new HttpHeaders({
		// 	"Content-Type": "application/json",
		// 	TokenAuthorization: token,
		// });

		// const headers = ApiServiceUtils.makeAuthHeaders(
		// 	this.tokenService.getToken()
		// );
		const options = {};

		return this.http.put<UserInfo>(this.UserRow_URL, item, options);
	}

	public deleteUser(id: number): Observable<number> {
		let token = this.tokenService.getToken();
		//console.log(token);
		// // add auth token to header
		// const httpOptions = {
		//     headers: new HttpHeaders({
		//         'Content-Type': 'application/json',
		//         'TokenAuthorization': token
		//     })
		// };

		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			TokenAuthorization: token,
		});

		// let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		let params = ApiServiceUtils.make_params_pure({ param: "id", value: id });
		const options = { params: params, headers: headers };
		return this.http.delete<number>(this.UserRow_URL, options);
	}

	/**
	 * Create a user from outside with the given admin credentials.
	 * @param arg Argument
	 * @returns The new created user information
	 */
	public createUserAsAdmin(arg: CreateUserAdminArg): Observable<UserInfo[]> {
		return this.http.post<UserInfo[]>(this.BackEndUrl + "/System/User/api/createUserAsAdmin", arg);
	}

	// USER DETAILS
	public getUserDetailsRow(id?: number): Observable<UserDetailsRow[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<UserDetailsRow[]>(this.BackEndUrl + "/System/UserDetails", options);
	}
	public createUserDetailsRow(item: UserDetailsRow): Observable<UserDetailsRow> {
		return this.http.post<UserDetailsRow>(this.BackEndUrl + "/System/UserDetails", item);
	}
	public updateUserDetailsRow(item: UserDetailsRow): Observable<number> {
		return this.http.put<number>(this.BackEndUrl + "/System/UserDetails", item);
	}
	public deleteUserDetailsRow(id: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>(this.BackEndUrl + "/System/UserDetails", options);
	}


	/**
	 * Get Users even if you don't have permission to the user view
	 * @param id optional user id filter
	 */
	getAuthUsers(id?: number) {
		// /api/Authorization/Users
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<UserInfo[]>(this.BackEndUrl + "/api/Authorization/Users", options);
	}

	readonly UserRowApi_URL = this.UserRow_URL + "/api/changePassword";
	public changePassword(item: ChangeUserPassword): Observable<number> {
		// // let token = this.tokenService.getUser();
		// const token = this.tokenService.getToken();
		// //console.log(token);

		// const headers = new HttpHeaders({
		// 	"Content-Type": "application/json",
		// 	TokenAuthorization: token,
		// });

		// const headers = ApiServiceUtils.makeAuthHeaders(
		// 	this.tokenService.getToken()
		// );
		const options = {};

		return this.http.post<number>(this.UserRowApi_URL, item, options);

	}

	/**
	 * Sends a Passwort Reset request to the given E-Mail adress.
	 * @param to User E-Mail which receives the token
	 * @param link Page URL which receives the token as GET Parameter
	 * @param tokenName Name of the Paramter which contains the token
	 * @returns E-Mail Message ID
	 */
	resetPassword(to: String, link: String, tokenName: String): Observable<string> {
		const options = ApiServiceUtils.makeOptions(
			{ param: "to", value: to },
			{ param: "link", value: link },
			{ param: "tokenName", value: tokenName });
		return this.http.get<string>("/api/System/User/api/resetPassword", options);
	}

	/**
	 * Sets the new password.
	 *
	 * The token must be provided by the user who receive the mail from resetPassword via GET Paramter in the target page.
	 * @param arg Set Passwort Parameters.
	 * @returns 1 if the password was changed.
	 */
	setNewPassword(arg: SetNewPassword): Observable<number> {
		return this.http.post<number>("/api/System/User/api/setNewPassword", arg);
	}

	// AZURE  ----------------------------------------------------------------------------------------------

	// -- Azure Sesssion
	public azureLogin(username: string, password: string): Observable<string> {
		const arg = new UserLogIn(username, password);
		return this.http.post<string>("/api/Session/Azure/login", arg);
	}

	public azureLoginWithCode(code: AuthCodeRequest): Observable<string> {
		return this.http.post<string>("/api/Session/Azure/loginWithCode", code);
	}

	public azurLoginTest(username: string, password: string): Observable<string> {
		const arg = new UserLogIn(username, password);
		return this.http.post<string>("/api/Session/Azure/loginTest", arg);
	}

	public azureGetUserToken(username: string, password: string): Observable<string[]> {
		const arg = new UserLogIn(username, password);
		return this.http.post<string[]>("/api/Session/Azure/getUserToken", arg);
	}

	// -- Azure User
	public getUserAzureRow(
		userId?: number,
		accountName?: string
	): Observable<UserAzureRow[]> {
		let options = ApiServiceUtils.makeOptions(
			{ param: "userId", value: userId },
			{ param: "accountName", value: accountName }
		);

		return this.http.get<UserAzureRow[]>("/api/System/Azure/User", options);
	}
	public createUserAzureRow(item: UserAzureRow): Observable<UserAzureRow> {
		return this.http.post<UserAzureRow>("/api/System/Azure/User", item);
	}
	public updateUserAzureRow(item: UserAzureRow): Observable<number> {
		return this.http.put<number>("/api/System/Azure/User", item);
	}
	public deleteUserAzureRow(): Observable<number> {
		let options = ApiServiceUtils.makeOptions();
		return this.http.delete<number>("/api/System/Azure/User", options);
	}

	// EXPORT ----------------------------------------------------------------------------------------------

	readonly stagingUrl = this.BackEndUrl + "/Staging";
	readonly stagingExportUrl = this.stagingUrl + "/Export";

	/**
	 * Gets all Available Export Formats.
	 * @returns Available Export Formats
	 */
	public getFormats(): Observable<ExportFormatInfo<any>[]> {
		return this.http.get<ExportFormatInfo<any>[]>(
			this.stagingExportUrl + "/Formats"
		);
	}

	/**
	 * Available Data Providers for export, e.g. a Data Store.
	 * @returns Available Data Providers
	 */
	public getDataProviders(): Observable<
		DataProviderInfo<DataProviderIdentifier>[]
	> {
		return this.http.get<DataProviderInfo<DataProviderIdentifier>[]>(
			this.stagingUrl + "/DataProviders"
		);
	}

	/**
	 * Exports the given Data Providers as the given file format.
	 * @param arg Export Data Configuration
	 * @returns Base64 encoded file
	 */
	public exportDataProvider<T extends DataProviderIdentifier>(arg: ExportDataConfig<T, any>): Observable<ExportResult> {
		return this.http.post<ExportResult>(this.stagingExportUrl + "/api/exportDataProvider", arg);
	}

	/**
	 * Exports the given Data Provider as the given file format as chunked response.
	 * The response is a byte stream, usable via Uint8Array class.
	 * @param arg Export Data Configuration
	 * @returns A byte array encoded as 8-Bit unsigned integer.
	 */
	public exportDataProviderStream<T extends DataProviderIdentifier>(arg: ExportDataConfig<T, any>): Observable<Array<number>> {
		return this.http.post<Array<number>>(this.stagingExportUrl + "/api/exportDataProviderStream", arg);
	}

	/**
	 * Stream mit Events
	 * @param arg
	 * @returns
	 */
	public exportDataProvChunkedStream<T extends DataProviderIdentifier>(arg: ExportDataConfig<T, any>) {

		// https://itecnote.com/tecnote/angular-how-to-use-reportprogress-in-httpclient-in-angular/

		// https://github.com/angular/angular/issues/18586

		const url: string = this.stagingExportUrl + "/api/exportDataProviderStreamChunked";

		const result = this.http.post(url, arg, {
			reportProgress: true,
			observe: 'events',
			responseType: 'blob'
		});

		return result;

		//return this.http.post(url, arg, options);
	}

	/**
	 * Test mit Body
	 * @param arg
	 * @returns
	 */
	public exportDataProvChunkedStreamBody<T extends DataProviderIdentifier>(arg: ExportDataConfig<T, any>) {

		// https://itecnote.com/tecnote/angular-how-to-use-reportprogress-in-httpclient-in-angular/

		// https://github.com/angular/angular/issues/18586

		// https://stackoverflow.com/questions/36721600/angularjs-byte-array-to-blob
		// https://blog.bitsrc.io/implement-concurrent-download-of-large-files-in-javascript-4e94202c5373

		const url: string = this.stagingExportUrl + "/api/exportDataProviderStreamChunked";

		const result = this.http.post(url, arg, {
			reportProgress: true,
			observe: 'body',
			responseType: 'blob'
		});

		return result;

		//return this.http.post(url, arg, options);
	}

	// New Task Manager
	readonly newTaskManagerUrl = this.BackEndUrl + "/NewTaskManager";

	public getTasks(id?: number): Observable<TaskInfo[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<TaskInfo[]>(
			this.newTaskManagerUrl + "/getTasks",
			options
		);
	}

	/**
	 * Remove the Task and force shutdown if possible
	 * @param id Task ID
	 * @returns Removed Task ID
	 */
	public killTask(id: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<number>(this.newTaskManagerUrl + "/killTask", options);
	}

	/**
	 * Clear all finished Tasks
	 * @param keepFailed Keep the failed finished Tasks
	 * @returns IDs of cleared Tasks
	 */
	public clearTasks(keepFailed: boolean): Observable<number[]> {
		let options = ApiServiceUtils.makeOptions({
			param: "keepFailed",
			value: keepFailed,
		});
		return this.http.get<number[]>(
			this.newTaskManagerUrl + "/clearTasks",
			options
		);
	}

	// == AUTHORIZATION (OLD)

	readonly Auth_URL = this.BackEndUrl + "/Authorization";

	// = Views
	public getViewInfoRow(name?: string): Observable<ViewInfoRow[]> {
		const options = ApiServiceUtils.makeOptions({ param: "name", value: name });
		return this.http.get<ViewInfoRow[]>("/api/Authorization/Views", options);
	}

	// = View Permissions
	public getViewPermission(id?: number, name?: string): Observable<ViewPermission[]> {
		const options = ApiServiceUtils.makeOptions({ param: "id", value: id }, { param: "name", value: name });
		return this.http.get<ViewPermission[]>("/api/Authorization/Views/Permissions", options);
	}
	public createViewPermission(item: ViewPermission): Observable<ViewPermission> {
		return this.http.post<ViewPermission>("/api/Authorization/Views/Permissions", item);
	}
	public updateViewPermission(item: ViewPermission): Observable<number> {
		return this.http.put<number>("/api/Authorization/Views/Permissions", item);
	}
	public deleteViewPermission(id: number): Observable<number> {
		const options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>("/api/Authorization/Views/Permissions", options);
	}


	// = Right Holders

	public getRightHolders(name?: string): Observable<RightHolder[]> {
		let options = ApiServiceUtils.makeOptions({ param: "name", value: name });
		return this.http.get<RightHolder[]>(
			this.Auth_URL + "/RightHolders",
			options
		);
	}
	public getRightHolderTypes(): Observable<GeneralTypeInfo<string>[]> {
		return this.http.get<GeneralTypeInfo<string>[]>(
			this.Auth_URL + "/RightHolderTypes"
		);
	}

	public getAccessibles(name?: string): Observable<Accessible[]> {
		let options = ApiServiceUtils.makeOptions({ param: "name", value: name });
		return this.http.get<Accessible[]>(this.Auth_URL + "/Accessibles", options);
	}

	public getPermissions(name?: string): Observable<PermissionLike[]> {
		let options = ApiServiceUtils.makeOptions({ param: "name", value: name });
		return this.http.get<PermissionLike[]>(
			this.Auth_URL + "/Permissions",
			options
		);
	}

	public getPermissionLevels(): Observable<string[]> {
		return this.http.get<string[]>(this.Auth_URL + "/PermissionLevels");
	}

	// Authorization NEW

	readonly ROLES_URL = this.BackEndUrl + "/Authorization/Roles";
	// GENERIC ROLE-----------------------------------------------
	deleteRole(id: number): Observable<number> {
		console.log(id);
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>(this.ROLES_URL, options);
	}

	readonly USER_ROLES_URL =
		this.BackEndUrl + "/Authorization/RightHolders/UserRoles";

	// USER ROLE--------------------------------------------------
	public getUserRoles(id?: number, userId?: number): Observable<UserRole[]> {
		let options = ApiServiceUtils.makeOptions(
			{ param: "id", value: id },
			{ param: "userId", value: userId }
		);
		return this.http.get<UserRole[]>(this.USER_ROLES_URL, options);
	}
	public createUserRole(role: UserRole): Observable<UserRole> {
		return this.http.post<UserRole>(this.USER_ROLES_URL, role);
	}
	public updateUserRole(role: UserRole): Observable<number> {
		return this.http.put<number>(this.USER_ROLES_URL, role);
	}
	public deleteUserRole(id?: number): Observable<number> {
		console.log(id);
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>(this.USER_ROLES_URL, options);
	}

	readonly GROUP_ROLES_URL =
		this.BackEndUrl + "/Authorization/RightHolders/GroupRoles";

	// GROUP ROLE--------------------------------------------------
	public getGroupRoles(id?: number): Observable<GroupRole[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<GroupRole[]>(this.GROUP_ROLES_URL, options);
	}
	public createGroupRole(role: GroupRole): Observable<GroupRole> {
		return this.http.post<GroupRole>(this.GROUP_ROLES_URL, role);
	}
	public updateGroupRole(role: GroupRole): Observable<number> {
		return this.http.put<number>(this.GROUP_ROLES_URL, role);
	}
	public deleteGroupRole(id?: number): Observable<number> {
		console.log(id);
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>(this.GROUP_ROLES_URL, options);
	}

	// USER MEMBERSHIP
	public getRoleMembership(childRole?: number): Observable<RoleMembershipRow[]> {
		let options = ApiServiceUtils.makeOptions({
			param: "childRole",
			value: childRole,
		});
		return this.http.get<RoleMembershipRow[]>(
			"/api/Authorization/RightHolders/UserGroups",
			options
		);
	}
	public createRoleMembership(
		item: RoleMembershipRow
	): Observable<RoleMembershipRow> {
		return this.http.post<RoleMembershipRow>(
			"/api/Authorization/RightHolders/UserGroups",
			item
		);
	}
	public updateRoleMembership(item: RoleMembershipRow): Observable<number> {
		return this.http.put<number>(
			"/api/Authorization/RightHolders/UserGroups",
			item
		);
	}
	public deleteRoleMembership(childRole: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({
			param: "childRole",
			value: childRole,
		});
		return this.http.delete<number>(
			"/api/Authorization/RightHolders/UserGroups",
			options
		);
	}

	public createRoleMembershipUtil(
		items: RoleMembershipRow[]
	): Observable<RoleMembershipRow[]> {
		return this.http.post<RoleMembershipRow[]>(
			"/api/Authorization/RightHolders/UserGroups/api/createRoleMemberships",
			items
		);
	}

	// Data Source Auth
	public getDataSourcePermission(id?: number, ds_id?: number): Observable<dss.DataSourcePermission[]> {
		const params = ApiServiceUtils.make_params_pure(
			{ param: "id", value: id },
			{ param: "dataSource", value: ds_id }
		);
		const options = { params: params };

		return this.http.get<dss.DataSourcePermission[]>("/api/Staging/DataSources/Auth/Permissions", options);
	}
	public createDataSourcePermission(item: dss.DataSourcePermission): Observable<dss.DataSourcePermission> {
		return this.http.post<dss.DataSourcePermission>("/api/Staging/DataSources/Auth/Permissions", item);
	}
	public updateDataSourcePermission(item: dss.DataSourcePermission): Observable<number> {
		return this.http.put<number>("/api/Staging/DataSources/Auth/Permissions", item);
	}
	public deleteDataSourcePermission(id: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>("/api/Staging/DataSources/Auth/Permissions", options);
	}


	public getActionPlanPermission(id?: number, planId?: number): Observable<ActionPlanPermission[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id }, { param: "planId", value: planId });
		return this.http.get<ActionPlanPermission[]>(this.BackEndUrl + "/System/Scheduler/ActionPlans/Auth/Permissions", options);
	}
	public createActionPlanPermission(item: ActionPlanPermission): Observable<ActionPlanPermission> {
		return this.http.post<ActionPlanPermission>(this.BackEndUrl + "/System/Scheduler/ActionPlans/Auth/Permissions", item);
	}
	public updateActionPlanPermission(item: ActionPlanPermission): Observable<number> {
		return this.http.put<number>(this.BackEndUrl + "/System/Scheduler/ActionPlans/Auth/Permissions", item);
	}
	public deleteActionPlanPermission(id: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>(this.BackEndUrl + "/System/Scheduler/ActionPlans/Auth/Permissions", options);
	}


	// == License

	public getSubscriptionInfo(): Observable<SubscriptionInfo[]> {
		let options = ApiServiceUtils.makeOptions();
		return this.http.get<SubscriptionInfo[]>(this.BackEndUrl + "/License/Subscription", options);
	}

	public getStribeSubscriptionInfo(): Observable<SubscriptionInfo[]> {
		let options = ApiServiceUtils.makeOptions();
		return this.http.get<SubscriptionInfo[]>(this.BackEndUrl + "/License/Stripe/Subscription ", options);
	}

	// == STRIPE API

	public getUserLicStripeRow(id?: number): Observable<UserLicStripeRow[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<UserLicStripeRow[]>("/api/Administration/License/Stripe/UserCustomer", options);
	}
	public createUserLicStripeRow(item: UserLicStripeRow): Observable<UserLicStripeRow> {
		return this.http.post<UserLicStripeRow>("/api/Administration/License/Stripe/UserCustomer", item);
	}
	public updateUserLicStripeRow(item: UserLicStripeRow): Observable<number> {
		return this.http.put<number>("/api/Administration/License/Stripe/UserCustomer", item);
	}
	public upsertUserLicStripeRow(item: UserLicStripeRow): Observable<number> {
		return this.http.patch<number>("/api/Administration/License/Stripe/UserCustomer", item);
	}
	public deleteUserLicStripeRow(id: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>("/api/Administration/License/Stripe/UserCustomer", options);
	}




	// == OData

	/**
	 * Returns the Data Source OData Endpoint Information.
	 */
	public getDataSourceEndpointInfo(id?: number): Observable<DataSourceEndpointInfo[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<DataSourceEndpointInfo[]>(this.BackEndUrl + "/Data/Endpoints/DataSources", options);
	}

	/**
	 * Returns the Data Store OData Endpoint Information.
	 */
	public getDataStoreEndpointInfo(id?: number): Observable<EndpointInfo[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<EndpointInfo[]>(this.BackEndUrl + "/Data/Endpoints/DataStores", options);
	}


	/**
	 * TEST FUNCTION
	 *
	 * Creates an Extract-Data-Source-Task.
	 * @param dataSource Data Source ID
	 * @returns Dummy value, always 0
	 */
	public createExtractTask(dataSource: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({
			param: "dataSource",
			value: dataSource,
		});
		return this.http.get<number>(
			this.newTaskManagerUrl + "/createExtractTask",
			options
		);
	}
}
