import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
	HttpClient,
	HttpClientModule,

} from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";

import { ReactiveFormsModule } from "@angular/forms";
import { CaptchaModule } from 'primeng/captcha';
import { DatePipe } from "@angular/common";




import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { BadgeModule } from "primeng/badge";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipsModule } from "primeng/chips";
import { ChipModule } from "primeng/chip";
import { CodeHighlighterModule } from "primeng/codehighlighter";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ColorPickerModule } from "primeng/colorpicker";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DragDropModule } from "primeng/dragdrop";
import { DropdownModule } from "primeng/dropdown";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";

import { GalleriaModule } from "primeng/galleria";
import { InplaceModule } from "primeng/inplace";
import { InputNumberModule } from "primeng/inputnumber";
import { InputMaskModule } from "primeng/inputmask";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { KnobModule } from "primeng/knob";
import { LightboxModule } from "primeng/lightbox";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ScrollTopModule } from "primeng/scrolltop";
import { SelectButtonModule } from "primeng/selectbutton";
import { SkeletonModule } from "primeng/skeleton";
import { SidebarModule } from "primeng/sidebar";
import { SlideMenuModule } from "primeng/slidemenu";
import { SliderModule } from "primeng/slider";
import { SplitButtonModule } from "primeng/splitbutton";
import { SplitterModule } from "primeng/splitter";
import { StepsModule } from "primeng/steps";
import { TabMenuModule } from "primeng/tabmenu";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { TerminalModule } from "primeng/terminal";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TimelineModule } from "primeng/timeline";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeTableModule } from "primeng/treetable";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { DockModule } from "primeng/dock";
import { SpeedDialModule } from "primeng/speeddial";
import { EditorModule } from "primeng/editor";
import { ImageModule } from 'primeng/image';

// JSON Schema Form Generation
import { CommonModule } from '@angular/common';

import { AppCodeModule } from "./app.code.component";
import { AppComponent } from "./app.component";
import { AppMainComponent } from "./app.main.component";
import { AppConfigComponent } from "./app.config.component";
import { AppRightpanelComponent } from "./app.rightpanel.component";
import { AppMenuComponent } from "./app.menu.component";
import { AppMenuitemComponent } from "./app.menuitem.component";
import { AppTopBarComponent } from "./app.topbar.component";
import { AppFooterComponent } from "./app.footer.component";
//import { DashboardDemoComponent } from "./demo/view/dashboarddemo.component";
//import { FormLayoutDemoComponent } from "./demo/view/formlayoutdemo.component";
// import { FloatLabelDemoComponent } from "./demo/view/floatlabeldemo.component";
// import { InvalidStateDemoComponent } from "./demo/view/invalidstatedemo.component";
// import { InputDemoComponent } from "./demo/view/inputdemo.component";
// import { ButtonDemoComponent } from "./demo/view/buttondemo.component";
// import { TableDemoComponent } from "./demo/view/tabledemo.component";
// import { ListDemoComponent } from "./demo/view/listdemo.component";
// import { AppTimelineDemoComponent } from "./pages/app.timelinedemo.component";
// import { TreeDemoComponent } from "./demo/view/treedemo.component";
// import { PanelsDemoComponent } from "./demo/view/panelsdemo.component";
// import { OverlaysDemoComponent } from "./demo/view/overlaysdemo.component";
// import { MediaDemoComponent } from "./demo/view/mediademo.component";
// import { MenusDemoComponent } from "./demo/view/menusdemo.component";
// import { MessagesDemoComponent } from "./demo/view/messagesdemo.component";
// import { MiscDemoComponent } from "./demo/view/miscdemo.component";
// import { EmptyDemoComponent } from "./demo/view/emptydemo.component";
// import { ChartsDemoComponent } from "./demo/view/chartsdemo.component";
// import { FileDemoComponent } from "./demo/view/filedemo.component";
// import { DocumentationComponent } from "./demo/view/documentation.component";
// import { DisplayComponent } from "./utilities/display.component";
// import { ElevationComponent } from "./utilities/elevation.component";
// import { FlexboxComponent } from "./utilities/flexbox.component";
// import { GridComponent } from "./utilities/grid.component";
// import { IconsComponent } from "./utilities/icons.component";
// import { WidgetsComponent } from "./utilities/widgets.component";
// import { SpacingComponent } from "./utilities/spacing.component";
// import { TypographyComponent } from "./utilities/typography.component";
// import { TextComponent } from "./utilities/text.component";
// import { AppCrudComponent } from "./pages/app.crud.component";
// import { AppCalendarComponent } from "./pages/app.calendar.component";
// import { AppInvoiceComponent } from "./pages/app.invoice.component";
// import { AppHelpComponent } from "./pages/app.help.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppLoginComponent } from "./pages/app.login.component";

import { CountryService } from "./demo/service/countryservice";
import { CustomerService } from "./demo/service/customerservice";
import { EventService } from "./demo/service/eventservice";
import { IconService } from "./demo/service/iconservice";
import { NodeService } from "./demo/service/nodeservice";
import { PhotoService } from "./demo/service/photoservice";
import { ProductService } from "./demo/service/productservice";
import { MenuService } from "./app.menu.service";
import { ApiBackendService } from "./services/api-backend.service";
import { DatasourcesService } from "./services/datasources.service";
import { AuthorizationService } from "./services/authorization.service";

import { AppRegisterComponent } from "./pages/app-register/app-register.component";
import { UsersViewComponent } from "./views/users/users-view.component";
import { UserProfileComponent } from "./views/users/components/user-profile/user-profile.component";
import { UserSettingsComponent } from "./views/users/components/user-settings/user-settings.component";
import { UsersListComponent } from "./views/users/components/users-list/users-list.component";
import { AppSubscriptionsComponent } from "./pages/app-subscriptions/app-subscriptions.component";
import { InvoicesListComponent } from "./views/users/components/invoices-list/invoices-list.component";

import { DesignerViewComponent } from "./views/designer/designer-view.component";

import { ScheduleCalendarComponent } from "./views/schedules/components/schedule-calendar/schedule-calendar.component";

import { DesignerNavToolbarComponent } from "./views/designer/components/designer-nav-toolbar/designer-nav-toolbar.component";
import { NodesListComponent } from "./views/workflows/components/nodes-list/nodes-list.component";
import { NodeConfigComponent } from "./views/designer/components/node-config/node-config.component";
import { NodeDataPreviewComponent } from "./views/designer/components/node-data-preview/node-data-preview.component";

import { DataMarketViewComponent } from "./views/datamarket/data-market-view.component";

import { PsaTableComponent } from "./views/datasources/components/psa-table/psa-table.component";

import { NodesToolbarComponent } from "./views/designer/components/nodes-toolbar/nodes-toolbar.component";
import { DatasourceChartsComponent } from "./views/datasources/components/datasource-charts/datasource-charts.component";
import { CreateDatasourceDialogComponent } from "./views/datasources/dialogs/create-datasource-dialog/create-datasource-dialog.component";
import { DeleteDatasourceDialogComponent } from "./views/datasources/dialogs/delete-datasource-dialog/delete-datasource-dialog.component";
import { EditDatasourceDialogComponent } from "./views/datasources/dialogs/edit-datasource-dialog/edit-datasource-dialog.component";
import { ChangeWriteModeDialogComponent } from "./views/datasources/dialogs/change-write-mode-dialog/change-write-mode-dialog.component";
import { DeleteDataPackageDialogComponent } from "./views/datasources/dialogs/delete-data-package-dialog/delete-data-package-dialog.component";
import { BionMessageService } from "./services/bion-message.service";
import { ChooseConnectorComponent } from "./views/datasources/components/choose-connector/choose-connector.component";
import { ExcelConnectorComponent } from "./views/datasources/components/connectors/excel-connector/excel-connector.component";
import { JdbcConnectorComponent } from "./views/datasources/components/connectors/jdbc-connector/jdbc-connector.component";
import { DefineDatasourceComponent } from "./views/datasources/components/define-datasource/define-datasource.component";
import { CreateNewDatasourceComponent } from "./views/datasources/components/create-new-datasource/create-new-datasource.component";

import { DeleteUserDialogComponent } from "./views/users/dialogs/delete-user-dialog/delete-user-dialog.component";
import { InviteUserDialogComponent } from "./views/users/dialogs/invite-user-dialog/invite-user-dialog.component";

import { TasksService } from "./services/tasks.service";
import { UserService } from "./services/user.service";
import { AuthService } from "./services/auth.service";

import { TokenStorageService } from "./services/token-storage.service";

import { GojsAngularModule } from 'gojs-angular';


// AZURE AUTHENTIFICATION
// import {
// 	MsalGuard,
// 	MsalInterceptor,
// 	MsalModule,
// 	MsalRedirectComponent,
// } from "@azure/msal-angular";
// import { InteractionType, PublicClientApplication } from "@azure/msal-browser";

import { SystemMessageLogService } from "./services/system-message-log.service";

import { ScheduleActionComponent } from "./views/schedules/components/schedule-action/schedule-action.component";
import { ScheduleFrequencyComponent } from "./views/schedules/components/schedule-frequency/schedule-frequency.component";
import { CreateScheduleDialogComponent } from "./views/schedules/dialogs/create-schedule-dialog/create-schedule-dialog.component";
import { DeleteScheduleDialogComponent } from "./views/schedules/dialogs/delete-schedule-dialog/delete-schedule-dialog.component";
import { EditScheduleDialogComponent } from "./views/schedules/dialogs/edit-schedule-dialog/edit-schedule-dialog.component";
import { PopulatePsaComponent } from "./views/datasources/components/populate-psa/populate-psa.component";
import { NewUserDialogComponent } from "./views/users/dialogs/new-user-dialog/new-user-dialog.component";
import { CreateActionDialogComponent } from "./views/schedules/dialogs/create-action-dialog/create-action-dialog.component";
import { CreateFrequencyDialogComponent } from "./views/schedules/dialogs/create-frequency-dialog/create-frequency-dialog.component";
import { DeleteActionDialogComponent } from "./views/schedules/dialogs/delete-action-dialog/delete-action-dialog.component";
import { DeleteFrequencyDialogComponent } from "./views/schedules/dialogs/delete-frequency-dialog/delete-frequency-dialog.component";
import { CreateWorkflowDialogComponent } from "./views/workflows/dialogs/create-workflow-dialog/create-workflow-dialog.component";
import { DeleteWorkflowDialogComponent } from "./views/workflows/dialogs/delete-workflow-dialog/delete-workflow-dialog.component";
import { WorkflowsService } from "./services/workflows.service";
import { CreateDatastoreDialogComponent } from "./views/destinations/dialogs/create-datastore-dialog/create-datastore-dialog.component";
import { DeleteDatastoreDialogComponent } from "./views/destinations/dialogs/delete-datastore-dialog/delete-datastore-dialog.component";
import { CubesService } from "./services/cubes.service";
import { RunScheduleDialogComponent } from "./views/schedules/dialogs/run-schedule-dialog/run-schedule-dialog.component";
import { ExportDatastoreDialogComponent } from "./views/destinations/dialogs/export-datastore-dialog/export-datastore-dialog.component";
import { DesignerService } from "./services/designer.service";
import { WorkflowGraphComponent } from "./views/designer/components/workflow-graph/workflow-graph.component";
import { CsvConnectorComponent } from "./views/datasources/components/connectors/csv-connector/csv-connector.component";
import { JsonConnectorComponent } from "./views/datasources/components/connectors/json-connector/json-connector.component";
import { DatamarketConnectorComponent } from "./views/datasources/components/connectors/datamarket-connector/datamarket-connector.component";
import { RestApiConnectorComponent } from "./views/datasources/components/connectors/rest-api-connector/rest-api-connector.component";

import { NodesStatusComponent } from "./views/designer/components/nodes-status/nodes-status.component";
import { NodeStatsComponent } from "./views/designer/components/node-stats/node-stats.component";
import { WorkflowArtefactComponent } from "./views/designer/components/workflow-artefact/workflow-artefact.component";
import { UserRolesListComponent } from "./views/users/components/user-roles-list/user-roles-list.component";
import { UserPermissionsListComponent } from "./views/users/components/user-permissions-list/user-permissions-list.component";
import { ChangeUserPasswordDialogComponent } from "./views/users/dialogs/change-user-password-dialog/change-user-password-dialog.component";

import { DefaultComponent } from "./views/designer/components/node-settings/default/default.component";
import { InputNodeComponent } from "./views/designer/components/node-settings/input-datasource-node/input-datasource-node.component";
import { SelectNodeComponent } from "./views/designer/components/node-settings/select-node/select-node.component";
import { JoinNodeComponent } from "./views/designer/components/node-settings/join-node/join-node.component";
import { UnionNodeComponent } from "./views/designer/components/node-settings/union-node/union-node.component";
import { FilterNodeComponent } from "./views/designer/components/node-settings/filter-node/filter-node.component";
import { SortingNodeComponent } from "./views/designer/components/node-settings/sorting-node/sorting-node.component";
import { TransposeNodeComponent } from "./views/designer/components/node-settings/transpose-node/transpose-node.component";
import { FindAndReplaceNodeComponent } from "./views/designer/components/node-settings/find-and-replace-node/find-and-replace-node.component";
import { OutputDatastoreNodeComponent } from "./views/designer/components/node-settings/output-datastore-node/output-datastore-node.component";
import { CalculateNodeComponent } from "./views/designer/components/node-settings/calculate-node/calculate-node.component";
import { FxConvertNodeComponent } from "./views/designer/components/node-settings/fx-convert-node/fx-convert-node.component";
import { UnitConvertNodeComponent } from "./views/designer/components/node-settings/unit-convert-node/unit-convert-node.component";
import { ConstantValueNodeComponent } from "./views/designer/components/node-settings/constant-value-node/constant-value-node.component";
import { MissingValueNodeComponent } from "./views/designer/components/node-settings/missing-value-node/missing-value-node.component";
import { TableSplitterNodeComponent } from "./views/designer/components/node-settings/table-splitter-node/table-splitter-node.component";
import { PresentValueNodeComponent } from "./views/designer/components/node-settings/present-value-node/present-value-node.component";
import { ColumnSplitterNodeComponent } from "./views/designer/components/node-settings/column-splitter-node/column-splitter-node.component";
import { ColumnAggregatorNodeComponent } from "./views/designer/components/node-settings/column-aggregator-node/column-aggregator-node.component";
import { SummarizeNodeComponent } from "./views/designer/components/node-settings/summarize-node/summarize-node.component";
import { UniqueValuesNodeComponent } from "./views/designer/components/node-settings/unique-values-node/unique-values-node.component";
import { FileDownloaderNodeComponent } from "./views/designer/components/node-settings/file-downloader-node/file-downloader-node.component";
import { DataSetDetailsComponent } from "./views/datamarket/components/data-set-details/data-set-details.component";
import { AppTutorialComponent } from "./pages/app-tutorial/app-tutorial.component";
import { NodeInfoComponent } from "./views/workflows/components/node-info/node-info.component";
import { PostgreConnectorComponent } from "./views/datasources/components/connectors/postgre-connector/postgre-connector.component";
import { H2EmbeddedConnectorComponent } from "./views/datasources/components/connectors/h2-embedded-connector/h2-embedded-connector.component";
import { H2ServerConnectorComponent } from "./views/datasources/components/connectors/h2-server-connector/h2-server-connector.component";
import { MariaDBConnectorComponent } from "./views/datasources/components/connectors/maria-db-connector/maria-db-connector.component";
import { RedshiftConnectorComponent } from "./views/datasources/components/connectors/redshift-connector/redshift-connector.component";
import { HsqlServerConnectorComponent } from "./views/datasources/components/connectors/hsql-server-connector/hsql-server-connector.component";
import { HsqlEmbeddedConnectorComponent } from "./views/datasources/components/connectors/hsql-embedded-connector/hsql-embedded-connector.component";
import { UtilFunctionsService } from "./services/util-functions.service";
import { ExcelUrlConnectorComponent } from "./views/datasources/components/connectors/excel-url-connector/excel-url-connector.component";
import { Db2ConnectorComponent } from "./views/datasources/components/connectors/db2-connector/db2-connector.component";
import { ScheduleChartsComponent } from "./views/schedules/components/schedule-charts/schedule-charts.component";
import { OpenWorkflowDialogComponent } from "./views/designer/dialogs/open-workflow-dialog/open-workflow-dialog.component";
import { DateparserNodeComponent } from "./views/designer/components/node-settings/dateparser-node/dateparser-node.component";
import { PivotNodeComponent } from "./views/designer/components/node-settings/pivot-node/pivot-node.component";
import { WorkflowChartsComponent } from "./views/workflows/components/workflow-charts/workflow-charts.component";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { UpcomingAppointmentsTimelineComponent } from "./views/schedules/components/upcoming-appointments-timeline/upcoming-appointments-timeline.component";
import { SystemMessageLogComponent } from "./pages/system-message-log/system-message-log.component";
import { TeamWidgetComponent } from "./views/users/components/team-widget/team-widget.component";
import { AddPermissionDialogComponent } from "./views/users/dialogs/add-permission-dialog/add-permission-dialog.component";
import { TwitterConnectorComponent } from "./views/datasources/components/connectors/twitter-connector/twitter-connector.component";
import { CubeChartsComponent } from "./views/destinations/components/cube-charts/cube-charts.component";
import { CubePipesComponent } from "./views/general/pipeline/pipeline.component";
import { InputDatastoreNodeComponent } from "./views/designer/components/node-settings/input-datastore-node/input-datastore-node.component";
import { WorldMapComponent } from "./views/datamarket/components/world-map/world-map.component";
import { DataSetDetailsDialogComponent } from "./views/datamarket/dialogs/data-set-details-dialog/data-set-details-dialog.component";
import { CreateUserRoleDialogComponent } from "./views/users/dialogs/create-user-role-dialog/create-user-role-dialog.component";
import { DeleteUserRoleDialogComponent } from "./views/users/dialogs/delete-user-role-dialog/delete-user-role-dialog.component";
import { DeletePermissionDialogComponent } from "./views/users/dialogs/delete-permission-dialog/delete-permission-dialog.component";
import { ChangePermissionDialogComponent } from "./views/users/dialogs/change-permission-dialog/change-permission-dialog.component";
import { NodesErrorsComponent } from "./views/designer/components/nodes-errors/nodes-errors.component";
import { RegexNodeComponent } from "./views/designer/components/node-settings/regex-node/regex-node.component";
import { NoDataAvailableComponent } from "./pages/no-data-available/no-data-available.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LoadingScreenComponent } from './pages/loading-screen/loading-screen.component';

import { DatatypeChartComponent } from './views/datasources/components/datatype-chart/datatype-chart.component';
import { AnonymizeNodeComponent } from './views/designer/components/node-settings/anonymize-node/anonymize-node.component';
import { SamplingNodeComponent } from './views/designer/components/node-settings/sampling-node/sampling-node.component';
import { EncryptNodeComponent } from './views/designer/components/node-settings/encrypt-node/encrypt-node.component';
import { ColumnStatsComponent } from './views/designer/components/column-stats/column-stats.component';
import { CompareNodeComponent } from './views/designer/components/node-settings/compare-node/compare-node.component';
import { CsvUrlConnectorComponent } from "./views/datasources/components/connectors/csv-url-connector/csv-url-connector.component";
import { NodeInfosComponent } from "./views/designer/components/node-info/node-info.component";
import { OperationsOverviewComponent } from './views/general/operations-overview/operations-overview.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

import { DatasourcesViewRelayoutComponent } from "./views/datasources/view/datasources-view.component";
import { GeneralObjectViewComponent } from "./views/objectManagement/general-object-view/general-object-view.component";
import { GeneralSearchComponent } from './views/objectManagement/general-search/general-search.component';
import { SpssConnectorComponent } from './views/datasources/components/connectors/spss-connector/spss-connector.component';
import { WorkflowsViewRelayoutComponent } from './views/workflows/view/workflows-view.component';
import { GenericTopToolbarComponent } from './views/objectManagement/generic-top-toolbar/generic-top-toolbar.component';
import { GenericLatestActivitiesComponent } from './views/objectManagement/generic-latest-activities/generic-latest-activities.component';
import { GenericWidgetsComponent } from './views/objectManagement/generic-widgets/generic-widgets.component';
import { PullDatasourceDialogComponent } from './views/datasources/dialogs/pull-datasource-dialog/pull-datasource-dialog.component';
import { GenericTeamWidgetComponent } from './views/objectManagement/generic-team-widget/generic-team-widget.component';
import { GenericCalendarWidgetComponent } from './views/objectManagement/generic-calendar-widget/generic-calendar-widget.component';
import { GenericBottomToolbarComponent } from './views/objectManagement/generic-bottom-toolbar/generic-bottom-toolbar.component';
import { DestinationsViewRelayoutComponent } from './views/destinations/view/destinations-view.component';
import { SchedulesViewRelayoutComponent } from './views/schedules/view/schedules-view.component';
import { ChangeDatatypeNodeComponent } from './views/designer/components/node-settings/change-datatype-node/change-datatype-node.component';
import { GenericFieldInfosComponent } from './views/objectManagement/generic-field-infos/generic-field-infos.component';
import { SapConnectorComponent } from './views/datasources/components/connectors/sap-connector/sap-connector.component';
import { PostgresSqlServerSingerConnectorComponent } from './views/datasources/components/connectors/postgres-sql-server-singer-connector/postgres-sql-server-singer-connector.component';
import { CreateDestinationDialogComponent } from './views/destinations/dialogs/create-destination-dialog/create-destination-dialog.component';
import { DummySchedulerService } from "./services/dummy-backend.service";
import { ScheduleGraphWorkflowComponent } from './views/schedule-planner/schedule-graph-workflow/schedule-graph-workflow.component';
import { SchedulePlannerComponent } from './views/schedule-planner/schedule-planner.component';
import { SchedulePlannerNavToolbarComponent } from './views/schedule-planner/schedule-planner-nav-toolbar/schedule-planner-nav-toolbar.component';
import { SchedulePlannerBottomToolbarComponent } from './views/schedule-planner/schedule-planner-bottom-toolbar/schedule-planner-bottom-toolbar.component';
import { ScheduleActionsListComponent } from './views/schedule-planner/schedule-actions-list/schedule-actions-list.component';
import { ScheduleLineChartComponent } from './views/schedules/components/schedule-line-chart/schedule-line-chart.component';
import { AutoSelectNodeComponent } from './views/designer/components/node-settings/auto-select-node/auto-select-node.component';
import { FormulaNodeComponent } from './views/designer/components/node-settings/formula-node/formula-node.component';
import { CreateDatasourceDialogNewComponent } from './views/datasources/dialogs/create-datasource-dialog-new/create-datasource-dialog-new.component';
import { LoadDataToPSADialogComponent } from './views/datasources/dialogs/load-data-to-psadialog/load-data-to-psadialog.component';
import { SparkSelectNodeComponent } from './views/designer/components/node-settings/spark-select-node/spark-select-node.component';
import { SparkAutoSelectNodeComponent } from './views/designer/components/node-settings/spark-auto-select-node/spark-auto-select-node.component';
import { SparkFindAndReplaceNodeComponent } from './views/designer/components/node-settings/spark-find-and-replace-node/spark-find-and-replace-node.component';
import { SparkSplitColumnNodeComponent } from './views/designer/components/node-settings/spark-split-column-node/spark-split-column-node.component';
import { SparkConstantValueNodeComponent } from './views/designer/components/node-settings/spark-constant-value-node/spark-constant-value-node.component';
import { ChangeConnectorSettingsDialogComponent } from './views/datasources/dialogs/change-connector-settings-dialog/change-connector-settings-dialog.component';
import { PushNewDataDialogComponent } from './views/datasources/dialogs/push-new-data-dialog/push-new-data-dialog.component';
import { PostgreSqlSettingsComponent } from './views/destinations/components/postgre-sql-settings/postgre-sql-settings.component';
import { CsvUriComponent } from './views/destinations/components/csv-uri/csv-uri.component';
import { ChooseDestinationComponent } from './views/destinations/components/choose-destination/choose-destination.component';
import { DefineDestinationComponent } from './views/destinations/components/define-destination/define-destination.component';
import { CreateDestinationComponent } from './views/destinations/components/create-destination/create-destination.component';
import { DestinationNodeComponent } from './views/designer/components/node-settings/destination-node/destination-node.component';
import { GenericObjectTasksComponent } from './views/objectManagement/generic-object-tasks/generic-object-tasks.component';
import { DeleteDatastorePackageDialogComponent } from './views/destinations/dialogs/delete-datastore-package-dialog/delete-datastore-package-dialog.component';
import { SqlDesignerViewComponent } from './views/sql-designer/designer-view/designer-view.component';
import { PluginViewComponent } from './views/sql-designer/plugin-view/plugin-view.component';
import { GraphViewComponent } from './views/sql-designer/graph-view/graph-view.component';
import { SettingsViewComponent } from './views/sql-designer/settings-view/settings-view.component';
import { ToolbarViewComponent } from './views/sql-designer/toolbar-view/toolbar-view.component';
import { DataViewComponent } from './views/sql-designer/data-view/data-view.component';
import { WorkflowTabViewComponent } from './views/sql-designer/workflow-tab-view/workflow-tab-view.component';
import { WebSocketTesterComponent } from './views/experimental/web-socket-tester/web-socket-tester.component';
import { DatasourceInputComponent } from './views/sql-designer/settings-view/settings/datasource-input/datasource-input.component';
import { EmailValidationNodeComponent } from './views/designer/components/node-settings/email-validation-node/email-validation-node.component';
import { TrimNodeComponent } from './views/designer/components/node-settings/trim-node/trim-node.component';
import { GenderCheckNodeComponent } from './views/designer/components/node-settings/gender-check-node/gender-check-node.component';
import { RemoveDuplicatesNodeComponent } from './views/designer/components/node-settings/remove-duplicates-node/remove-duplicates-node.component';
import { APP_INITIALIZER } from "@angular/core";
import { LoadingAppComponent } from './pages/loading-app/loading-app.component';
import { UserSubscriptionService } from "./services/user-subscription.service";
import { InfoTooltipComponent } from './pages/info-tooltip/info-tooltip.component';
import { KpiIndicatorComponent } from './views/dashboard/components/kpi-indicator/kpi-indicator.component';
import { ConvertCaseNodeComponent } from './views/designer/components/node-settings/convert-case-node/convert-case-node.component';
import { LimitRowsNodeComponent } from './views/designer/components/node-settings/limit-rows-node/limit-rows-node.component';
//import { DatasourcesModule } from "./views/datasources/datasources.module";
import { CreateAbSourceComponent } from './views/datasources/components/create-ab-source/create-ab-source.component';
import { CreateAbSourceConfigComponent } from './views/datasources/components/create-ab-source-config/create-ab-source-config.component';
import { GoogleSheetsComponent } from './views/datasources/components/create-ab-source-config/configViews/google-sheets/google-sheets.component';
import { PowerbiComponent } from './views/reports/powerbi-component/powerbi.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { PowerbiViewComponent } from './views/reports/powerbi-view/powerbi-view.component';
import { ExportWorkflowsDialogComponent } from './views/workflows/dialogs/export-workflows-dialog/export-workflows-dialog.component';
import { ImportWorkflowsDialogComponent } from './views/workflows/dialogs/import-workflows-dialog/import-workflows-dialog.component';
import { CreatePowerbiReportComponent } from './views/reports/create-powerbi-report/create-powerbi-report.component';
import { DeletePowerbiReportComponent } from './views/reports/delete-powerbi-report/delete-powerbi-report.component';
import { EditPowerbiReportComponent } from './views/reports/edit-powerbi-report/edit-powerbi-report.component'
import { ConfigDirective } from "./views/datasources/components/create-ab-source/config.directive";
import { CreateAbConnectionComponent } from './views/datasources/components/create-ab-connection/create-ab-connection.component';
import { DashboardV2Component } from './views/dashboard-v2/dashboard-v2.component';
import { DashboardDummyService } from "./views/dashboard-v2/dashboard-kpi-dummy.service";
import { PostgreSqlComponent } from './views/datasources/components/create-ab-source-config/configViews/postgre-sql/postgre-sql.component';
import { UnknownSettingsComponent } from './views/datasources/components/create-ab-source-config/configViews/unknown-settings/unknown-settings.component';
import { ViewAbSourceComponent } from './views/datasources/components/view-ab-source/view-ab-source.component';
import { ViewAbConnectionComponent } from './views/datasources/components/view-ab-connection/view-ab-connection.component';
import { ViewAbConnectionsComponent } from './views/datasources/components/view-ab-connections/view-ab-connections.component';
import { ViewAbSourcesComponent } from './views/datasources/components/view-ab-sources/view-ab-sources.component';
import { ViewAbMenuComponent } from './views/datasources/components/view-ab-menu/view-ab-menu.component';
import { HubspotComponent } from './views/datasources/components/create-ab-source-config/configViews/hubspot/hubspot.component';
import { StripeComponent } from './views/datasources/components/create-ab-source-config/configViews/stripe/stripe.component';

// Formly
//import { ReactiveFormsModule } from '@angular/forms';  // DUPE
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule, FormlyFieldConfig } from '@ngx-formly/core';
import {FormlyPrimeNGModule} from '@ngx-formly/primeng';
import {FormlyDatepickerModule} from '@ngx-formly/primeng/datepicker';
import { ArrayTypeComponent } from './views/datasources/components/create-ab-source/array.type';
import { ObjectTypeComponent } from './views/datasources/components/create-ab-source/object.type';
import { MultiSchemaTypeComponent } from './views/datasources/components/create-ab-source/multischema.type';
import { NullTypeComponent } from './views/datasources/components/create-ab-source/null.type';
import { MatMenuModule } from '@angular/material/menu';
import { ChooseConnectorIntComponent } from './views/datasources/components/choose-connector-int/choose-connector-int.component';
import { CreateDatasourceIntComponent } from './views/datasources/components/create-datasource-int/create-datasource-int.component';
import { DefineStreamsIntComponent } from './views/datasources/components/define-streams-int/define-streams-int.component';
import { CreateConfigIntAbComponent } from './views/datasources/components/create-config-int-ab/create-config-int-ab.component';
import { CreateConfigIntBionComponent } from './views/datasources/components/create-config-int-bion/create-config-int-bion.component';
import { ChooseConnectorIntPageComponent } from './views/datasources/components/choose-connector-int-page/choose-connector-int-page.component';
import { CreateDatasourceIntPageComponent } from './views/datasources/components/create-datasource-int-page/create-datasource-int-page.component';
import { DefineStreamsIntPageComponent } from './views/datasources/components/define-streams-int-page/define-streams-int-page.component';
import { ViewSourcesIntComponent } from './views/datasources/components/view-sources-int/view-sources-int.component';
import { DatasourceIntNodeComponent } from './views/designer/components/node-settings/datasource-int-node/datasource-int-node.component';
import { GetStreamsIntPageComponent } from './views/datasources/components/get-streams-int-page/get-streams-int-page.component';
import { UpdateStreamsIntPageComponent } from './views/datasources/components/update-streams-int-page/update-streams-int-page.component';
import { ViewSourceIntComponent } from './views/datasources/components/view-source-int/view-source-int.component';
import { ViewConnectionIntComponent } from './views/datasources/components/view-connection-int/view-connection-int.component';
import { PushIntSourceDialogComponent } from './views/datasources/dialogs/push-int-source-dialog/push-int-source-dialog.component';
import { ExcelAdapterComponent } from './views/datasources/components/bion-connector-views/excel-adapter/excel-adapter.component';
import { AbDsViewComponent } from './views/datasources/ab-view/ab-ds-view/ab-ds-view.component';
import { DestinationViewComponent } from './views/destinations/view/destination-view/destination-view.component';
import { CreateDestinationPageComponent } from './views/destinations/components/create-destination-page/create-destination-page.component';
import { ChooseDestConnectorPageComponent } from './views/destinations/components/choose-dest-connector-page/choose-dest-connector-page.component';
import { ChooseDestConnectorComponent } from "./views/destinations/components/choose-dest-connector/choose-dest-connector.component";

import { CsvAdapterComponent } from './views/datasources/components/bion-connector-views/csv-adapter/csv-adapter.component';
import { CreateDestinationConfigBionComponent } from './views/destinations/components/create-destination-config-bion/create-destination-config-bion.component';
import { ViewSourceIntHistoryComponent } from './views/datasources/components/view-source-int-history/view-source-int-history.component';
import { WorkflowViewComponent } from './views/workflows/view/workflow-view/workflow-view.component';

const isIE =
	window.navigator.userAgent.indexOf("MSIE ") > -1 ||
	window.navigator.userAgent.indexOf("Trident/") > -1;

// FullCalendarModule.registerPlugins([
// 	dayGridPlugin,
// 	timeGridPlugin,
// 	interactionPlugin,
// ]);

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
	//return null;
}

// interface System {
// 	import(request: string): Promise<any>;
//   }
// declare var System: System;

// export class WebpackTranslateLoader implements TranslateLoader {
// 	getTranslation(lang: string): Observable<any> {
// 	  return from(System.import(`./assets/i18n/${lang}.json`)); // your path
// 	}
//   }


// Formly Functions for Form Validation
export function minItemsValidationMessage(error: any, field: FormlyFieldConfig) {
	// return `should NOT have fewer than ${field.props.minItems} items`; // RTH: Disabled due to interface change (?)
	return `should NOT have fewer than ${field.props["minItems"]} items`;
}

export function maxItemsValidationMessage(error: any, field: FormlyFieldConfig) {
	// return `should NOT have more than ${field.props.maxItems} items`;  // RTH: Disabled due to interface change (?)
	return `should NOT have more than ${field.props["maxItems"]} items`;
}

export function minLengthValidationMessage(error: any, field: FormlyFieldConfig) {
	return `should NOT be shorter than ${field.props.minLength} characters`;
}

export function maxLengthValidationMessage(error: any, field: FormlyFieldConfig) {
	return `should NOT be longer than ${field.props.maxLength} characters`;
}

export function minValidationMessage(error: any, field: FormlyFieldConfig) {
	return `should be >= ${field.props.min}`;
}

export function maxValidationMessage(error: any, field: FormlyFieldConfig) {
	return `should be <= ${field.props.max}`;
}

export function multipleOfValidationMessage(error: any, field: FormlyFieldConfig) {
	return `should be multiple of ${field.props.step}`;
}

export function exclusiveMinimumValidationMessage(error: any, field: FormlyFieldConfig) {
	return `should be > ${field.props.step}`;
}

export function exclusiveMaximumValidationMessage(error: any, field: FormlyFieldConfig) {
	return `should be < ${field.props.step}`;
}

export function constValidationMessage(error: any, field: FormlyFieldConfig) {
	//return `should be equal to constant "${field.props.const}"`;
	return `should be equal to constant "${field.props["const"]}"`;
}

export function typeValidationMessage({ schemaType }: any) {
	return `should be "${schemaType[0]}".`;
}
// Formly - END



@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		// MsalModule.forRoot(
		// 	new PublicClientApplication({
		// 		auth: {
		// 			clientId: environment.clientId, // This is your client ID
		// 			authority: environment.authority, // This is your tenant ID
		// 			redirectUri: environment.redirectUrl, // This is your redirect URI
		// 		},
		// 		cache: {
		// 			cacheLocation: "localStorage",
		// 			storeAuthStateInCookie: isIE,
		// 		},
		// 	}),
		// 	{
		// 		interactionType: InteractionType.Redirect,
		// 		authRequest: {
		// 			scopes: ["User.Read"],
		// 		},
		// 	},
		// 	{
		// 		interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
		// 		protectedResourceMap: new Map([
		// 			[
		// 				"https://graph.microsoft.com/v1.0/me",
		// 				["User.Read", "openid", "profile"],
		// 			],
		// 		]),
		// 	}
		// ),
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				// useClass: WebpackTranslateLoader,
				useFactory: httpTranslateLoader,
				deps: [HttpClient],
			},
		}),
		BrowserAnimationsModule,
		AccordionModule,
		AutoCompleteModule,
		AvatarModule,
		AvatarGroupModule,
		BadgeModule,
		CaptchaModule,
		BreadcrumbModule,
		ButtonModule,
		CalendarModule,
		CardModule,
		CarouselModule,
		ChartModule,
		CheckboxModule,
		ChipsModule,
		ChipModule,
		CodeHighlighterModule,
		ConfirmDialogModule,
		ConfirmPopupModule,
		ColorPickerModule,
		ContextMenuModule,
		DataViewModule,
		DialogModule,
		DividerModule,
		DragDropModule,
		DropdownModule,
		FieldsetModule,
		FileUploadModule,
		GalleriaModule,
		InplaceModule,
		InputNumberModule,
		InputMaskModule,
		InputSwitchModule,
		InputTextModule,
		InputTextareaModule,
		KnobModule,
		LightboxModule,
		ListboxModule,
		MegaMenuModule,
		MenuModule,
		MenubarModule,
		MessageModule,
		MessagesModule,
		MultiSelectModule,
		OrderListModule,
		OrganizationChartModule,
		OverlayPanelModule,
		PaginatorModule,
		PanelModule,
		PanelMenuModule,
		PasswordModule,
		PickListModule,
		ProgressBarModule,
		RadioButtonModule,
		RatingModule,
		RippleModule,
		ScrollPanelModule,
		ScrollTopModule,
		SelectButtonModule,
		SkeletonModule,
		SidebarModule,
		SlideMenuModule,
		SliderModule,
		SplitButtonModule,
		SplitterModule,
		StepsModule,
		TableModule,
		TabMenuModule,
		TabViewModule,
		TagModule,
		TerminalModule,
		TieredMenuModule,
		TimelineModule,
		ToastModule,
		ToggleButtonModule,
		ToolbarModule,
		TooltipModule,
		TreeModule,
		TreeTableModule,
		VirtualScrollerModule,
		DockModule,
		SpeedDialModule,
		EditorModule,
		AppCodeModule,
		GojsAngularModule,
		ImageModule,
		PowerBIEmbedModule,

		//DatasourcesModule

		// JSON Schema Form Builder
		CommonModule,

		// Formly
		FormlyBootstrapModule,
		MatMenuModule,
		FormlyModule.forRoot({
			validationMessages: [
			  { name: 'required', message: 'This field is required' },
			  { name: 'type', message: typeValidationMessage },
			  { name: 'minLength', message: minLengthValidationMessage },
			  { name: 'maxLength', message: maxLengthValidationMessage },
			  { name: 'min', message: minValidationMessage },
			  { name: 'max', message: maxValidationMessage },
			  { name: 'multipleOf', message: multipleOfValidationMessage },
			  { name: 'exclusiveMinimum', message: exclusiveMinimumValidationMessage },
			  { name: 'exclusiveMaximum', message: exclusiveMaximumValidationMessage },
			  { name: 'minItems', message: minItemsValidationMessage },
			  { name: 'maxItems', message: maxItemsValidationMessage },
			  { name: 'uniqueItems', message: 'should NOT have duplicate items' },
			  { name: 'const', message: constValidationMessage },
			],
			types: [
			  { name: 'null', component: NullTypeComponent, wrappers: ['form-field'] },
			  { name: 'array', component: ArrayTypeComponent },
			  { name: 'object', component: ObjectTypeComponent },
			  { name: 'multischema', component: MultiSchemaTypeComponent },
			],
		  }),
		  FormlyPrimeNGModule,
		  FormlyDatepickerModule

	],
	declarations: [
		AppComponent,
		AppMainComponent,
		AppRightpanelComponent,
		AppMenuComponent,
		AppMenuitemComponent,
		AppConfigComponent,
		AppTopBarComponent,
		AppFooterComponent,
		//DashboardDemoComponent,
		//FormLayoutDemoComponent,
		// FloatLabelDemoComponent,
		// InvalidStateDemoComponent,
		// InputDemoComponent,
		// ButtonDemoComponent,
		// TableDemoComponent,
		// ListDemoComponent,
		// TreeDemoComponent,
		// PanelsDemoComponent,
		// OverlaysDemoComponent,
		// MediaDemoComponent,
		// MenusDemoComponent,
		// MessagesDemoComponent,
		// MessagesDemoComponent,
		// MiscDemoComponent,
		// ChartsDemoComponent,
		// EmptyDemoComponent,
		// FileDemoComponent,
		// DocumentationComponent,
		// DisplayComponent,
		// ElevationComponent,
		// FlexboxComponent,
		// GridComponent,
		// IconsComponent,
		// WidgetsComponent,
		// SpacingComponent,
		// TypographyComponent,
		// TextComponent,
		// AppCrudComponent,
		// AppCalendarComponent,
		AppLoginComponent,
		//AppInvoiceComponent,
		//AppHelpComponent,
		AppNotfoundComponent,
		AppErrorComponent,
		//AppTimelineDemoComponent,
		AppAccessdeniedComponent,
		AppRegisterComponent,
		UsersViewComponent,
		UserProfileComponent,
		UserSettingsComponent,
		UsersListComponent,
		AppSubscriptionsComponent,
		InvoicesListComponent,
		DesignerViewComponent,
		ScheduleCalendarComponent,
		DesignerNavToolbarComponent,
		NodesListComponent,
		NodeConfigComponent,
		NodeDataPreviewComponent,
		DataMarketViewComponent,
		PsaTableComponent,
		NodesToolbarComponent,
		DatasourceChartsComponent,
		CreateDatasourceDialogComponent,
		DeleteDatasourceDialogComponent,
		EditDatasourceDialogComponent,
		ChangeWriteModeDialogComponent,
		DeleteDataPackageDialogComponent,
		ChooseConnectorComponent,
		ExcelConnectorComponent,
		JdbcConnectorComponent,
		PostgreConnectorComponent,
		DefineDatasourceComponent,
		CreateNewDatasourceComponent,
		ScheduleActionComponent,
		ScheduleFrequencyComponent,
		CreateScheduleDialogComponent,
		DeleteScheduleDialogComponent,
		EditScheduleDialogComponent,
		PopulatePsaComponent,
		NewUserDialogComponent,
		DeleteUserDialogComponent,
		InviteUserDialogComponent,
		CreateActionDialogComponent,
		CreateFrequencyDialogComponent,
		DeleteActionDialogComponent,
		DeleteFrequencyDialogComponent,
		CreateWorkflowDialogComponent,
		DeleteWorkflowDialogComponent,
		CreateDatastoreDialogComponent,
		DeleteDatastoreDialogComponent,
		RunScheduleDialogComponent,
		ExportDatastoreDialogComponent,
		WorkflowGraphComponent,
		CsvConnectorComponent,
		CsvUrlConnectorComponent,
		JsonConnectorComponent,
		DatamarketConnectorComponent,
		RestApiConnectorComponent,
		DefaultComponent,
		InputNodeComponent,
		SelectNodeComponent,
		JoinNodeComponent,
		UnionNodeComponent,
		FilterNodeComponent,
		SortingNodeComponent,
		TransposeNodeComponent,
		FindAndReplaceNodeComponent,
		OutputDatastoreNodeComponent,
		CalculateNodeComponent,
		FxConvertNodeComponent,
		UnitConvertNodeComponent,
		ConstantValueNodeComponent,
		MissingValueNodeComponent,
		TableSplitterNodeComponent,
		PresentValueNodeComponent,
		ColumnSplitterNodeComponent,
		ColumnAggregatorNodeComponent,
		SummarizeNodeComponent,
		UniqueValuesNodeComponent,
		FileDownloaderNodeComponent,
		NodesStatusComponent,
		NodeStatsComponent,
		WorkflowArtefactComponent,
		UserRolesListComponent,
		UserPermissionsListComponent,
		ChangeUserPasswordDialogComponent,
		DataSetDetailsComponent,
		AppTutorialComponent,
		NodeInfoComponent,
		NodeInfosComponent,
		H2EmbeddedConnectorComponent,
		H2ServerConnectorComponent,
		MariaDBConnectorComponent,
		RedshiftConnectorComponent,
		HsqlServerConnectorComponent,
		HsqlEmbeddedConnectorComponent,
		ExcelUrlConnectorComponent,
		Db2ConnectorComponent,
		ScheduleChartsComponent,
		OpenWorkflowDialogComponent,
		WorkflowChartsComponent,
		DateparserNodeComponent,
		PivotNodeComponent,
		WorkflowChartsComponent,
		DashboardComponent,
		UpcomingAppointmentsTimelineComponent,
		SystemMessageLogComponent,
		TeamWidgetComponent,
		AddPermissionDialogComponent,
		TwitterConnectorComponent,
		CubeChartsComponent,
		CubePipesComponent,
		InputDatastoreNodeComponent,
		WorldMapComponent,
		DataSetDetailsDialogComponent,
		CreateUserRoleDialogComponent,
		DeleteUserRoleDialogComponent,
		DeletePermissionDialogComponent,
		ChangePermissionDialogComponent,
		NodesErrorsComponent,
		RegexNodeComponent,
		NoDataAvailableComponent,
		LoadingScreenComponent,
		DatatypeChartComponent,
		AnonymizeNodeComponent,
		SamplingNodeComponent,
		EncryptNodeComponent,
		ColumnStatsComponent,
		CompareNodeComponent,
		OperationsOverviewComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		DatasourcesViewRelayoutComponent,
		GeneralObjectViewComponent,
		GeneralSearchComponent,
		SpssConnectorComponent,
		WorkflowsViewRelayoutComponent,
		GenericTopToolbarComponent,
		GenericLatestActivitiesComponent,
		GenericWidgetsComponent,
		PullDatasourceDialogComponent,
		GenericTeamWidgetComponent,
		GenericCalendarWidgetComponent,
		GenericBottomToolbarComponent,
		DestinationsViewRelayoutComponent,
		SchedulesViewRelayoutComponent,
		ChangeDatatypeNodeComponent,
		GenericFieldInfosComponent,
		SapConnectorComponent,
		PostgresSqlServerSingerConnectorComponent,
		CreateDestinationDialogComponent,
		ScheduleGraphWorkflowComponent,
		SchedulePlannerComponent,
		SchedulePlannerNavToolbarComponent,
		SchedulePlannerBottomToolbarComponent,
		ScheduleActionsListComponent,
		ScheduleLineChartComponent,
		AutoSelectNodeComponent,
		FormulaNodeComponent,
		CreateDatasourceDialogNewComponent,
		LoadDataToPSADialogComponent,
		SparkSelectNodeComponent,
		SparkAutoSelectNodeComponent,
		SparkFindAndReplaceNodeComponent,
		SparkSplitColumnNodeComponent,
		SparkConstantValueNodeComponent,
		ChangeConnectorSettingsDialogComponent,
		PushNewDataDialogComponent,
		PostgreSqlSettingsComponent,
		CsvUriComponent,
		ChooseDestinationComponent,
		DefineDestinationComponent,
		CreateDestinationComponent,
		DestinationNodeComponent,
		GenericObjectTasksComponent,
		DeleteDatastorePackageDialogComponent,
		SqlDesignerViewComponent,
		PluginViewComponent,
		GraphViewComponent,
		SettingsViewComponent,
		ToolbarViewComponent,
		DataViewComponent,
		WorkflowTabViewComponent,
		DeleteDatastorePackageDialogComponent,
		WebSocketTesterComponent,
		DatasourceInputComponent,
		EmailValidationNodeComponent,
		TrimNodeComponent,
		GenderCheckNodeComponent,
		RemoveDuplicatesNodeComponent,
		LoadingAppComponent,
		InfoTooltipComponent,
		KpiIndicatorComponent,
		ConvertCaseNodeComponent,
		LimitRowsNodeComponent,
		CreateAbSourceComponent,
		CreateAbSourceConfigComponent,
		GoogleSheetsComponent,
		PowerbiComponent,
		PowerbiViewComponent,
		ExportWorkflowsDialogComponent,
		ImportWorkflowsDialogComponent,
		CreatePowerbiReportComponent,
		DeletePowerbiReportComponent,
		EditPowerbiReportComponent,
		ConfigDirective,
		CreateAbConnectionComponent,
		DashboardV2Component,
		PostgreSqlComponent,
		UnknownSettingsComponent,
		ViewAbSourceComponent,
		ViewAbConnectionComponent,
		ViewAbConnectionsComponent,
		ViewAbSourcesComponent,
		ViewAbMenuComponent,
		HubspotComponent,
		StripeComponent,

		// Formly
		ArrayTypeComponent,
		ObjectTypeComponent,
		MultiSchemaTypeComponent,
		NullTypeComponent,

		// Other
		ChooseConnectorIntComponent,
		CreateDatasourceIntComponent,
		DefineStreamsIntComponent,
		CreateConfigIntAbComponent,
		CreateConfigIntBionComponent,
		ChooseConnectorIntPageComponent,
		CreateDatasourceIntPageComponent,
		DefineStreamsIntPageComponent,
		ViewSourcesIntComponent,
		DatasourceIntNodeComponent,
		GetStreamsIntPageComponent,
		UpdateStreamsIntPageComponent,
		ViewSourceIntComponent,
		ViewConnectionIntComponent,
		PushIntSourceDialogComponent,
		ExcelAdapterComponent,
		AbDsViewComponent,
		DestinationViewComponent,
		CreateDestinationPageComponent,
		ChooseDestConnectorPageComponent,
		ChooseDestConnectorComponent,
		CsvAdapterComponent,
		CreateDestinationConfigBionComponent,
  ViewSourceIntHistoryComponent,
  WorkflowViewComponent
	],
	providers: [
		// Keycloak interceptor disabled because the Backend performs the auth task and authorizes the FE via Cookie

		// {
		// 	provide: APP_INITIALIZER,
		// 	useFactory: initializeKeycloak,
		// 	multi: true,
		// 	deps: [KeycloakService],
		// },

		// {
		// 	provide: HTTP_INTERCEPTORS,
		// 	useClass: HttpInterceptorService,
		// 	multi: true,
		// },
		// MsalGuard,
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		BionMessageService,
		DatePipe,
		CountryService,
		CustomerService,
		EventService,
		IconService,
		NodeService,
		PhotoService,
		ProductService,
		MenuService,
		AppMainComponent,
		AuthService,
		//authInterceptorProviders,
		TokenStorageService,
		ApiBackendService,
		DummySchedulerService,
		UserService,
		DatasourcesService,
		WorkflowsService,
		TasksService,
		CubesService,
		DesignerService,
		UtilFunctionsService,
		SystemMessageLogService,
		AuthorizationService,
		UserSubscriptionService,
		DashboardDummyService
	],
	bootstrap: [AppComponent],
})
export class AppModule { }

